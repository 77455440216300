import styled from 'styled-components';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Select__Values__Holder = styled(FlexBox)`
flex-direction: column;
  max-width: 300px;
  max-height: 300px;
`;

const Lock__Icon = styled(FontAwesomeIcon)`
  margin-top: 2px;
 margin-right: 4px;
  width: 14px;
  height: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
`;
const Favourite__Icon = styled(FontAwesomeIcon)`
  margin-top: 2px;
  margin-right: 2px;
  padding-left: 2px;
  width: 14px;
  height: 14px;
  color: ${({ theme }) => theme.colors.textColor};
`;
const Option__Label = styled(FlexBox)`
  max-width: calc(290px - 16px - 18px);
`;

const Small__Select__Option = styled(FlexBox)`
  width: 100%;
padding: 8px 5px;
  cursor: pointer;
  &:hover{
    background-color: ${({ theme }) => theme.colors.textColor};
  }
  &:hover ${Option__Label} {
    color: ${({ theme }) => theme.colors.white};;
  }

  &:hover ${Lock__Icon}{
    color: ${({ theme }) => theme.colors.white};
  }
  &:hover ${Favourite__Icon}{
    color: ${({ theme }) => theme.colors.white};
  }
`;
const No__Values__Found__Holder = styled(FlexBox)`
height: 35px;
  width: 100%;
  text-align: center;
  align-content: center;
`;
const No__Values__Found__Label = styled(Text)`
margin: auto;
`;
const AutoSuggestDropDown = styled.div<{ width: number, height: number }>`
   display: flex;
   flex-direction: column;
  position: relative;
  max-height: 400px;
  height: ${({ height }) => height}px;
   width: ${({ width }) => width}px;
   border-radius: ${({ theme }) => theme.radii['br-4']};
   box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
   background-color: ${({ theme }) => theme.colors.white};
   z-index: ${({ theme }) => theme.zIndex.modal};
`;

export const Styled = {
  Favourite__Icon,
  Option__Label,
  Lock__Icon,
  AutoSuggestDropDown,
  Select__Values__Holder,
  Small__Select__Option,
  No__Values__Found__Holder,
  No__Values__Found__Label,
};
