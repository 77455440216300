import React from 'react';
import loadable from '@loadable/component';

import { useSelector } from '@ess/store/core';

import { useReactBasket } from '@basket/hooks/useReactBasket';

import { ColumnsManagerProvider } from './ColumnsManager';

const SearchByMap = loadable(() => import(
  /* webpackChunkName: "search-by-map" */
  '@tourop/components/SearchForm/SearchByMap'
));

const MapSearch = loadable(() => import(
  /* webpackChunkName: "map-search" */
  '@tourop/pages/SearchResultsPage/MapSearch'
));

const Basket = loadable(() => import(
  /* webpackChunkName: "basket" */
  '@basket/components'
));

const MapSearchComponent = (props: any) => {
  const searchByMap = ({
    isGeoSearch = false,
    mapBoundsPoints = null,
  }) => {
    if (isGeoSearch) {
      const event = new CustomEvent('MXSearchFormGeoInteract', { detail: { geo: mapBoundsPoints, submit: true } });
      window.dispatchEvent(event);
    }
  };

  const isMapOpen = useSelector((state) => state.base.showMap);
  return (
    <MapSearch {...props} fetchResults={searchByMap} isOpen={isMapOpen}/>
  );
};

const SearchByMapComponent = (props: any) => {
  const isMapOpen = useSelector((state) => state.base.showMap);
  return (
    <>
      {isMapOpen ? <SearchByMap {...props}/> : null}
    </>
  );
};

const BasketComponent = (props: any) => (
  <Basket {...props}/>
);

type ColumnsManagerContainerProps = {
  children: React.ReactNode;
}

const ColumnsManagerContainer = ({ children }: ColumnsManagerContainerProps) => {
  const { basket } = useReactBasket();
  const showSearchFormMap = useSelector((state) => state.base.showMap);
  const isOutsideColumns = ['top', 'bottom'].includes(basket.position);

  const basketElement = [{
    key: 'basket',
    requiredWidth: 500,
    fillSpace: false,
    isVisible: basket.isOpen,
    offset: 24,
    Component: BasketComponent,
  }];

  const leftColumn = basket.position === 'left' ? [...basketElement] : [];
  const outsideColumn = isOutsideColumns ? [...basketElement] : [];

  const elements = {
    SEARCH_PAGE: {
      left: leftColumn,
      right: [
        ...(basket.position === 'right' ? basketElement : []),
        {
          key: 'map',
          requiredWidth: 450,
          fillSpace: true,
          isVisible: showSearchFormMap,
          Component: SearchByMapComponent,
        },
      ],
      outside: outsideColumn,
    },
    RESULTS_PAGE: {
      left: leftColumn,
      right: [
        ...(basket.position === 'right' ? basketElement : []),
        {
          key: 'map',
          requiredWidth: 450,
          fillSpace: true,
          isVisible: showSearchFormMap,
          Component: MapSearchComponent,
        },
      ],
      outside: outsideColumn,
    },
    OTHER_PAGE: {
      left: leftColumn,
      right: [
        ...(basket.position === 'right' ? basketElement : []),
      ],
      outside: outsideColumn,
    },
  };

  return (
    <ColumnsManagerProvider elements={elements}>
      {children}
    </ColumnsManagerProvider>
  );
};

export {
  ColumnsManagerContainer,
};
