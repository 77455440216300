import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import React from 'react';

type BasketMessageProps = {
  title: string
  message: string
}

const BasketMessage = ({ title, message }: BasketMessageProps) => (
  <FlexBox
    p="medium"
    alignItems="center"
    justifyContent="center"
    flexGrow={1}
    minHeight={350}
    height="100%"
    backgroundColor="#e8ecee"
  >
    <FlexBox flexDirection="column" flexWrap="nowrap" style={{ textAlign: 'center' }} alignItems="center">
      <Text color="#84949f" mb="medium" fontSize="title">{title}</Text>
      <Text color="#84949f" mb="medium">{message}</Text>
    </FlexBox>
  </FlexBox>
);

export {
  BasketMessage,
};
