import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BasketActionLevel, BasketActions } from '@basket/types';

import { useReactBasket } from '@basket/hooks/useReactBasket';

import {
  showToast,
  TOAST_ERROR,
  TOAST_SUCCESS,
  getCurrentLanguage,
} from '@ess/utils';

import { Button } from '@ess/ui/Button';
import { Tab, TabPanel, Tabs } from '@ess/ui/Tabs';
import Modal from '@ess/ui/Modal';
import FlexBox from '@ess/ui/FlexBox';

import { FileMethod } from './FileMethod';
import { UrlMethod } from './UrlMethod';

import { AddMediaMethod, IAddMediaRequest } from './types';

type CustomMediaModalProps = {
  onClose: () => void
}

const CustomMediaModal = ({ onClose }: CustomMediaModalProps) => {
  const { t } = useTranslation();
  const { basketClient, basket, getBasketItems } = useReactBasket();
  const [tab, setTab] = useState<AddMediaMethod>(AddMediaMethod.File);

  const onTabChangeHandler = (type: AddMediaMethod) => {
    setTab(type);
  };

  const onCloseHandler = () => {
    onClose();
  };

  const onApplyHandler = ({ itemType, data }: IAddMediaRequest) => {
    addMediaRequest({ itemType, data });
    onClose();
  };

  const addMediaRequest = ({ itemType, data }: IAddMediaRequest) => {
    (async () => {
      try {
        const client = await basketClient({
          action: BasketActions.AddMedia,
          level: BasketActionLevel.Item,
          conditions: {
            Language: getCurrentLanguage(),
            currency: 'PLN',
            basketId: basket.current.id,
            itemType,
            Item: [data],
          },
        });

        if (client?.status === 'OK') {
          showToast(TOAST_SUCCESS, t('lbl_add_media_success'));
          getBasketItems(basket.current.id as number, true);
        } else {
          showToast(TOAST_ERROR, t('lbl_add_media_fail'));
        }
      } catch (error) {
        showToast(TOAST_ERROR, t('lbl_add_media_fail'));
      }
    })();
  };

  return (
    <Modal
      title={t('lbl_add_media')}
      isOpen
      theme="white"
      width={500}
      onClose={onCloseHandler}
      controls={(
        <FlexBox p="small" width="100%" alignItems="center" justifyContent="flex-end">
          <Button
            variant="secondary"
            label={t('lbl_cancel')}
            size="small"
            width="90px"
            onClick={onCloseHandler}
          />
        </FlexBox>
      )}
    >
      <Tabs onChange={onTabChangeHandler} value={tab}>
        <Tab
          value="file"
          label={t('lbl_add_media_file')}
          style={{
            width: '50%',
          }}
        />
        <Tab
          value="url"
          label={t('lbl_add_media_url')}
          style={{
            width: '50%',
          }}
        />
      </Tabs>

      <TabPanel value="file">
        <FileMethod onApply={onApplyHandler}/>
      </TabPanel>

      <TabPanel value="url">
        <UrlMethod onApply={onApplyHandler}/>
      </TabPanel>
    </Modal>
  );
};

export {
  CustomMediaModal,
};
