import {
  has, findIndex, find, sortBy,
} from 'lodash-es';

import { REGION_LIST, SKI_REGION_LIST } from '@ess/constants/search';
import { IDictionary, IOffer } from '@ess/types';

interface IRegion {
  id: string
  desc: string
}

/**
 * Get price amount for offer, distinguishing `Total` and `Person` price
 *
 * @param offer
 *
 * @returns {number}
 */
const getPriceAmountFromOffer = (offer: IOffer) => {
  let price = 0;
  if (offer.Base?.Price?.FirstPerson?.amount !== '') {
    price = parseInt(offer?.Base?.Price?.FirstPerson?.amount as string, 10);
  } else if (offer.Base?.Price?.Total?.amount !== '') {
    price = parseInt(offer.Base?.Price?.Total?.amount as string, 10);
  }

  return price;
};

/**
 * Prepare ski regions list.
 * @param regions
 */
export const getSkiRegionList = (regions: any) => {
  const skiRegions: any = [];
  const objKeys = Object.keys(regions[SKI_REGION_LIST]);

  objKeys.map((key) => {
    const skiRegion = regions[SKI_REGION_LIST][key];
    const { path, offer } = skiRegion;
    const country = path[0];
    const countryId = country?.id?.toString();
    const countryIndex = findIndex(skiRegions, (o: IRegion) => o.id === countryId);
    const items = path.slice(1);
    const parentId: any = [];

    const price = getPriceAmountFromOffer(offer);

    if (countryIndex === -1) {
      skiRegions.push({
        id: countryId,
        desc: country.desc,
        offer,
        price,
        regions: {},
      });
    }

    items.map((region: IRegion, index: number) => {
      const countryIndex = findIndex(skiRegions, (o: IRegion) => o.id === countryId);
      const currentRegion0 = find(skiRegions[countryIndex].regions, (o: IRegion) => o.id === region.id);
      const currentRegion1 = parentId[0] ? (
        find(skiRegions[countryIndex].regions[parentId[0]].regions, (o) => o.id === region.id)
      ) : undefined;

      if (!currentRegion0 && index === 0) {
        skiRegions[countryIndex].regions[region.id] = {
          ...region, id: region.id.toString(), offer, price, regions: {},
        };
      }
      if (!currentRegion1 && index === 1) {
        skiRegions[countryIndex].regions[parentId[0]].regions[region.id] = {
          ...region, id: region.id.toString(), offer, price, regions: {},
        };
        if (skiRegions[countryIndex].regions[parentId[0]].price > price) {
          // set lowest price to region
          skiRegions[countryIndex].regions[parentId[0]].offer = offer;
          skiRegions[countryIndex].regions[parentId[0]].price = price;
        }
      }

      if (index === 2) {
        skiRegions[countryIndex].regions[parentId[0]].regions[parentId[1]].regions[region.id] = {
          ...region, id: region.id.toString(), offer, price,
        };
        if (skiRegions[countryIndex].regions[parentId[0]].regions[parentId[1]].price > price) {
          // set lowest price to region
          skiRegions[countryIndex].regions[parentId[0]].regions[parentId[1]].offer = offer;
          skiRegions[countryIndex].regions[parentId[0]].regions[parentId[1]].price = price;
        }
        if (skiRegions[countryIndex].regions[parentId[0]].price > price) {
          // set lowest price to region
          skiRegions[countryIndex].regions[parentId[0]].offer = offer;
          skiRegions[countryIndex].regions[parentId[0]].price = price;
        }
      }

      if (skiRegions[countryIndex].price > price) {
        skiRegions[countryIndex].offer = offer;
        skiRegions[countryIndex].price = price;
      }

      parentId.push(region.id);
    });
  });

  Object.keys(skiRegions).map((key1) => {
    skiRegions[key1].regions = sortBy(skiRegions[key1].regions, 'price');
    Object.keys(skiRegions[key1].regions).map((key2) => {
      skiRegions[key1].regions[key2].regions = sortBy(skiRegions[key1].regions[key2].regions, 'price');
      Object.keys(skiRegions[key1].regions[key2].regions).map((key3) => {
        skiRegions[key1].regions[key2].regions[key3].regions = sortBy(skiRegions[key1]
          .regions[key2].regions[key3].regions, 'price');
      });
    });
  });

  return sortBy(skiRegions, 'price');
};

/**
 * Prepare regular regions list.
 * @param regions
 */
export const getRegionList = (regions: any) => {
  const objKeys = Object.keys(regions[REGION_LIST]);

  return sortBy(objKeys.map((key) => {
    const country = {
      id: key,
      desc: regions[REGION_LIST][key].desc,
      offer: undefined,
      regions: {},
      price: 0,
    };

    const newRegions: IDictionary<IRegion> = {};
    Object.keys(regions[REGION_LIST][key].regions).map((regionKey) => {
      const region = regions[REGION_LIST][key].regions[regionKey];
      region.id = regionKey;
      newRegions[regionKey] = region;
      const offer = regions[REGION_LIST][key].regions[regionKey].offer;
      region.price = getPriceAmountFromOffer(offer);

      if (country.offer === undefined || country.price > region.price) {
        country.offer = offer;
        country.price = region.price;
      }
    });
    country.regions = sortBy(newRegions, 'price');
    return country;
  }), 'price');
};

/**
 * Returns regions object as array.
 *
 * @param regions {object}
 * @returns {array}
 */

const parseRegions = (regions: object): any => {
  const regionListType = has(regions, REGION_LIST) ? REGION_LIST : SKI_REGION_LIST;

  return regionListType === REGION_LIST
    ? getRegionList(regions)
    : getSkiRegionList(regions);
};

export default parseRegions;
