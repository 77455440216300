/* eslint-disable no-param-reassign */
import Cookies from 'js-cookie';

import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import { ACCESS_TOKEN_KEY } from 'libs/auth-provider/src/lib/constants';

export interface AxiosTokenRefreshOptions {
  statusCodes?: Array<number>;
  onRetry?: (requestConfig: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
}

export interface AxiosTokenRefreshCache {
  skipInstances: AxiosInstance[];
  refreshCall: Promise<any> | undefined;
  requestQueueInterceptorId: number | undefined;
}

export const defaultOptions: AxiosTokenRefreshOptions = {
  statusCodes: [412],
};

const createTokenRefreshInterceptor = (
  instance: AxiosInstance,
  tokenRefreshCall: (error: any) => Promise<any>,
  options?: AxiosTokenRefreshOptions,
) => {
  options = { ...defaultOptions, ...options };

  const cache: AxiosTokenRefreshCache = {
    skipInstances: [],
    refreshCall: undefined,
    requestQueueInterceptorId: undefined,
  };

  return instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: any) => {
      const originalRequest = error.config;

      cache.skipInstances.push(instance);

      if (options?.statusCodes?.includes(error.response.status) && !originalRequest?._retry) {
        originalRequest._retry = true;

        await tokenRefreshCall(error).then((response) => {
          const { token } = response.data.data;

          Cookies.set(ACCESS_TOKEN_KEY, token);

          originalRequest.headers = {
            ...originalRequest.headers,
            'X-Token': token,
          };

          return instance(originalRequest);
        }).catch((error) => {
          Promise.reject(error);
        });
      }

      return Promise.reject(error);
    },
  );
};

export {
  createTokenRefreshInterceptor,
};
