import * as Sentry from '@sentry/react';

const RELEASE = process.env.NX_RELEASE || 'dev';
const isDev = process.env.NODE_ENV === 'development';

Sentry.init({
  dsn: 'https://f7f2f73599cb3a053a8daf5149ba24e6@sentrelay.merlinx.eu/4506665246130176',
  release: isDev ? 'dev' : RELEASE,
  environment: isDev ? 'dev' : 'production',
  sampleRate: 1.0,
  beforeSend: isDev ? (event, hint) => {
    setTimeout(() => console.error(hint?.originalException || hint?.syntheticException), 0);
    return event;
  } : undefined,
});
