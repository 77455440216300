import React from 'react';
import { useTranslation } from 'react-i18next';

import { BOOKING_STATUS_COLOR, BOOKING_STATUS_TYPES, STATUS_DESCRIPTIONS } from '@ess/constants/status';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Tooltip from '@ess/ui/Tooltip';
import Loader from '@ess/ui/Loader';

type BookingStatusProps = {
  status: string
  onClick?: (event: React.MouseEvent) => void
  isLoading?: boolean
}

const getStatus = (status: string) => {
  const statusType = status in BOOKING_STATUS_TYPES
    ? BOOKING_STATUS_TYPES[status]
    : undefined;

  return {
    statusIcon: status,
    color: statusType && statusType in BOOKING_STATUS_COLOR
      ? BOOKING_STATUS_COLOR[statusType]
      : '#000',
    description: statusType && statusType in STATUS_DESCRIPTIONS
      ? STATUS_DESCRIPTIONS[statusType]
      : '',
  };
};

const BookingStatus = ({ status, onClick = undefined, isLoading = false }: BookingStatusProps) => {
  const { t } = useTranslation();
  const { statusIcon, color, description } = getStatus(status);

  const onClickHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (onClick) {
      onClick(event);
    }
  };

  if (!statusIcon) {
    return null;
  }

  return (
    <FlexBox onClick={onClickHandler}>
      {isLoading ? (<Loader type="dots" size="18px"/>) : (
        <Tooltip content={description ? t(description) : ''} disabled={!description}>
          <Text color={color} fontSize="12px" fontWeight="bold">{statusIcon}</Text>
        </Tooltip>
      )}
    </FlexBox>
  );
};

export {
  BookingStatus,
};
