import styled, { css } from 'styled-components';

const GridTemplateColumns = css`
  grid-template-columns: 25px 25px 25px minmax(100px, 400px) minmax(80px, 200px) minmax(50px, 1fr) minmax(80px, 1fr) 32px;
`;

const BasketHeader = styled.div`
  display: grid;
  align-items: center;
  padding: 10px 30px 10px 20px;
  ${GridTemplateColumns}
`;

const BasketItem = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 4px 0px;
  ${GridTemplateColumns}
`;

export const Styled = {
  BasketItem,
  BasketHeader,
};
