export const areHookInputsEqual = (nextDeps: any[], prevDeps: any[]): boolean => {
  if (nextDeps.length !== prevDeps.length) {
    return false;
  }

  for (let i = 0; i < prevDeps.length && i < nextDeps.length; i++) {
    if (Object.is(nextDeps[i], prevDeps[i])) {
      // eslint-disable-next-line no-continue
      continue;
    }
    return false;
  }
  return true;
};
