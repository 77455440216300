import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';

import { IconButton } from '@ess/ui/Button';
import Dropdown, { DropdownProps } from '@ess/ui/Dropdown';

import { useAgentSettings } from '@ess/components/AgentSettings';

const EllipsisMenu = (props: Omit<DropdownProps, 'target' | 'hoverOpensDropdown'>) => {
  const dropdownMouseover = useAgentSettings((state) => state.values.searchResults.dropdownMouseover);

  return (
    <Dropdown
      hoverOpensDropdown={dropdownMouseover}
      target={(
        <IconButton
          icon={(<FontAwesomeIcon icon={faEllipsisV}/>)}
          radius="rounded"
          size="medium"
        />
      )}
      {...props}
    />
  );
};

export { EllipsisMenu };
