import React, { useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useNavigateWithParams } from '@ess/hooks/useNavigateWithParams';
import { useTravelCompass } from '@ess/hooks/useTravelCompass';

import { useLayoutSettings } from '@merlin/context';

import { DesktopMenu } from './Desktop';
import { MobileMenu } from './Mobile';

const menuViews = {
  desktop: (props: any) => <DesktopMenu {...props}/>,
  mobile: (props: any) => <MobileMenu {...props}/>,
};

type MenuProps = {
  view: 'desktop' | 'mobile';
}

const Menu = ({ view }: MenuProps) => {
  const navigate = useNavigateWithParams();
  const { menu } = useLayoutSettings(useShallow((state) => ({ menu: state.bookmarks })));
  const { openTravelCompass } = useTravelCompass();

  const onMenuItemClick = useCallback((event: React.MouseEvent, item: any) => {
    event.preventDefault();

    if (!item?.url) {
      const label = item.label;

      if (label.includes('travelcompass')) {
        openTravelCompass('system');
      }

      return;
    }

    const isTourop = item.url.includes('tourop');
    const search = isTourop ? undefined : { include: ['sf'] };

    if (isTourop) {
      window.location.href = `${window.location.origin}${item.url}`;
    } else {
      navigate(`${item.url}`, search);
    }
  }, []);

  return (
    <>
      {menuViews[view]({
        menu,
        onMenuItemClick,
      })}
    </>
  );
};

export {
  Menu,
};
