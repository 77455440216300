import React, { useContext, useMemo, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { has, includes } from 'lodash-es';
import { useToggle } from 'react-use';
import {
  useFloating,
  useHover,
  useInteractions,
  FloatingPortal,
  autoPlacement,
  size, safePolygon,
} from '@floating-ui/react';

import { IDictionary, TAttributesItem } from '@ess/types';

import { ATTRIBUTES_PRIO_ITEMS, ATTRIBUTES_EXTENDEDVIEW_MAX_ITEMS } from '@ess/constants/attributes';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import { getAttributeIcon } from '@ess/utils';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Tooltip from '@ess/ui/Tooltip';
import { Card } from '@ess/ui/Card';

import { ScrollBar } from '@ess/components/ScrollBar';
import { Styled } from './HotelAttribute.styles';

const hotelAttributesTooltipDescription = (
  item: string,
  dictionary: IDictionary<{code: string, text: string, value: number}>,
) => (
  has(dictionary, item) ? dictionary[item].text : item
);

type HotelAttributeProps = {
    icon: any
    tooltipContent: string
    isHighlighted: boolean
};

type RestAttributeProps = {
  attributes: Array<string>
  dictionary: IDictionary<TAttributesItem>
  selectedAttributes: string[]
}

const RestAttributesTooltip = ({ attributes, dictionary, selectedAttributes }: RestAttributeProps) => (
  <>
    {attributes.map((item, index) => {
      const isHighlighted = includes(selectedAttributes, item);
      const icon = getAttributeIcon(item);
      const description = hotelAttributesTooltipDescription(item, dictionary);
      const lastElement = attributes.length - 1 === index;

      return (
        <FlexBox
          key={item}
          alignItems="center"
          mb={lastElement ? null : 'small'}
          style={{
            fontSize: '16px',
          }}
        >
          <Styled.RestHotelAttribute isHighlighted={isHighlighted}>
            <FontAwesomeIcon icon={icon as any}/>
          </Styled.RestHotelAttribute>
          <Text ml="small">{description}</Text>
        </FlexBox>
      );
    })}
  </>
);

const HotelAttributeExtend = ({ icon, tooltipContent, isHighlighted }: HotelAttributeProps) => (
  <Tooltip content={tooltipContent}>
    <Styled.HotelAttribute isHighlighted={isHighlighted}>
      <FontAwesomeIcon icon={icon as any}/>
    </Styled.HotelAttribute>
  </Tooltip>
);

type HotelAttributesGroupProps={
    attributes: string[],
    selectedAttributes: string[]
};

const HotelAttributesExtendGroup = ({ attributes, selectedAttributes }: HotelAttributesGroupProps) => {
  const [isOpen, setIsOpen] = useToggle(false);
  const { state: SFContext } = useContext(AppConfigContext);
  const { dictionary } = SFContext;
  const attributesDictionary = dictionary['Accommodation.Attributes'];

  const parsedAttributes = useMemo(() => {
    let numberOfEntries = 0; // counter how many in tooltal
    const newAttributesArray: string[] = [];
    const restAttributes: string[] = [];

    ATTRIBUTES_PRIO_ITEMS.map((item:string) => {
      if (includes(attributes, item)) {
        if (numberOfEntries < ATTRIBUTES_EXTENDEDVIEW_MAX_ITEMS) {
          newAttributesArray.push(item);
          numberOfEntries++;
        }
      }
    });

    attributes.map((item) => {
      if (!includes(ATTRIBUTES_PRIO_ITEMS, item)) {
        if (numberOfEntries < ATTRIBUTES_EXTENDEDVIEW_MAX_ITEMS) {
          newAttributesArray.push(item);
          numberOfEntries++;
        } else {
          restAttributes.push(item);
        }
      }
    });

    return {
      attributes: newAttributesArray,
      rest: restAttributes,
    };
  }, [attributes]);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      autoPlacement({
        allowedPlacements: ['bottom-start', 'top-start'],
      }),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
      }),
    ],
  });

  const hover = useHover(context, {
    move: true,
    delay: { open: 75 },
    handleClose: safePolygon({ blockPointerEvents: true }),
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
  ]);

  return (
    <FlexBox alignItems="center">
      <Styled.HotelAttributesGroup>
        {parsedAttributes.attributes.map((item) => {
          const isHighlighted = includes(selectedAttributes, item);
          const tooltipContent = hotelAttributesTooltipDescription(item, attributesDictionary);
          const icon = getAttributeIcon(item);

          return (
            <HotelAttributeExtend
              key={item}
              icon={icon}
              tooltipContent={tooltipContent}
              isHighlighted={isHighlighted}
            />
          );
        })}
      </Styled.HotelAttributesGroup>
      {(attributes.length > ATTRIBUTES_EXTENDEDVIEW_MAX_ITEMS) && (
        <>
          {isOpen && (
            <FloatingPortal>
              <Card
                ref={refs.setFloating}
                width={250}
                style={{
                  ...floatingStyles,
                  zIndex: 99999,
                }}
                {...getFloatingProps()}
              >
                <ScrollBar>
                  <FlexBox p="small" flexDirection="column">
                    <RestAttributesTooltip
                      attributes={parsedAttributes.rest}
                      dictionary={attributesDictionary}
                      selectedAttributes={selectedAttributes}
                    />
                  </FlexBox>
                </ScrollBar>

              </Card>
            </FloatingPortal>
          )}

          <FlexBox
            ref={refs.setReference}
            ml="tiny"
            alignItems="center"
            justifyContent="center"
            backgroundColor="lighterGray"
            width="24px"
            height="24px"
            borderRadius={15}
            {...getReferenceProps()}
          >
            <Text fontWeight="bold" fontSize="11px" color="darkGray">
              {`+${attributes.length - ATTRIBUTES_EXTENDEDVIEW_MAX_ITEMS}`}
            </Text>
          </FlexBox>
        </>

      )}
    </FlexBox>
  );
};
export default memo(HotelAttributesExtendGroup);
export {
  HotelAttributeExtend,
};
