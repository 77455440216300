import styled from 'styled-components';

const Main = styled.div<{ isFullWidth?: boolean }>`
  display: flex;
  height: 100%;
  padding-top: var(--header-height);
`;

export const Styled = {
  Main,
};
