import { createStore } from 'zustand';

import { Store, Elements, IElement } from './types';

type FoundElement = {
  side: string;
  element: IElement;
}

const findElement = (elements: Elements, key: string): FoundElement => {
  let foundElement = {} as FoundElement;

  Object.keys(elements).map((side) => {
    const elementsArray = elements[side];
    const findElement = elementsArray.find((element) => element.key === key);

    if (findElement) {
      foundElement = { side, element: findElement };
    }
  });

  return foundElement;
};

const createColumnsManagerStore = (initState?: Partial<Store>) => {
  const INITIAL_STATE = {
    elements: {
      left: [],
      right: [],
      outside: [],
    },
    columnsState: {
      left: {
        fillSpace: false,
        availableWidth: 0,
        hasVisibleElements: false,
      },
      right: {
        fillSpace: false,
        availableWidth: 0,
        hasVisibleElements: false,
      },
    },
  };

  return createStore<Store>((set, getState) => {
    const initialState = { ...INITIAL_STATE, ...initState };

    return {
      ...initialState,
      updateColumnsState: (column: string) => {
        const elements = getState().elements[column];

        set((state) => ({
          ...state,
          columnsState: {
            ...state.columnsState,
            [column]: {
              ...state.columnsState[column],
              fillSpace: elements.some((element) => element.fillSpace && element.isVisible),
              hasVisibleElements: elements.some((element) => element.isVisible),
            },
          },
        }));
      },
      setAvailableWidth: (column: string, width: number) => {
        set((state) => ({
          ...state,
          columnsState: {
            ...state.columnsState,
            [column]: {
              ...state.columnsState[column],
              availableWidth: width,
            },
          },
        }));

        getState().updateColumnsState(column);
      },
      setElements: (elements) => {
        set((state) => ({
          ...state,
          elements,
        }));
      },
      moveElement: (key, toColumn) => {
        const elements = getState().elements;
        const { element, side } = findElement(elements, key);

        if (side === toColumn) {
          return;
        }

        set((state) => ({
          ...state,
          elements: {
            ...state.elements,
            [side]: [...state.elements[side]]?.filter((element) => (element.key !== key)),
            [toColumn]: [element, ...state.elements[toColumn]],
          },
        }));

        getState().updateColumnsState(side);
        getState().updateColumnsState(toColumn);
      },
      toggleElement: (key, isVisible) => {
        const elements = getState().elements;
        const { element, side } = findElement(elements, key);

        if (element) {
          set((state) => ({
            ...state,
            elements: {
              ...state.elements,
              [side]: state.elements[side]?.map((element) => (
                element.key === key ? ({
                  ...element,
                  isVisible,
                }) : element
              )),
            },
          }));

          getState().updateColumnsState(side);
        }
      },
    };
  });
};

export {
  createColumnsManagerStore,
};
