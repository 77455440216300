import {
  isEmpty, isEqual, assign, has,
} from 'lodash-es';

import {
  IConditions,
  ISearchBase,
} from '@ess/types';

import { getUngroupedOperators, getParticipantsSearchSchema } from '@ess/utils';

import {
  IViewObject,
  View,
  conditionsDepth,
} from './interfaces';

export const compareConditions = (c1:any, c2:any):boolean => ((c1.length > c2.length)
  ? isEqual(c1.slice(0, c2.length), c2)
  : isEqual(c1, c2.slice(0, c1.length)));

export interface IViewFilterList {
    fields: IViewObject[]
}

interface IRequest {
    conditions: any[]
    isGet: boolean
    subscribers: IViewObject[]
}

const PRIORITY_MAP: any = {
  'Accommodation.XCode': {
    searchBaseKey: 'Hotels',
    localStorageKey: 'FavouriteHotels',
  },
};

const getPrioritizedValues = (groupBy: any) => {
  const groupByKey = groupBy?.key;
  const priority = has(PRIORITY_MAP, groupByKey) ? PRIORITY_MAP[groupByKey] : false;

  if (priority && groupByKey) {
    const { localStorageKey } = priority;
    const storedValues = localStorage.getItem(localStorageKey);

    return storedValues ? JSON.parse(storedValues) : [];
  }

  return [];
};

// Class responsible for creating and merging requests if possible
// It will create request array with information which View should get data from which request

export class RequestBuilder {
  requests: IRequest[];

  constructor() {
    this.requests = [];
  }

  addRequest(newSubscriber: IViewObject, conditions: any[], conditionsFilter = (c:any) => c) {
    // Add parameters to New or Old request (if conditions match)

    if (isEmpty(conditions[0].p)) {
      return;
    }

    let index = this.requests.findIndex((value) => {
      // conditions should match to min(value.conditions.length,conditions.length)
      // then we can use same request
      const valConditions = conditionsFilter(value.conditions);
      const viewConditions = conditionsFilter(conditions);

      const conditionsOk = (valConditions.length > viewConditions.length)
        ? isEqual(valConditions.slice(0, viewConditions.length), viewConditions)
        : isEqual(valConditions, viewConditions.slice(0, valConditions.length));

      if (!conditionsOk) {
        return false;
      }

      /*        // If conditions match, we check for existing view types
                                    // if same view type exists in request, we can't use same request
                                    const sameViewTypeNotFound = !value.subscribers.some((subscriber: IViewObjectSubscriber) => subscriber.vo.type === newSubscriber.vo.type);
                                    // It should not happen (it can be a result of using 2 views of same data with different fields at the same time,
                                    // avoid it, because it create additional request)
                                    console.assert(!sameViewTypeNotFound, '2 views of same section with different fields');
                                    return sameViewTypeNotFound; */

      return value.subscribers[newSubscriber.type] === undefined;
    });

    if (index === -1) {
      const isGet = !!((conditions[0].p.offerDetailsId ?? false));

      index = this.requests.length;

      this.requests.push({ conditions, isGet, subscribers: Array(View._LENGTH) });
    } else if (this.requests[index].conditions.length < conditions.length) {
      this.requests[index].conditions = conditions;
    }
    this.requests[index].subscribers[newSubscriber.type] = newSubscriber;
  }

  /**
   * Returns modified filters conditions.
   * @param conditions
   */
  fixFiltersConditions(conditions: IConditions): IConditions {
    let newConditions: IConditions | null = null;

    // xcode has to be integer, not strings
    if (!isEmpty(conditions?.Accommodation?.XCode) && conditions?.Accommodation?.XCode !== undefined) {
      newConditions = {
        ...conditions,
        Accommodation: {
          ...conditions.Accommodation,
          XCode: conditions.Accommodation.XCode.map((xCodeString: string | number) => Number(xCodeString)),
        },
      };
    }

    // Mark all attributes as required
    if (!isEmpty(conditions?.Accommodation?.Attributes) && conditions?.Accommodation?.Attributes !== undefined) {
      newConditions = {
        ...(newConditions ?? conditions),
        Accommodation: {
          ...(newConditions ?? conditions).Accommodation,
          Attributes: conditions.Accommodation.Attributes.map((attribute: string) => (
            attribute?.charAt(0) === '+' ? attribute : `+${attribute}`),
          ),
        },
      };
    }

    // region id hast bo string (for skiRegions its number)
    if (conditions?.Base?.DestinationLocation !== undefined && 'Id' in conditions.Base.DestinationLocation
          && !isEmpty(conditions.Base.DestinationLocation.Id)) {
      newConditions = {
        ...(newConditions ?? conditions),
        Base: {
          ...(newConditions ?? conditions).Base,
          DestinationLocation: {
            Id: conditions.Base.DestinationLocation.Id.map((locationId: number | string) => String(locationId)),
          },
        },
      };
    }

    return newConditions ?? conditions;
  }

  /**
   * Returns modified search conditions.
   * @param conditions
   * @param searchBase
   */
  fixSearchConditions(conditions: IConditions, searchBase: any): IConditions {
    let newConditions: IConditions | null = null;

    const priceValue = conditions.Base?.Price as any;
    const priceType = Object.keys(priceValue ?? {}).filter((item) => !isEmpty(priceValue[item]))[0];
    const maxPrice = searchBase[`Base.Price.${priceType}.Max`]?.num?.max;
    const { Min, Max } = priceValue?.[priceType] ?? {};

    const searchOperators = conditions.Base?.Operator;
    const defaultOperators = Object.keys(searchBase['Base.Operator']?.list?.values ?? []);
    const selectedOperators = searchOperators && searchOperators.length ? searchOperators : defaultOperators;

    newConditions = {
      ...conditions,
      Base: {
        ...conditions.Base,
        ParticipantsList: getParticipantsSearchSchema(conditions.Base?.ParticipantsList as any),
        Operator: getUngroupedOperators(selectedOperators),
        Price: {
          [priceType]: (Max === maxPrice) ? { Min } : {
            Min,
            Max,
          },
        },
      },
    };

    // Mark all attributes as required
    if (!isEmpty(conditions?.Accommodation?.Attributes) && conditions?.Accommodation?.Attributes !== undefined) {
      newConditions = {
        ...(newConditions ?? conditions),
        Accommodation: {
          ...conditions.Accommodation,
          Attributes: conditions.Accommodation.Attributes.map((attribute: string) => (
            attribute.charAt(0) === '+' ? attribute : `+${attribute}`),
          ),
        },
      };
    }

    return newConditions ?? conditions;
  }

  getAjaxRequests(reqlist:any[], searchbase: ISearchBase): any[] {
    reqlist.filter(([vo]) => vo.type !== View.RegionList && vo.type !== View.SkiRegionList).forEach(
      ([vo, conditions]) => this.addRequest(vo, conditions),
    );
    reqlist.filter(([vo]) => vo.type === View.RegionList || vo.type === View.SkiRegionList).forEach(
      ([vo, conditions]) => this.addRequest(vo, conditions, (c) => {
        let ret = c;
        if (c.length >= conditionsDepth.Filtered) {
          ret = [c[0], assign({}, c[1], { Base: { DestinationLocation: { Id: [] } } }, []), ...c.slice(2)];
          if (isEmpty(ret[1].p?.Base)) {
            ret[1].p = {};
          }
        }
        return ret;
      }),
    );
    return this.requests.map((request) => {
      const {
        conditions,
        subscribers,
      } = request;

      const groupList = subscribers[View.GroupedList];
      const offerList = subscribers[View.OfferList];
      const accommodationSupplementary = subscribers[View.AccommodationSupplementary];

      if (request.isGet) {
        return {
          get: `data/travel/details?Base.OfferId=${conditions[0].p.offerDetailsId}`,
          ajax: null,
          subscribers,
        };
      }

      const groupBy = {
        ...(conditions.length - 1 >= conditionsDepth.GroupKey
          ? { key: conditions[conditionsDepth.GroupKey].p } : false),
        ...(conditions.length - 1 >= conditionsDepth.SlectedObject
          ? { value: conditions[conditionsDepth.SlectedObject].p } : false),
      };

      const prioritizeGroupValues = getPrioritizedValues(groupBy);

      return {
        ajax: {
          conditions: {
            ...(conditions.length - 1 >= conditionsDepth.Search
              ? { search: this.fixSearchConditions(conditions[conditionsDepth.Search].p, searchbase) } : {}),
            ...(conditions.length - 1 >= conditionsDepth.Filtered
              ? { filter: this.fixFiltersConditions(conditions[conditionsDepth.Filtered].p) } : {}),
          },
          ...(groupBy?.key ? {
            results: {
              groupBy,
            },
          } : {}),
          views: {
            ...(subscribers[View.SkiRegionList] !== undefined ? {
              skiRegionList: {
                fieldList: [...subscribers[View.SkiRegionList].fieldList],
              },
            } : {}),
            ...(subscribers[View.RegionList] !== undefined ? {
              regionList: {
                fieldList: [...subscribers[View.RegionList].fieldList],
              },
            } : {}),
            ...(accommodationSupplementary !== undefined ? {
              offerList: {
                limit: 60,
                fieldList: [...accommodationSupplementary.fieldList, 'Base.OfferId'],
                orderBy: accommodationSupplementary.order,
              },
            } : {}),
            ...(groupList !== undefined ? {
              groupedList: {
                limit: 60,
                fieldList: [...groupList.fieldList, 'Base.OfferId'],
                orderBy: groupList.order,
                ...groupList.loadMoreRequested ? {
                  previousPageBookmark: groupList.previousPageBookmark,
                } : {},
                ...prioritizeGroupValues.length ? {
                  prioritizeGroupValues,
                } : {},
              },
            } : {}),
            ...(subscribers[View.OfferListFieldValues] !== undefined ? {
              offerListFieldValues: {
                fieldList: subscribers[View.OfferListFieldValues].fieldList,
              },
            } : {}),
            ...(offerList !== undefined ? {
              offerList: {
                limit: 30,
                fieldList: [...offerList.fieldList, 'Base.OfferId'],
                orderBy: offerList.order,
                ...offerList.loadMoreRequested ? {
                  previousPageBookmark: offerList.previousPageBookmark,
                } : {},
                ...offerList?.orderPriority && offerList?.orderPriority?.mapping.length && groupBy?.key ? {
                  orderByPriority: [offerList.orderPriority],
                } : {},
              },
            } : {}),
          },
        },
        subscribers,
      };
    });
  }
}
