import React, { useEffect } from 'react';
import loadable from '@loadable/component';

import { HelmetContainer } from '@merlin/containers';
import { useLayoutSettings } from '@merlin/context';

import { useNetworkStatus } from '@ess/v5-data-provider/components';

import NetworkStatus from '@tourop/components/NetworkStatus';

import { Layout } from '@ess/components/layout';

const AgentSettingsDrawer = loadable(async () => {
  const { AgentSettingsDrawer } = await import(/* webpackChunkName: "agent-settings-drawer" */'@ess/components/AgentSettings');
  return AgentSettingsDrawer;
});

type MerlinLayoutProps = {
  children: React.ReactElement;
}

const MerlinLayout = ({ children }: MerlinLayoutProps) => {
  const fetchSettings = useLayoutSettings((state) => state.fetchSettings);

  useEffect(() => {
    (async () => {
      await fetchSettings();
    })();
  }, []);

  return (
    <>
      <AgentSettingsDrawer/>
      <NetworkStatus useNetworkStatusHook={useNetworkStatus}/>
      <HelmetContainer>
        <Layout>
          <Layout.Header/>
          <Layout.Main>
            <Layout.ColumnsManager
              center={(
                <Layout.Container>
                  {children}
                </Layout.Container>
              )}
            />
          </Layout.Main>
        </Layout>
      </HelmetContainer>
    </>
  );
};

export {
  MerlinLayout,
};
