import React, {
  forwardRef, memo, useEffect, useRef,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faLock } from '@fortawesome/pro-solid-svg-icons';

import useElementSize from '@ess/hooks/useElementSize';

import TextInput from '@ess/ui/Form/TextInput';

import Tags from './Tags';

import { Styled } from '../MultiSelectV2.styles';

type FieldProps = {
  label: any
  value?: any[]
  selectedOptions: any
  isOpen: boolean
  placeholder?: string
  isDisabled?: boolean
  onClick: () => void
  onClear: () => void
}

const Field = forwardRef<HTMLDivElement, FieldProps>(({
  label,
  value = [],
  selectedOptions,
  placeholder = '',
  isDisabled = false,
  onClick,
  onClear,
  isOpen,
}, ref) => {
  const inputContainer = useRef<HTMLElement>(null);
  const { size: { width }, forceUpdate } = useElementSize(inputContainer);

  useEffect(() => {
    forceUpdate();
  }, [selectedOptions]);

  return (
    <Styled.MultiSelect__Field ref={inputContainer as any} isOpen={isOpen}>
      {label && (
      <Styled.MultiSelect__Label>
        {label}
      </Styled.MultiSelect__Label>
      )}
      <Styled.MultiSelect__Field__Input ref={ref}>
        {selectedOptions.length > 0 && (
          <Tags options={selectedOptions} inputWidth={width}/>
        )}
        <TextInput
          onClick={onClick}
          onClear={onClear}
          value={selectedOptions.length ? ' ' : ''}
          placeholder={!selectedOptions.length ? placeholder : ''}
          readOnly
          isDisabled={isDisabled}
          isClearable
          icon={!isDisabled ? (
            <Styled.Multiselect__Field__DropdownIndicator>
              <FontAwesomeIcon icon={faAngleDown}/>
            </Styled.Multiselect__Field__DropdownIndicator>
          ) : (
            <Styled.Multiselect__Field__DisabledIcon>
              <FontAwesomeIcon
                icon={faLock}
                size="sm"
              />
            </Styled.Multiselect__Field__DisabledIcon>
          )}
        />
      </Styled.MultiSelect__Field__Input>
    </Styled.MultiSelect__Field>
  );
});

export default memo(Field);
