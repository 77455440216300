import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';

import { IPrice } from '@ess/types';

import Text from '@ess/ui/Text';
import FlexBox from '@ess/ui/FlexBox';
import Tooltip from '@ess/ui/Tooltip';

import TooltipContent from './TooltipContent';

type TFGProps = {
  price: IPrice | undefined,
}

/**
 * Returns tfg data;
 * @param data
 */
export const getTFGData = (data: IPrice) => {
  const { details, currency } = data ?? {};

  let parsedData: any = {
    included: details?.TFGIncluded,
    data: null,
  };

  if (details?.TFGData) {
    const [tfgAmount, tfpAmount] = details.TFGData.split('/');

    parsedData = {
      ...parsedData,
      data: {
        items: [
          {
            name: 'tfg',
            amount: tfgAmount,
            currency,
          },
          {
            name: 'tfp',
            amount: tfpAmount,
            currency,
          },
        ],
        sum: {
          amount: `${Number(tfgAmount) + Number(tfpAmount)}`,
          currency,
        },
      },
    };
  }

  return parsedData;
};

const TFGData = ({ price }: TFGProps) => {
  const { t } = useTranslation();
  const tfg = price ? getTFGData(price) : false;

  return tfg && !tfg.included ? (
    <FlexBox alignItems="center" flexWrap="wrap">
      <Text as="span" lineHeight="1">
        {t('tfg_not_included')}
      </Text>

      {tfg?.data && (
        <FlexBox
          as="span"
          ml="tiny"
          alignItems="center"
        >
          <Text
            as="span"
            lineHeight="1"
          >
            {`(+ ${tfg.data.sum.amount} ${tfg.data.sum.currency} / ${t('person_shortcut')})`}
          </Text>
          {tfg.data?.sum?.amount > 0 && (
            <FlexBox
              as="span"
              ml="tiny"
              style={{ fontSize: '15px' }}
            >
              <Tooltip theme="bubble-wide" content={<TooltipContent data={tfg.data}/>}>
                <FontAwesomeIcon icon={faCircleInfo}/>
              </Tooltip>
            </FlexBox>
          )}
        </FlexBox>
      )}
    </FlexBox>
  ) : null;
};

export default TFGData;
