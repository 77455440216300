import { includes } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { showToast } from '@ess/utils';

import useStorage from '@ess/hooks/useStorage';
import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';

const MAX_HOTELS = 500;

/**
 * useFavouriteHotels hook.
 *
 * Manages favourite hotels storage.
 */
const useFavouriteHotels = () => {
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const [favouriteHotels, setFavouriteHotels] = useStorage<any>('localStorage', 'FavouriteHotels', []);

  /**
   * Clear stored favourite hotels.
   */
  const reset = () => {
    setFavouriteHotels([]);
    showToast(1, t('favourite_hotels_cleared'));
  };

  /**
   * Add/Remove item from favourite hotels.
   * @param value
   * @param name
   */
  const add = (value: string, name: string) => {
    trackEvent({
      event: 'prioritizedHotels',
      eventCategory: 'B2B_CLICK_EVENT',
      eventAction: 'B2B_PRIORITIZED_HOTELS',
    });

    setFavouriteHotels((state: any) => {
      const isInFavourites = includes(state, value);
      const message = t(!isInFavourites ? 'hotel_added_to_favourite' : 'hotel_removed_from_favourite', { name });

      if (!isInFavourites && state.length === MAX_HOTELS) {
        showToast(1, t('favourite_hotels_limit', { count: MAX_HOTELS }));
        return state;
      }
      showToast(1, message);
      return isInFavourites ? [...state].filter((item) => item !== value) : [...state, value];
    });
  };

  /**
   * Remove value from favouriteHotels.
   * @param value
   * @param name
   */
  const remove = (value: string, name: string) => {
    showToast(1, t('hotel_removed_from_favourite', { name }));
    setFavouriteHotels((state: any) => [...state].filter((item) => item !== value));
  };

  return {
    add,
    remove,
    reset,
    favouriteHotels,
  };
};

export default useFavouriteHotels;
