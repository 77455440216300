import styled from 'styled-components';

const Container = styled.div<{ width?: string }>`
  width: 100%;
  max-width: ${({ width }) => (width || 'var(--page-width)')};
  margin: 0 auto;
  height: 100%;
  padding: 0  ${({ theme }) => theme.space.medium};
`;

export const Styled = {
  Container,
};
