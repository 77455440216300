import React, {
  createContext, useEffect, useRef, useState,
} from 'react';
import Cookies from 'js-cookie';

import { initI18n } from '@tourop/config/i18n';

import { getCurrentLanguage } from '@ess/utils';

import {
  LoginSettingStore,
  createLoginSettingsStore,
  Language,
} from './store';

const LoginSettingsContext = createContext<LoginSettingStore | null>(null);

type LoginSettingsProviderProps = {
  children: React.ReactNode;
}

const LoginSettingsProvider = ({ children }: LoginSettingsProviderProps) => {
  const storeRef = useRef<LoginSettingStore>();
  const [isReady, setIsReady] = useState(false);

  if (!storeRef.current) {
    storeRef.current = createLoginSettingsStore();
  }

  useEffect(() => {
    (async () => {
      if (storeRef?.current) {
        const currentLanguage = getCurrentLanguage();
        const response = await storeRef.current.getState().fetchSettings();
        const language = currentLanguage || response?.data?.languages.find((item: Language) => item.default)?.code || 'pl';

        Cookies.set('language', language);

        await initI18n({
          language,
          modules: ['common', 'basket', 'ibe', 'search', 'authorization'],
          unstableConnectionSupport: true,
        });

        setIsReady(true);
      }
    })();
  }, []);

  return (
    <LoginSettingsContext.Provider value={storeRef.current}>
      {isReady && children}
    </LoginSettingsContext.Provider>
  );
};

export {
  LoginSettingsProvider,
  LoginSettingsContext,
};
