import { IDictionary } from '@ess/types';

export const objectToArray = (data: IDictionary<any>, keyName = '') => {
  if (data === undefined || data === null) {
    return data;
  }

  return Object.keys(data).map((key) => (
    { ...data[key], ...keyName ? { [keyName]: key } : {} }
  ));
};
