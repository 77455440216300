import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useShallow } from 'zustand/react/shallow';

import { useAuth } from '@ess/auth-provider';

import { showSettingsDrawer } from '@ess/store/slices/baseSlice';

import { useLayoutSettings } from '@merlin/context';

import Dropdown, { DropdownItem } from '@ess/ui/Dropdown';
import Text from '@ess/ui/Text';
import Avatar from '@ess/ui/Avatar';
import Skeleton from '@ess/ui/Skeleton';

import { Styled } from './User.style';

type UserProps = {
  avatarSize?: number;
  displayedName?: 'name' | 'consultantCode';
  showAgency?: boolean;
  menuEnabled?: boolean;
}

const User = ({
  displayedName = undefined,
  showAgency = false,
  avatarSize = 34,
  menuEnabled = true,
}: UserProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = useAuth(useShallow((state) => state.logout));
  const {
    userProfile, userData, isLoading, isError,
  } = useLayoutSettings(useShallow((state) => ({
    userProfile: state.userProfile,
    userData: state.userData,
    isLoading: state.isLoading,
    isError: state.isError,
  })));

  const userMenu: DropdownItem[] = useMemo(() => [
    {
      label: (t('lbl_profile')),
      value: 'profile',
      hide: !userProfile?.show,
    },
    {
      label: `${t('lbl_agent_settings')}`,
      value: 'agentSettings',
      hide: false,
    },
    {
      label: (t('lbl_logout')),
      value: 'logout',
      hide: false,
    },
  ], [userProfile]);

  const openAgentSettings = () => dispatch(showSettingsDrawer(true));

  const onOpenAgentProfile = () => {
    window.open(`${window.location.origin}/tourop${userProfile?.url ?? ''}`, '_blank');
  };

  const onDropdownItemClick = (item: string) => {
    const actions: Record<string, () => void> = {
      logout: () => {
        logout({
          onSuccess: () => navigate('/login'),
        });
      },
      agentSettings: () => {
        openAgentSettings();
      },
      profile: () => {
        onOpenAgentProfile();
      },
    };

    if (item in actions) {
      actions[item]();
    }
  };

  if (isLoading) {
    return (
      <Styled.User>
        <Skeleton width={`${avatarSize}px`} height={`${avatarSize}px`} borderRadius="100px" bg="#3161ab"/>
        {displayedName && (
          <Styled.User__Data>
            <Skeleton width="40px" height="16px" borderRadius="8px" bg="#3161ab"/>
            {showAgency && (
              <Skeleton mt="2px" width="60px" height="16px" borderRadius="8px" bg="#3161ab"/>
            )}
          </Styled.User__Data>
        )}
      </Styled.User>
    );
  }

  if (isError || !userData) {
    return null;
  }

  return (
    <Styled.User>
      <Dropdown
        options={userMenu.filter((option: any) => !option.hide)}
        menuMinWidth={200}
        isDisabled={!menuEnabled}
        placement="bottom-start"
        onItemClick={onDropdownItemClick}
        header={(
          <Styled.User__DropdownHeader>
            <Text fontWeight="bold" mb="tiny" lineHeight="16px">{userData?.name}</Text>
            <Text fontSize="12px" color="textSecondaryColor">{`${t('lbl_agency')}: ${userData?.agencyNumber}`}</Text>
          </Styled.User__DropdownHeader>
        )}
        target={(
          <Avatar
            size={avatarSize}
            img={userData?.imageUrl}
            alt={userData?.name as string}
          />
        )}
      />
      {displayedName && (
        <Styled.User__Data>
          <Styled.User__Data__Name>
            {userData[displayedName]}
          </Styled.User__Data__Name>
          {showAgency && (
            <Styled.User__Data__Agency>
              {`${t('lbl_agency')}: ${userData.agencyNumber}`}
            </Styled.User__Data__Agency>
          )}
        </Styled.User__Data>
      )}
    </Styled.User>
  );
};

export {
  User,
};
