import React, { useLayoutEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useColumnsManager } from './provider/useColumnsManager';

import { Column, Element } from './components';

import { Styled } from './ColumnsManager.style';

type ColumnsManagerProps = {
  center: React.ReactElement;
}

const ColumnsManager = ({ center }: ColumnsManagerProps) => {
  const [styles, setStyles] = useState<any>({ left: {}, right: {} });
  const { elements, columnsState } = useColumnsManager(useShallow((state) => ({
    elements: state.elements,
    columnsState: state.columnsState,
  })));

  useLayoutEffect(() => {
    setStyles((state: any) => ({
      ...state,
      left: columnsState.right.fillSpace
        ? !columnsState.left.hasVisibleElements
          ? { display: 'none' }
          : { maxWidth: '500px' }
        : {},
      right: {},
    }));
  }, [columnsState]);

  return (
    <>
      <Styled.ColumnsManager>
        <Column side="left" style={{ ...styles.left }}>
          {({ availableSpace }) => (
            <>
              {elements.left.map((element) => (
                <Element
                  key={element.key}
                  hasRequiredWidth={availableSpace >= element.requiredWidth}
                  Component={element.Component}
                />
              ))}
            </>
          )}
        </Column>
        {React.cloneElement(center, { width: '1349px' })}
        <Column side="right" style={{ width: styles.right }}>
          {({ availableSpace }) => {
            const hasMoreThanOne = elements.right.length > 1;

            return (
              <>
                {elements.right.map((element) => {
                  const isBasket = element.key === 'basket';
                  const isMap = element.key === 'map';
                  let hasRequiredWidth = availableSpace >= element.requiredWidth;

                  if (hasMoreThanOne) {
                    const map = elements.right.find((element) => element.key === 'map');
                    const basket = elements.right.find((element) => element.key === 'basket');

                    if (isBasket && map && map.isVisible && availableSpace <= 1000) {
                      hasRequiredWidth = false;
                    }

                    if (isMap && basket && basket.isVisible && availableSpace <= 500) {
                      hasRequiredWidth = false;
                    }
                  }

                  return (
                    <Element
                      key={element.key}
                      hasRequiredWidth={hasRequiredWidth}
                      Component={element.Component}
                    />
                  );
                })}
              </>
            );
          }}
        </Column>
      </Styled.ColumnsManager>

      {elements.outside.map((element) => (
        <Element
          key={element.key}
          hasRequiredWidth={false}
          Component={element.Component}
        />
      ))}
    </>
  );
};

export {
  ColumnsManager,
};
