import styled from 'styled-components';
import { darken } from 'polished';

const MobileMenu__Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.navy};
`;

const MobileMenu__Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const MobileMenu__Scroll = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const MobileMenu__Header = styled.div`
  display: flex;
  background-color: ${({ theme }) => darken(0.04, theme.colors.navy)};
  width: 100%;
  height: 52px;
`;

const MobileMenu__Header__Logo = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  padding-left: 52px;

  svg {
    width: 180px;
    height: auto;
  }
`;

const MobileMenu__Header__CloseIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  width: 52px;
  height: 52px;
`;

const MobileMenu__User = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0 ${({ theme }) => theme.space.medium};
`;

const MobileMenu__Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  height: 35px;
`;

const MobileMenu__Item = styled.a<{ isExpanded?: boolean}>`
  display: flex;
  cursor: pointer;
  flex-shrink: 0;
  padding: ${({ theme }) => theme.space.medium};
  font-weight: bold;
`;

const MobileMenu__Item__Icon = styled.span`
  display: flex;
  justify-content: center;
  margin-right: ${({ theme }) => theme.space.medium};
  width: 25px;
`;

const MobileMenu__Item__Expand = styled.span`
  margin-left: auto;
`;

const MobileMenu__Section = styled.div<{ isExpanded?: boolean}>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.white};
  background-color: #1b3867;
  border-radius: 12px;

  ${({ isExpanded }) => `
      ${MobileMenu__Item__Expand} {
        transform: ${isExpanded ? 'rotate(180deg)' : ''};
        transition: all .3s ease;
        will-change: transform, top;
        transition-property: transform, top;
      }
  `}
`;

const MobileMenu__Sections = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 ${({ theme }) => theme.space.medium};

  ${MobileMenu__Section}:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.small};
  }
`;

const MobileMenu__Buttons = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: ${({ theme }) => theme.space.medium};
`;

const MobileMenu__Buttons__Item = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: ${({ theme }) => theme.space.medium};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  border-radius: 12px;

  &:hover {
    color: ${({ theme }) => theme.colors.blue};
    background-color: #284b82;
  }
`;

const MobileMenu__Buttons__Item__Icon = styled.div`
  margin-right: ${({ theme }) => theme.space.medium};
`;

const MobileMenu__Item__List = styled.div`
  display: flex;
  flex-direction: column;

  ${MobileMenu__Item} {
    font-weight: normal;
    padding: ${({ theme }) => theme.space.small} ${({ theme }) => theme.space.medium};
    padding-left: 55px;

    &:hover {
      color: ${({ theme }) => theme.colors.blue};
      background-color: #284b82;
    }
  }
`;

export const Styled = {
  MobileMenu__Container,
  MobileMenu__Wrapper,
  MobileMenu__Scroll,
  MobileMenu__Header,
  MobileMenu__Header__Logo,
  MobileMenu__Header__CloseIcon,
  MobileMenu__User,
  MobileMenu__Icon,
  MobileMenu__Buttons,
  MobileMenu__Buttons__Item,
  MobileMenu__Buttons__Item__Icon,
  MobileMenu__Sections,
  MobileMenu__Section,
  MobileMenu__Item,
  MobileMenu__Item__Icon,
  MobileMenu__Item__Expand,
  MobileMenu__Item__List,
};
