import React from 'react';
import {
  SpaceProps,
  LayoutProps,
  BorderProps,
  FlexboxProps,
} from 'styled-system';

import { Styled } from './Skeleton.styles';

type StyledSystemProps = SpaceProps & LayoutProps & BorderProps & FlexboxProps;

type SkeletonProps = {
  children?: React.ReactNode;
  height?: string;
  width?: string;
  bg?: string;
  borderRadius?: string;
} & StyledSystemProps;

const Skeleton = ({
  children = null,
  bg = 'lightGray',
  width = '100px',
  height = '20px',
  borderRadius = '4px',
  ...props
}: SkeletonProps) => (
  <Styled.Skeleton
    bgColor={bg}
    height={height}
    width={width}
    borderRadius={borderRadius}
    {...props}
  >
    {children && children}
  </Styled.Skeleton>
);

export default Skeleton;
