import React from 'react';
import { Helmet } from 'react-helmet';
import { unescape } from 'lodash-es';

type HelmetContainerProps = {
  children: React.ReactNode
}

const getBookmarkName = () => {
  const storageItem = sessionStorage.getItem('MXBookmark');
  const bookmark = storageItem !== null
    ? JSON.parse(storageItem)
    : false;

  if (!bookmark?.name) {
    return '';
  }

  return decodeURIComponent(unescape(atob(bookmark.name)));
};

const HelmetContainer = ({ children }: HelmetContainerProps) => {
  const title = getBookmarkName();

  return (
    <>
      <Helmet defaultTitle="MerlinX">
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};

export {
  HelmetContainer,
};
