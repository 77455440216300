import React, { CSSProperties, useEffect } from 'react';
import { useSize, useWindowSize } from 'react-use';

import { useColumnsManager } from '../../provider/useColumnsManager';

import { Styled } from './Column.style';

interface ColumnChildrenProps {
  availableSpace: number;
}

type ColumnProps = {
  children: ((props: ColumnChildrenProps) => React.ReactNode);
  side: string;
  style: CSSProperties;
}

const Column = ({ children, side, style }: ColumnProps) => {
  const { height } = useWindowSize();
  const setAvailableWidth = useColumnsManager((state) => state.setAvailableWidth);
  const [sized, { width }] = useSize(
    ({ width }) => (
      <Styled.Column style={style} height={height}>
        <Styled.Column__Inner columnSide={side}>
          {children({ availableSpace: width })}
        </Styled.Column__Inner>
      </Styled.Column>
    ),
    { width: 100 },
  );

  useEffect(() => {
    setAvailableWidth(side, width);
  }, [width]);

  return sized;
};

export {
  Column,
};
