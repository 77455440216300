import { includes } from 'lodash-es';

import { IDictionary } from '@ess/types';

import { REGION_LIST, SKI_REGION_LIST } from '@ess/constants/search';

/**
 * Returns regions list type ( REGION_LIST | SKI_REGION_LIST ).
 * Checks if Accommodation.Attributes field contains 'location_ski_resorts' attribute.
 */

export const getRegionListType = (values: IDictionary<any>) => (
  includes(values['Accommodation.Attributes'], 'location_ski_resorts')
    ? SKI_REGION_LIST
    : REGION_LIST
);
