import React, { memo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { Button } from '@ess/ui/Button';

import { Styled } from '../MultiSelectV2.styles';

type ControlsProps = {
  onReset: () => void
  onApply: () => void
}

const Controls = ({
  onReset,
  onApply,
}: ControlsProps) => {
  const { t } = useTranslation();
  return (
    <Styled.MultiSelect__Controls isMobileOnly={isMobileOnly}>
      <Button
        mr={!isMobileOnly ? 'tiny' : 'small'}
        label={t('clear')}
        onClick={onReset}
        size="small"
        variant="secondary"
        width={isMobileOnly ? '90px' : 'auto'}
      />
      <Button
        label={t('lbl_apply')}
        onClick={onApply}
        size="small"
        width={isMobileOnly ? '90px' : 'auto'}
      />
    </Styled.MultiSelect__Controls>
  );
};

export default memo(Controls);
