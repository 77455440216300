/* Returns 'list' field type options */

export const getValuesOptions = (values) => {
  const options = [];

  if (values) {
    // eslint-disable-next-line array-callback-return
    Object.entries(values).map((key) => {
      options.push({
        label: key[1],
        value: key[0],
      });
    });
  }

  return options;
};
