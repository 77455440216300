import styled from 'styled-components';

const ColumnsManager = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    align-items: start;
    height: 100%;
`;

export const Styled = {
  ColumnsManager,
};
