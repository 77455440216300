import React, { CSSProperties, useEffect, useState } from 'react';

import { Styled } from '../Tabs.styles';

type TabsProps = {
  children: React.ReactNode[];
  onChange: (value: any) => void;
  value: any;
  style?: CSSProperties;
};

const Tabs = ({
  children,
  onChange,
  value,
  ...props
}: TabsProps) => {
  const [tabsElement, setTabsElement] = useState<HTMLDivElement | null>(null);
  const [currentTabElement, setCurrentTabElement] = useState<Element | null>(null);

  /**
   * Scroll horizontal handler.
   * @param event
   */
  const onMouseWheelHandler = (event: React.WheelEvent) => {
    tabsElement?.scrollTo({
      left: tabsElement.scrollLeft + event.deltaY,
      behavior: 'smooth',
    });
  };

  /**
   * Tab click handler.
   * @param event
   * @param value
   */
  const tabClickHandler = (event: React.MouseEvent, value: any) => {
    setCurrentTabElement(event.currentTarget);
    onChange(value);
  };

  /**
   * Set selected tab panel visible by given value.
   */
  useEffect(() => {
    const tabPanels = document.querySelectorAll('[role="tabpanel"]');
    const tabs = document.querySelectorAll('[role="tab"]');

    tabs.forEach((tab) => {
      const activeTab = tab.id === `tab-${value}`;
      tab.setAttribute('aria-selected', activeTab ? 'true' : 'false');

      if (activeTab) {
        setCurrentTabElement(tab);
      }
    });

    tabPanels.forEach((panel) => {
      const panelId = panel.id;
      const isSelected = panelId === `tab-panel-${value}`;

      if (isSelected) {
        panel.removeAttribute('hidden');
      } else {
        panel.setAttribute('hidden', 'true');
      }
    });
  }, [value]);

  /**
   * Set selected tab panel visible by given value.
   */
  useEffect(() => {
    if (currentTabElement && tabsElement) {
      const { left, right } = currentTabElement.getBoundingClientRect();
      const { left: containerLeft, right: containerRight } = tabsElement.getBoundingClientRect();
      const isFullyVisible = (left >= containerLeft && right <= containerRight);

      if (!isFullyVisible) {
        tabsElement?.scrollTo({
          left,
          behavior: 'smooth',
        });
      }
    }
  }, [currentTabElement]);

  return (
    <Styled.Tabs
      ref={setTabsElement}
      onWheel={onMouseWheelHandler}
      {...props}
    >
      {React.Children.map(children, (child) => React.cloneElement(child as any, {
        onClick: tabClickHandler,
      }))}
    </Styled.Tabs>
  );
};

export default Tabs;
