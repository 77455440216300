import styled from 'styled-components';

const AgentSettings = styled.div`
  width: 100%;
`;

const AgentSettings__Section = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.space.medium};
  background-color: ${({ theme }) => theme.colors.navy};
`;

const AgentSettings__Section__Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme }) => theme.space.medium};

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`;

export const Styled = {
  AgentSettings,
  AgentSettings__Section,
  AgentSettings__Section__Item,
};
