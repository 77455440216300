import React from 'react';
import { size } from 'lodash-es';

import { OptionTypeBase } from 'react-select';
import { OptionProps, CommonProps } from 'react-select/src/types';

import useElementSize from '@ess/hooks/useElementSize';

import Box from '@ess/ui/Box';

const MultiValue = ({
  selectProps,
  index,
  ...props
}: OptionProps & CommonProps<OptionTypeBase, true>) => {
  const { value: selectedOptions, passRef } = selectProps;
  const {
    size: { width: controlElementWidth },
  } = useElementSize({ current: passRef.current.select.controlRef });
  const styles = props.getStyles('multiValue', props);
  const labelStyles = props.getStyles('multiValueLabel', props);
  const valueCount = size(selectedOptions);
  // deducted 60 from control width for dropdown icon and counter badge
  const availableWidth = controlElementWidth - 60;
  let optionsWidth = 0;

  if (index === 0) {
    const optionsRender = [];
    if (valueCount === 1) {
      const charCount = availableWidth / 6;
      let displayString = props.data.label;
      if (charCount < props.data.label.length) {
        displayString = `${(props.data.label as string).substring(0, charCount - 3)}...`;
      }
      optionsRender.push(
        <Box as="span" style={styles}>
          <Box as="span" style={labelStyles}>
            {displayString}
          </Box>
        </Box>,
      );
    } else {
      let optionCount = 0;
      selectedOptions?.every((opt: { label: string; value: string }) => {
        // assuming 8 pixel is width of character.
        optionsWidth += opt.label.length * 8 + 2;
        if (optionsWidth > availableWidth) {
          return false;
        }
        optionCount += 1;
        optionsRender.push(
          <Box as="span" style={styles}>
            <Box as="span" style={labelStyles}>
              {opt.label}
            </Box>
          </Box>,
        );
        return true;
      });
      // if option width is more than avalable width than truncate text and show ellipses.
      if (optionCount === 0 && valueCount > 1) {
        const charCount = availableWidth / 8;
        const displayString = `${(props.data.label as string).substring(0, charCount - 3)}...`;
        optionsRender.push(
          <Box as="span" style={styles}>
            <Box as="span" style={labelStyles}>
              {displayString}
            </Box>
          </Box>,
        );
        optionCount = 1;
      }
      if (optionCount < valueCount) {
        optionsRender.push(
          <Box as="span" ml="3px" style={styles}>
            <Box
              as="span"
              style={labelStyles}
            >{` + ${valueCount - optionCount}`}</Box>
          </Box>,
        );
      }
    }
    return optionsRender;
  } else {
    return '';
  }
};

export default MultiValue;
