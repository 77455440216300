import React, { useEffect, useState, Suspense } from 'react';
import { Provider } from 'react-redux';
import loadable from '@loadable/component';

import { IAppConfigState } from '@ess/context/AppConfigContext/AppConfigContext';
import { ISearchBase } from '@ess/types';

import { SEARCH_API, TOKEN_ENDPOINT } from '@ess/constants/api';

import appInit from '@tourop/app/appInit';
import createStore from '@ess/store/core';
import getPreloadedState from '@tourop/app/preloadedState';

import { V5ContextProvider } from '@ess/v5-data-provider/components';

import { getUrlSearchParams, getAllFieldsList } from '@ess/utils';

import { MWSInitialValueSetter } from '@ess/v5-data-provider/mwsfunc/mwsfunc';

import AppConfigProvider from '@ess/context/AppConfigContext';
import SearchBaseProvider from '@ess/context/SearchBaseContext';

import { AgentSettingsProvider } from '@ess/components/AgentSettings';

const BasketProvider = loadable(() => import(
  /* webpackChunkName: "basket-provider" */
  '@basket/BasketProvider'
));

type AuthenticatedContainerProps = {
  children: React.ReactNode;
}

interface IAppLoader {
  isReady: boolean,
  isLoading: boolean,
  isError: boolean,
  error: any,
  unstableNetwork: string[],
  data: {
    config: IAppConfigState | null,
    searchBase: ISearchBase | null,
    store: any,
  },
}

const initialState = {
  isReady: false,
  isLoading: true,
  unstableNetwork: [],
  isError: false,
  error: null,
  data: {
    config: null,
    searchBase: null,
    store: {},
  },
};

const sections = {
  Description: true,
  Pictures: true,
  Omnibus: true,
  PriceChart: true,
  MultiRoomFinder: true,
  AlternativeOffers: false,
  AlternativeDepartures: true,
  AlternativeHotels: true,
  OtherOperatorsHotels: true,
  TripAdvisor: true,
  RegionalInfo: true,
  VisaInfo: true,
  AgentAttributes: true,
  AirportsNearby: true,
  BeachesNearby: true,
  MapInfo: true,
};

const initBookmark = () => {
  const bookmark = getUrlSearchParams({ params: ['_t'] });

  if (bookmark?._t) {
    sessionStorage.setItem('MXBookmark', JSON.stringify({
      name: bookmark._t,
      referrer: 'AppInit',
    }));
  }
};
const AuthenticatedContainer = ({ children }: AuthenticatedContainerProps) => {
  const [state, setState] = useState<IAppLoader>(initialState);

  useEffect(() => {
    setState((state) => ({
      ...state,
      isLoading: true,
    }));

    appInit().then((response: any) => {
      const { config, searchBase } = response ?? {};
      const store = createStore(
        getPreloadedState(response), process.env.NODE_ENV === 'development',
      );

      initBookmark();

      window.dictionary = config?.dictionary || {};

      setState((state) => ({
        ...state,
        isReady: true,
        isLoading: false,
        unstableNetwork: [...state.unstableNetwork].filter((item) => item !== 'configFetch'),
        data: {
          config,
          store,
          searchBase,
        },
      }));
    }).catch((error) => {
      setState((state) => ({
        ...state,
        error,
        isError: true,
      }));
    });
  }, []);

  return (
    <>
      {state.isReady && state.data.config && state.data.searchBase && (
        <BasketProvider>
          <AppConfigProvider initialState={state.data.config}>
            <SearchBaseProvider initialState={state.data.searchBase}>
              <Suspense fallback={null}>
                <Provider store={state.data.store}>
                  <AgentSettingsProvider sections={sections}>
                    <V5ContextProvider
                      APIServer={SEARCH_API}
                      fieldList={getAllFieldsList()}
                      valueSetter={MWSInitialValueSetter(state.data.store)}
                      getTokenUrl={TOKEN_ENDPOINT}
                    >
                      {children}
                    </V5ContextProvider>
                  </AgentSettingsProvider>
                </Provider>
              </Suspense>
            </SearchBaseProvider>
          </AppConfigProvider>
        </BasketProvider>
      )}
    </>
  );
};

export {
  AuthenticatedContainer,
};
