import React from 'react';

import { useReactBasket } from '@basket/hooks/useReactBasket';

import { BasketList } from '@basket/components/BasketList';
import { BasketCount } from '@basket/components/BasketCount';

import { BasketView } from '@basket/types';
import { DraggableContainer } from '../containers/DraggableContainer';
import { BasketContainer } from '../containers/BasketContainer';

import BasketCore from './BasketCore';

export * from '../types';

export * from '../constants';

export * from '../BasketProvider';

type BasketProps = {
  isPortal?: boolean
}

const Basket = ({ isPortal = true }:BasketProps) => {
  const { basket } = useReactBasket();

  return (
    <DraggableContainer>
      <BasketContainer isPortal={isPortal as boolean}>
        {basket.view === BasketView.BasketList ? <BasketList/> : <BasketCore/>}
      </BasketContainer>
    </DraggableContainer>
  );
};

export default Basket;

export {
  BasketCount,
};
