import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import moment from 'moment';

import { IDictionary } from '@ess/types';

import { DATE_DISPLAY_FORMAT, DATE_REQUEST_FORMAT } from '@ess/constants/api';

import { BasketActionLevel, BasketActions } from '@basket/types';

import { useReactBasket } from '@basket/hooks/useReactBasket';
import usePopperPositioning from '@ess/hooks/usePopperPositioning';
import useOnClickOutside from '@ess/hooks/useOnClickOutside';

import { Button, IconButton } from '@ess/ui/Button';
import Modal from '@ess/ui/Modal/ModalsTypes/Modal';
import FlexBox from '@ess/ui/FlexBox';
import Field from '@ess/ui/Form/Field';
import Box from '@ess/ui/Box';
import Text from '@ess/ui/Text';

import MultiSelectV2 from '@tourop/components/MultiSelectV2';

const initialFilters = {
  dateFrom: '',
  dateTo: '',
  consultants: [],
};

type FiltersProps = {
  onChange: (params: any) => void
}
const nonEmptyFilters = (filters: IDictionary<any>) => Object.keys(filters).filter((item) => !isEmpty(filters[item])).length;

const Filters = ({ onChange }: FiltersProps) => {
  const { basketClient } = useReactBasket();
  const targetElement = useRef<HTMLButtonElement>(null);
  const [consultants, setConsultants] = useState([]);
  const [filters, setFilters] = useState<IDictionary<any>>(initialFilters);
  const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const { styles, attributes } = usePopperPositioning({
    targetElementRef: targetElement,
    popperElementRef: modalElement,
    zIndex: 99999,
    applyMaxSizeEnabled: true,
  });

  const filtersCount = nonEmptyFilters(filters);
  const today = moment();

  const presets = useMemo(() => [
    {
      translationKey: 'lbl_yesterday',
      start: moment().subtract(1, 'day'),
      end: moment().subtract(1, 'day'),
    },
    {
      translationKey: 'lbl_last_three_days',
      start: moment().subtract(2, 'day'),
      end: today,
    },
    {
      translationKey: 'lbl_last_week',
      start: moment().subtract(1, 'week'),
      end: today,
    },
  ], []);

  const fetchConsultants = () => {
    (async () => {
      try {
        const response = await basketClient({
          method: 'get',
          action: BasketActions.ConsultantsList,
          level: BasketActionLevel.Basket,
        });

        if (response?.consultants?.length) {
          setConsultants(() => response.consultants.map((item: any) => ({
            label: item.code,
            value: item.code,
          })));
        }
      } catch (error) {
        console.error(error);
      }
    })();
  };

  const onConsultantsChange = (value: string[]) => {
    setFilters((state) => ({
      ...state,
      consultants: value,
    }));
  };

  const onCalendarChangeHandler = (date: any) => {
    setFilters((state) => ({
      ...state,
      dateFrom: date.startDate ? date.startDate.format(DATE_REQUEST_FORMAT) : '',
      dateTo: date.endDate ? date.endDate.format(DATE_REQUEST_FORMAT) : '',
    }));
  };

  const closeFilters = () => {
    setIsModalOpen(false);
  };

  const openFilters = () => {
    setIsModalOpen(true);
  };

  const onApply = useCallback(() => {
    onChange(filters);
    closeFilters();
  }, [filters]);

  const onClear = () => {
    onChange(initialFilters);
    setFilters(initialFilters);

    closeFilters();
  };

  useOnClickOutside(modalElement, closeFilters);

  return (
    <>
      <Modal
        ref={setModalElement as any}
        showOverlay={false}
        theme="white"
        title={t('lbl_basket_list_filters')}
        isOpen={isModalOpen}
        showCloseIcon={false}
        controls={(
          <FlexBox p="small" width="100%" alignItems="center" justifyContent="flex-end">
            <Button
              variant="secondary"
              label={t('clear')}
              size="small"
              mr="small"
              width="90px"
              onClick={onClear}
            />
            <Button
              variant="primary"
              label={t('lbl_apply')}
              size="small"
              width="90px"
              onClick={onApply}
            />
          </FlexBox>
        )}
        onClose={closeFilters}
        positionedByPopper
        style={{ ...styles.popper, overflow: 'visible' }}
        {...attributes.popper}
      >
        <Box p="small">
          <FlexBox mb="small">
            <Field.DateRangePicker
              label={`${t('lbl_modification_date')}:`}
              startDatePlaceholder={t('lbl_from')}
              endDatePlaceholder={t('lbl_to')}
              startDateValue={filters.dateFrom ? moment(filters.dateFrom, DATE_REQUEST_FORMAT) : null}
              endDateValue={filters.dateTo ? moment(filters.dateTo, DATE_REQUEST_FORMAT) : null}
              startDateInputValue={filters.dateFrom ? moment(filters.dateFrom, DATE_REQUEST_FORMAT).format(DATE_DISPLAY_FORMAT) : ''}
              endDateInputValue={filters.dateTo ? moment(filters.dateTo, DATE_REQUEST_FORMAT).format(DATE_DISPLAY_FORMAT) : ''}
              minDate={null}
              maxDate={moment()}
              isClearable
              presets={presets}
              appendTo={modalElement}
              onCalendarChange={onCalendarChangeHandler}
            />
          </FlexBox>

          <FlexBox flexDirection="column">
            <Text mb="2px">{`${t('lbl_consultant')}:`}</Text>
            <MultiSelectV2
              name="consultantFilter"
              value={filters?.consultants}
              onChange={onConsultantsChange}
              options={consultants}
              placeholder={t('lbl_irrelevant_value')}
              appendTo={modalElement}
              onOpen={fetchConsultants}
            />
          </FlexBox>
        </Box>
      </Modal>
      <FlexBox position="relative">
        {filtersCount > 0 && (
          <FlexBox
            top="-6px"
            right="-8px"
            position="absolute"
            alignItems="center"
            justifyContent="center"
            backgroundColor="#52a1d5"
            width="20px"
            height="20px"
            borderRadius="6px"
            style={{
              border: '1px solid #207fbe87',
            }}
          >
            <Text
              color="white"
              fontWeight="bold"
              fontSize="11px"
            >
              {filtersCount}
            </Text>
          </FlexBox>
        )}
        <IconButton
          ref={targetElement}
          onClick={openFilters}
          icon={<FontAwesomeIcon icon={faFilter}/>}
          variant="primary"
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        />
      </FlexBox>
    </>
  );
};

export {
  Filters,
};
