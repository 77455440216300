import { get, set } from 'lodash-es';

import { formValuesToSearchRequestSchema } from '@ess/utils';

export const MWSInitialValueSetter = (store: any) => (setter:(data:any, initial:boolean)=>void) => {
  const endDateMode = false;
  const state = store.getState();

  const v5data = formValuesToSearchRequestSchema(state.searchForm.searchValues);

  if (endDateMode) {
    set(v5data, 'Base.ReturnDate.Before', get(v5data, 'Base.StartDate.Before'));
  }

  setter({
    searchParams: v5data,
    filterParams: formValuesToSearchRequestSchema({ ...state.filtersForm.fields }),
    regionsParams: formValuesToSearchRequestSchema({ ...state.filtersForm.regions as any }),
  }, true);
};
