import React from 'react';
import { DragOverlay, defaultDropAnimationSideEffects } from '@dnd-kit/core';
import type { DropAnimation } from '@dnd-kit/core';

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
};

type SortableOverlayProps = {
  children: React.ReactNode
}

const SortableOverlay = ({ children }: SortableOverlayProps) => (
  <DragOverlay dropAnimation={dropAnimationConfig}>
    {children}
  </DragOverlay>
);

export { SortableOverlay };
