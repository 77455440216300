import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { includes } from 'lodash-es';

import { BasketItem } from '@basket/types';

import { useReactBasket } from '@basket/hooks/useReactBasket';

import FlexBox from '@ess/ui/FlexBox';

import ModernOffer from '@tourop/components/OfferList/Offer/ModernOffer';
import CardView from '@tourop/components/OfferList/CardView';

import useStorage from '@ess/hooks/useStorage';
import { SortableContainer } from '../containers/SortableContainer';

import Header from './Header';
import Controls from './Controls';
import { BasketMessage } from './BasketMessage';

type BasketCoreProps = {
  offerOpened?: (count: number) => void,
}

const BasketCore = ({ offerOpened = undefined } : BasketCoreProps) => {
  const [isPinned, _] = useStorage<boolean>('localStorage', 'PinnedBasket', false);
  const [openOfferList, setOpenOfferList] = useState<number[]>([]);
  const { t } = useTranslation();

  const {
    basket,
    selectItem,
    setItems,
    swapItems,
    setOfferView,
  } = useReactBasket();

  const scrollDirection = ['left', 'right'].includes(basket.position) || basket.offerView === 'list'
    ? 'vertical'
    : 'horizontal';

  /**
   * Sort change handler.
   * @param items
   * @param activeId
   * @param overId
   */
  const onSortChangeHandler = async (items: BasketItem[], original: BasketItem[], { activeId, overId }: { activeId: number, overId: number }) => {
    const activeItem = items.find((item) => item.id === activeId);
    const overItem = items.find((item) => item.id === overId);

    setItems(items);

    if (activeItem && overItem) {
      const swapResponse: any = await swapItems(activeItem.rowid, overItem?.rowid);

      if (swapResponse?.status === 'ERROR') {
        setItems(original);
      }
    }
  };

  const onOfferClick = useCallback((offerId: string, rowIndex: number) => {
    setOpenOfferList((state: number[]) => {
      if (includes(state, rowIndex)) {
        return state.filter((index: number) => index !== rowIndex);
      }
      return [...state, rowIndex];
    },
    );
  }, []);

  useEffect(() => {
    if (offerOpened) {
      offerOpened(openOfferList?.length);
    }
  }, [openOfferList.length, basket.items.data.length, basket.position]);

  return (
    <FlexBox
      flexGrow={1}
      height="100%"
      flexDirection="column"
    >
      <Header/>
      {basket?.current?.id === 0 ? (
        <BasketMessage
          title={t('lbl_basket_deleted_title')}
          message={t('lbl_basket_deleted_message')}
        />
      ) : !basket?.current?.id ? (
        <BasketMessage
          title={t('lbl_no_selected_basket')}
          message={t('lbl_please_select_basket')}
        />
      ) : (
        <FlexBox
          height="calc(100% - 50px)"
          flexDirection="column"
          backgroundColor="#e8ecee"
        >
          {basket?.items?.isReady && basket?.items?.data.length ? (
            <>
              <Controls onViewChange={setOfferView} offerView={basket.offerView}/>
              <SortableContainer
                items={basket.items.data}
                onChange={onSortChangeHandler}
                disabled={basket.isBlocked}
                direction={scrollDirection}
                renderItem={(item) => {
                  const isExpanded = includes(openOfferList, item.rowid);
                  return (
                    <>
                      {basket.offerView === 'grid' ? (
                        <CardView
                          searchForm={{
                            type: item?.search?.form ?? '',
                            hash: item?.search?.hash ?? '',
                          }}
                          isExpanded={isExpanded}
                          onClick={onOfferClick}
                          basketItem={item}
                          isSelected={basket.selectedItems.includes(item.rowid)}
                          onSelect={selectItem}
                          basketPosition={basket.position}
                          rowIndex={item.rowid}
                        />
                      ) : (
                        <ModernOffer
                          isExpanded={isExpanded}
                          onClick={onOfferClick}
                          basketItem={item}
                          isSelected={basket.selectedItems.includes(item.rowid)}
                          onSelect={selectItem}
                          basketPosition={basket.position}
                          rowIndex={item.rowid}
                          isBasketPinned={isPinned}
                        />
                      )}
                    </>
                  );
                }}
              />
            </>
          ) : basket?.items?.isLoading ? (
            <></>
          ) : (
            <BasketMessage
              title={t('lbl_empty_basket_title')}
              message={t('lbl_empty_basket_message')}
            />
          )}
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default BasketCore;
