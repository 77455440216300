import React, { MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';

import { Styled } from './ReplacedComponents.styles';

type NavButtonProps = {
  disabled?: boolean;
  onClick?: MouseEventHandler;
}

const NextBtn = ({ disabled = false, onClick = undefined }: NavButtonProps) => (
  <Styled.NextBtn size="medium" icon={<FontAwesomeIcon icon={faChevronRight}/>} disabled={disabled} onClick={onClick}/>
);

const PrevBtn = ({ disabled = false, onClick = undefined }: NavButtonProps) => (
  <Styled.PrevBtn size="medium" icon={<FontAwesomeIcon icon={faChevronLeft}/>} disabled={disabled} onClick={onClick}/>
);

export {
  PrevBtn,
  NextBtn,
};
