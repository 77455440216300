import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { faLock, faStar } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faStarEmpty, faUnlock } from '@fortawesome/pro-regular-svg-icons';
import { includes } from 'lodash-es';
import { useVirtualizer } from '@tanstack/react-virtual';

import { useReactBasket } from '@basket/hooks/useReactBasket';
import useBasketListFilters from '@basket/hooks/useBasketListFilters';

import { ScrollBar, useScrollBar } from '@ess/components/ScrollBar';

import Box from '@ess/ui/Box';
import FlexBox from '@ess/ui/FlexBox';

import { Styled } from './AutoSuggestBasketList.styles';

type SmallSelectValuesProps= {
  basketsList: any[]
  setState: any
  onChange :(selected: any) => void
  onScrollEnd: ()=>void
}

const SmallSelectValues = ({
  basketsList,
  onChange,
  onScrollEnd,
  setState,
}:SmallSelectValuesProps) => {
  const { t } = useTranslation();
  const scrollRef = useRef<any>(null);

  useScrollBar(scrollRef.current, {
    onReachEnd: onScrollEnd,
  });

  const {
    favouriteBasketChangeHandler,
    favourites,
  } = useBasketListFilters();

  const {
    blockBasket,
    unBlockBasket,
  } = useReactBasket();

  const virtualizedList = useVirtualizer({
    count: basketsList.length,
    getScrollElement: () => scrollRef.current,
    estimateSize: () => 35,
  });

  const virtualItems = virtualizedList.getVirtualItems();

  return basketsList.length !== 0 ? (
    <FlexBox
      height="100%"
      flexDirection="column"
    >
      <ScrollBar ref={scrollRef}>
        <Box style={{
          height: `${virtualizedList.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
        >
          {virtualItems.map((virtualItem) => {
            const index = virtualItem.index;
            const basketOption = [...basketsList as any][index];

            const {
              label,
              isBlocked,
              value,
            } = basketOption;

            const onFavoriteClickHandler = (event: any) => {
              event.stopPropagation();
              favouriteBasketChangeHandler(basketOption?.value);
            };

            const onLockClickHandler = (event:any) => {
              event.stopPropagation();
              if (isBlocked) {
                const unLock = unBlockBasket(value) as any;
                unLock.then((item:any) => {
                  if (item.status === 'OK') {
                    setState((state:any) => ({
                      ...state,
                      data: state.data.map((option:any) => (option.value === value ? { ...option, isBlocked: !isBlocked } : option)),
                    }));
                  }
                });
              } else {
                const block = blockBasket(value) as any;
                block.then((item:any) => {
                  if (item.status === 'OK') {
                    setState((state:any) => ({
                      ...state,
                      data: state.data.map((option:any) => (option.value === value ? { ...option, isBlocked: !isBlocked } : option)),
                    }));
                  }
                });
              }
            };

            return (
              <Box
                ref={virtualizedList.measureElement}
                key={virtualItem.key}
                data-index={virtualItem.index}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${virtualItem.start}px)`,
                }}
              >
                <FlexBox>
                  <Styled.Small__Select__Option onClick={() => { onChange(basketOption); }}>
                    <Styled.Favourite__Icon
                      onClick={onFavoriteClickHandler}
                      icon={includes(favourites ?? [], value) ? faStar : faStarEmpty}
                    />
                    <Styled.Lock__Icon
                      onClick={onLockClickHandler}
                      icon={isBlocked ? faLock : faUnlock}
                    />
                    <Styled.Option__Label>
                      {label}
                    </Styled.Option__Label>

                  </Styled.Small__Select__Option>
                </FlexBox>
              </Box>
            );
          })}
        </Box>
      </ScrollBar>
    </FlexBox>
  ) : (
    <Styled.No__Values__Found__Holder>
      <Styled.No__Values__Found__Label>
        {t('no_results')}
      </Styled.No__Values__Found__Label>
    </Styled.No__Values__Found__Holder>
  );
};
export default SmallSelectValues;
