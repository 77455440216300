import FlexBox from '@ess/ui/FlexBox';
import React, { useContext, useEffect, useRef } from 'react';
import Text from '@ess/ui/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical, faUser } from '@fortawesome/pro-solid-svg-icons';
import { OfferAttributesTags } from '@tourop/components/OfferElements/OfferAttributes';
import { OfferAttributesTagsFunc } from '@tourop/components/OfferElements/OfferAttributes/OfferAttributesTags';
import { useTranslation } from 'react-i18next';
import { includes, isEmpty, toNumber } from 'lodash-es';

import {
  BUS_PACKAGE,
  CHECK_TYPE_FUNC,
  FLIGHT_ONLY,
  FLIGHT_PACKAGE,
  HOTEL_ONLY,
  INSURANCE,
  PARKING,
  TRIP_ROUND_TRIP,
} from '@tourop/components/OfferList/Offer/CheckTypeFunc';

import { UNGROUPED_VIEW } from '@ess/constants/search';

import { BasketItem } from '@basket/types';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import { SortableItemContext } from '@basket/containers/SortableContainer';

import {
  encodeOfferId,
  getOfferHashGetter,
  getOfferUrl,
  getParticipantsFromHash,
  getTFG,
  getUrlSearchParams,
  getValidParticipants,
  objectToURLParams,
} from '@ess/utils';

import { useReactBasketItem } from 'apps/basket/src/hooks/useBasketItem';
import useContentWindow, { getMandatoryOfferOnly } from '@ess/hooks/useContentWindow';
import useElementSize from '@ess/hooks/useElementSize';

import { ImageSlider } from '@ess/components/ImageSlider';
import { OfferStatus } from '@tourop/components/OfferElements';
import PriceElement from '@tourop/components/OfferList/Offer/OfferElements/Price';
import TransportIcon from '@tourop/components/OfferList/Offer/OfferElements/Transport';
import DateWithDuration from '@tourop/components/OfferList/Offer/OfferElements/DateWithDuration';
import HotelCategory from '@tourop/components/OfferList/Offer/OfferElements/HotelCategory';
import ServiceElement from '@tourop/components/OfferList/Offer/OfferElements/ServiceElement';
import OfferDetails from '@tourop/components/OfferList/Offer/OfferDetails';
import { OfferIcon } from '@tourop/components/OfferElements/OfferIcon';
import { BasketOfferActions } from '@tourop/components/OfferElements/BasketOfferActions';
import { SupplementaryHotel } from '@tourop/components/OfferElements/SupplementaryHotel';
import { Media } from '@tourop/components/OfferElements/Media';
import { BookingId, BookingStatus } from '@tourop/components/OfferElements/Booking';

import Anchor from '@ess/ui/Anchor';
import Chip from '@ess/ui/Chip';
import Checkbox from '@ess/ui/Form/Checkbox';
import Tooltip from '@ess/ui/Tooltip';
import { Card } from '@ess/ui/Card';
import Box from '@ess/ui/Box';
import { ImagesSizes } from '@ess/hooks/useFetchImages';

type CardViewProps = {
  onClick?: (offerID: string, rowIndex: number) => void
  basketItem: BasketItem
  searchForm: {
    type: string
    hash: string
  },
  isExpanded?: boolean
  rowIndex?: number
  isSelected?: boolean
  basketPosition?: string
  onSelect?: (rowId: number) => void
  hasOperatorLogo?: boolean
}

const CardView = ({
  onClick = undefined,
  rowIndex = 0,
  basketItem,
  isSelected = false,
  onSelect = undefined,
  isExpanded = true,
  searchForm,
  basketPosition = '',
}: CardViewProps) => {
  const { t } = useTranslation();
  const { hash: sfProtoHash, type } = searchForm;
  const { item, checkOnline } = useReactBasketItem({ rowId: basketItem.rowid });
  const offer = basketItem.offer as any;
  const { openContentWindow } = useContentWindow(type);
  const { attributes, listeners, ref } = useContext(SortableItemContext);
  const { state: AppConfig } = useContext(AppConfigContext);
  const { offerAttributesPriority, configOper } = AppConfig;
  const tfg = getTFG(offer.Base?.Price as any);
  const participants = getParticipantsFromHash(offer?.Base?.OfferId?.split('|')[2] as string);
  const validParticipants = getValidParticipants(participants);
  const offerHashGetter = getOfferHashGetter(offer, validParticipants);
  const offerContainer = useRef<HTMLDivElement>(null);
  const inputContainer = useRef<HTMLElement>(null);
  const { size: { width }, forceUpdate } = useElementSize(inputContainer);

  const tripOffers = [
    FLIGHT_PACKAGE,
    FLIGHT_ONLY,
    BUS_PACKAGE,
    TRIP_ROUND_TRIP,
    HOTEL_ONLY,
  ];

  const isMedia = ['video', 'image'].includes(basketItem.offerType);
  const offerType = !isMedia ? CHECK_TYPE_FUNC(offer) : basketItem.offerType;

  const hasAccommodation = offer?.Accommodation;

  const getCustomChip = (offer: any) => {
    const AdditionalServices = offer?.AdditionalServices ?? [];

    if (!isEmpty(AdditionalServices)) {
      const additionalDescription = (
        <Box>
          {AdditionalServices?.map((item:any) => (item?.Assignment?.length ? (
            <Text
              margin="1px 0"
            >
              {item.Name}
            </Text>
          ) : null))}
        </Box>
      );

      return ({
        label: t('lbl_additional_service'), name: 'additional-service', visible: true, tooltip: additionalDescription,
      });
    }
    return null;
  };

  const offerAttributes = OfferAttributesTagsFunc(t, offer, offerAttributesPriority, true, getCustomChip(offer));

  const {
    Base, Accommodation, Insurance, Parking,
  } = offer as any;

  const getOfferProtoHash = () => {
    const { adultsCount, childDates, infantDates } = encodeOfferId(offer?.Offer?.Base?.OfferId) ?? {};
    const validParticipants = getValidParticipants({
      adultsCount,
      childDates,
      infantDates,
    });

    return getOfferHashGetter(offer.Offer, validParticipants)();
  };

  const onCheckboxChangeHandler = (event: any) => {
    event.stopPropagation();
    if (onSelect) {
      onSelect(basketItem.rowid);
    }
  };

  const onStatusClickHandler = () => {
    checkOnline();
  };

  const openContentWindowFunction = (page: string) => {
    openContentWindow({
      offerHash: getOfferProtoHash(),
      offer: getMandatoryOfferOnly(offer),
      page,
    });
  };

  const onNameClick = (event: any) => {
    event.stopPropagation();
    const urlSearchParams = getUrlSearchParams({ params: ['sfr'] });
    const sfrProtoHash = urlSearchParams?.sfr ?? '';

    const params = objectToURLParams({
      so: getOfferProtoHash(),
      sfr: sfrProtoHash,
      sf: sfProtoHash,
    });

    const url = getOfferUrl('details', type, params);

    window.open(url, '_self');
  };

  const operateOpenCloseOffer = () => {
    if (onClick) {
      onClick(offer?.Base?.OfferId ?? '', rowIndex as number);
    }
  };

  const onMiddleImageSectionClick = () => {
    openContentWindowFunction('Pictures');
  };

  const getDateForParking = (startValue: string | undefined, endValue: string | undefined) => {
    const startDate = startValue?.split(' ')[0]?.split('-')?.reverse() as any;
    startDate?.pop();
    const startTime = startValue?.split(' ')[1];
    const endDate = endValue?.split(' ')[0]?.split(' ')[0]?.split('-')?.reverse() as any;
    const endTime = endValue?.split(' ')[1];
    return `${startDate?.join('.')} ${startTime}  - ${endDate.join('.')} ${endTime}`;
  };

  const toolTipContent = () => {
    switch (offerType) {
      case PARKING:
        return (
          <FlexBox
            padding="2px"
            flexDirection="column"
          >
            <Text
              fontSize="11px"
            >
              {t('lbl_cars')}
              {': '}
              {Parking?.CarsCount}
            </Text>
            <Text
              fontSize="11px"
              margin="1px 0"
            >
              {t('lbl_participants')}
              {': '}
              {Parking?.PersonsCount}
            </Text>
          </FlexBox>
        );
      case INSURANCE:
        return (
          <FlexBox
            padding="2px"
            flexDirection="column"
          >
            <Text
              fontSize="11px"
            >
              {t('lbl_participants')}
              {': '}
              {Insurance?.PersonsCount}
            </Text>
          </FlexBox>
        );
      default:
        return (
          <FlexBox
            padding="2px"
            flexDirection="column"
          >
            <Text
              fontSize="11px"
            >
              {t('lbl_adult')}
              {': '}
              {participants?.adultsCount}
            </Text>
            <Text
              fontSize="11px"
              margin="1px 0"
            >
              {t('lbl_child')}
              {': '}
              {participants?.childDates?.length}
            </Text>
          </FlexBox>
        );
    }
  };

  const getOfferValues = () => {
    if (includes(tripOffers, offerType)) {
      return (
        <>
          <FlexBox mb="2px">
            <TransportIcon
              item={offer as any}
              offerHashGetter={undefined}
            />
            <DateWithDuration
              item={offer as any}
              commonProps={undefined}
            />
          </FlexBox>
          {hasAccommodation && (
            <FlexBox mb="2px">
              <Tooltip
                content={toolTipContent()}
              >
                <FlexBox>
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{
                      height: '12px',
                      margin: '1px 2px  0 2px',
                    }}
                  />
                  <Text
                    style={{
                      margin: '0 2px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {`${participants?.adultsCount}${!isEmpty(participants?.childDates) ? `:${participants?.childDates?.length}` : ''} / `}
                  </Text>
                </FlexBox>
              </Tooltip>
              <ServiceElement
                onlineOffer={offer}
                item={offer}
              />
            </FlexBox>
          )}
        </>
      );
    }
    return (<></>);
  };

  const getOfferDetails = () => {
    switch (offerType) {
      default:
        return (
          <FlexBox
            padding="0 15px"
          >
            <OfferDetails
              isBasket
              searchType={searchForm.type}
              offerData={offer}
              offerHashGetter={offerHashGetter}
              isMultiRoomMode={false}
              onContentsChange={() => {}}
              viewType={UNGROUPED_VIEW}
              swapBookingEnabled={false}
            />
          </FlexBox>
        );
    }
  };

  const getOfferContent = () => {
    let anchorText: string = Base?.XCode?.Name ?? '';
    let remainingText = ` •  ${Base?.XCountry?.Name ?? ''}${
      (Base?.XRegion?.Name ?? '') && ' / '}${Base?.XRegion?.Name ?? ''}`;
    const offerText = `${anchorText}${remainingText}`;
    const textLength = offerText?.length ?? 0;
    const isCategoryExists = (Accommodation?.Category && toNumber(Accommodation?.Category) !== 0);
    const availableWidth = isCategoryExists ? width - 28 : width;
    const totalCharactersCanFit = availableWidth / 7.5;
    let showToolTip = false;
    if (availableWidth > 0 && anchorText.length > 0) {
      showToolTip = (textLength * 7.5) > availableWidth;
      if (anchorText.length > totalCharactersCanFit) {
        anchorText = `${anchorText.substring(0, totalCharactersCanFit - (isCategoryExists ? 2 : 0))}`;
        remainingText = '...';
      }
    }
    switch (offerType) {
      default:
        return (
          <>
            <FlexBox width="100%" alignItems="flex-start" flexGrow={1}>
              <FlexBox flexDirection="column" flexGrow={1} minWidth={0} style={{ cursor: 'pointer' }}>
                <FlexBox ref={inputContainer as any} alignItems="flex-start" mb="5px">
                  <Tooltip disabled={!showToolTip} content={offerText}>
                    <Text
                      as="span"
                      mr="10px"
                      whiteSpace="noWrap"
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      <Anchor fontWeight="bold" color="navy" onClick={onNameClick}>
                        {anchorText}
                      </Anchor>
                      {remainingText}
                    </Text>
                  </Tooltip>
                  {(Accommodation?.Category && toNumber(Accommodation?.Category) !== 0) ? (
                    <HotelCategory Accommodation={Accommodation}/>
                  ) : null}
                </FlexBox>

                {getOfferValues()}
                <FlexBox alignItems="center" mb="5px">
                  <Text fontWeight="bold" mr="small">
                    {Base?.OperatorDesc ? Base.OperatorDesc : Base?.Operator ? configOper[Base.Operator]?.name : null}
                  </Text>
                  <FlexBox mr="small">
                    <OfferAttributesTags maxVisibleAttributes={1} offerAttributes={offerAttributes}/>
                  </FlexBox>
                  {basketItem?.nameCustom && (
                    <Text as="span" fontWeight="bold">
                      {basketItem.nameCustom}
                    </Text>
                  )}
                </FlexBox>
              </FlexBox>
              <FlexBox
                flexShrink={0}
                flexDirection="column"
                alignItems="center"
              >

                <BasketOfferActions
                  basketItem={basketItem}
                  allowedActions={basketItem?.booking ? [
                    'deleteOffer',
                    'goToSearchForm',
                    'editItemName',
                    'socialPostCreator',
                    'cloneOffer',
                    'deleteOffer',
                  ] : []}
                />
                <FlexBox
                  mt="10px"
                  flexShrink={0}
                  alignItems="center"
                  flexDirection="column"
                >
                  {basketItem?.booking ? (
                    <BookingStatus status={basketItem.booking.status}/>
                  ) : Base?.Availability ? (
                    <>
                      <OfferStatus
                        status={Base.Availability}
                        isLoading={!!item?.online?.isLoading}
                        onClick={onStatusClickHandler}
                        startDateCheck={Base.StartDate}
                      />
                      {(tfg && (
                        <Tooltip content={`${t('tfg_included')}`}>
                          <Chip variant="primary" label={t('lbl_tfg')}/>
                        </Tooltip>
                      ))}
                    </>
                  ) : null}
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </>
        );
    }
  };

  useEffect(() => {
    if ((basketPosition?.length ?? 0) > 0) {
      forceUpdate();
    }
  }, [basketPosition]);

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        offerContainer?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 200);
    }
  }, [isExpanded]);

  return (
    <Card ref={offerContainer} minHeight={350}>
      <FlexBox flexDirection="column" flexGrow={1}>
        <FlexBox alignItems="center" p="small">
          <Checkbox
            spacing="0px"
            checked={!!isSelected}
            onChange={onCheckboxChangeHandler}
          />

          <Tooltip content={t('lbl_offer_swap_disabled')} disabled={!attributes['aria-disabled']}>
            <FlexBox
              ref={ref}
              ml="small"
              style={{ cursor: 'grab', fontSize: '18px', color: 'darkGray' }}
              {...attributes}
              {...listeners}
            >
              <FontAwesomeIcon icon={faGripDotsVertical}/>
            </FlexBox>
          </Tooltip>
        </FlexBox>

        <FlexBox position="relative">
          {Accommodation?.Supplementary?.length && (
            <SupplementaryHotel data={Accommodation?.Supplementary[0]}/>
          )}
          {['parking', 'insurance', 'video'].includes(offerType as string) ? (
            <OfferIcon offerType={offerType as string} height="180px"/>
          ) : offerType === 'image' ? (
            <FlexBox width="100%" height={180}>
              <img
                src={basketItem.mediaLinkUrl}
                alt={basketItem.nameCustom}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </FlexBox>
          ) : (
            <ImageSlider
              image={Base?.ThumbUrl}
              operator={Base.Operator}
              hotelCode={offer?.Accommodation?.Code}
              onClick={onMiddleImageSectionClick}
              imageSize={ImagesSizes.Medium}
              height={180}
            />
          )}

          <FlexBox position="absolute" top="0px" right="0px">
            {(Accommodation?.ExtTripAdvisor && toNumber(Accommodation?.ExtTripAdvisor?.rating) !== 0) ? (
              <Tooltip content={t('tripadvisor_note')}>
                <FlexBox
                  width="45px"
                  height="25px"
                  bg="#12a180"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text fontSize="12px" fontWeight="bold" color="white" whiteSpace="nowrap">
                    {`${Accommodation.ExtTripAdvisor.rating.toString().includes('.')
                      ? Accommodation.ExtTripAdvisor.rating
                      : `${Accommodation.ExtTripAdvisor.rating}.0`} / 5`}
                  </Text>
                </FlexBox>
              </Tooltip>
            ) : null}
          </FlexBox>
        </FlexBox>

        <FlexBox
          height="100%"
          position="relative"
          flexDirection="column"
          p="7px"
          onClick={operateOpenCloseOffer}
          flexGrow={1}
        >
          {!isMedia ? (
            <>
              {getOfferContent()}
              <FlexBox height={20} alignItems="center">
                {basketItem?.booking && (
                  <FlexBox>
                    <BookingId
                      id={basketItem.booking.id}
                      operator={basketItem.offer.Base?.Operator as string}
                    />
                  </FlexBox>
                )}

                {offerType !== INSURANCE ? (
                  <>
                    <PriceElement
                      rooms={undefined}
                      item={offer}
                      onlineOffer={offer}
                      status={item?.online}
                      isMultiRoomMode={false}
                      commonProps={undefined}
                    />
                  </>
                ) : (
                  <PriceElement
                    rooms={undefined}
                    item={offer}
                    isMultiRoomMode={false}
                    commonProps={undefined}
                  />
                )}
              </FlexBox>
            </>
          ) : (
            <FlexBox width="100%" alignItems="flex-start" justifyContent="space-between">
              <FlexBox mr="small">
                <Media data={basketItem}/>
              </FlexBox>
              <FlexBox width={45} justifyContent="center">
                <BasketOfferActions basketItem={basketItem} allowedActions={['deleteOffer', 'editItemName']}/>
              </FlexBox>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
      {!isMedia && isExpanded && (
        <>
          {getOfferDetails()}
        </>
      )}
    </Card>
  );
};

export default CardView;
