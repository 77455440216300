import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import React, { useContext, useEffect, useState } from 'react';
import { IRoute } from '@ess/types';
import { AppConfigContext } from '@ess/context/AppConfigContext';
import {
  find, findIndex, includes, take,
} from 'lodash-es';

import { Styled } from './BreadCrumbs.style';

type BreadCrumbsProps = {
  routes: IRoute[]
}

const BreadCrumbs = ({ routes }: BreadCrumbsProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [sfProtoHash] = useQueryParam('sf', StringParam);
  const [breadCrumbs, setBreadCrumbs] = useState<IRoute[]>([]);
  const { state: SFContext } = useContext(AppConfigContext);
  const { searchFormList = [], type = '' } = SFContext;
  const currentSearchType = find(searchFormList, (item) => item.value === type);
  const searchFormHash = encodeURIComponent(sfProtoHash as string);
  const { name: title = '' } = type && currentSearchType ? currentSearchType : {};

  useEffect(() => {
    let breadCrumbs = [];
    const searchType = location.pathname.split('/').filter((item) => item)[0];

    // @ts-ignore
    const lastRouteIndex = findIndex(routes, (item) => {
      const routePath = item.path.replace(':searchType', searchType);

      return matchPath(routePath, location.pathname);
    });

    const hasSearchType = lastRouteIndex && routes[lastRouteIndex].path.includes(':searchType');

    if (!hasSearchType) {
      breadCrumbs = [routes[lastRouteIndex]];
    } else {
      breadCrumbs = lastRouteIndex === 0 ? [routes[0]]
        : lastRouteIndex > 0
          ? take(routes, lastRouteIndex + 1)
          : routes;
    }

    setBreadCrumbs(breadCrumbs);
  }, [location.pathname]);

  return (
    <Styled.BreadCrumbs>
      <Styled.BreadCrumbs__Container>
        <Styled.BreadCrumbs__Item key="home">
          <Styled.BreadCrumbs__Link
            data-react-link
            to={`/${type}/search/?sf=${searchFormHash}`}
          >
            {t('breadcrumb_home_page')}
          </Styled.BreadCrumbs__Link>
        </Styled.BreadCrumbs__Item>

        {breadCrumbs.map((route: any, index: number) => {
          const { path, breadCrumb } = route;
          const breadCrumbTitle = includes(breadCrumb, ':searchType') ? title : t(breadCrumb);
          const search = index !== breadCrumb.length - 1 ? `?sf=${searchFormHash}` : undefined;

          return (
            <Styled.BreadCrumbs__Item key={breadCrumb}>
              {breadCrumbs.length - 1 === index ? (
                <Styled.BreadCrumbs__Text>
                  {breadCrumbTitle}
                </Styled.BreadCrumbs__Text>
              ) : (
                <Styled.BreadCrumbs__Link
                  data-react-link
                  to={`${path.replace(':searchType', type)}${search ?? ''}`}
                >
                  {breadCrumbTitle}
                </Styled.BreadCrumbs__Link>
              )}
            </Styled.BreadCrumbs__Item>
          );
        })}
      </Styled.BreadCrumbs__Container>
    </Styled.BreadCrumbs>
  );
};

export {
  BreadCrumbs,
};
