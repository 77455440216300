import { IDictionary } from '@ess/types';

export const ALLOWED_VIDEO_SERVICES = ['youtube', 'vimeo'];
export const ALLOWED_IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.png'];
export const ALLOWED_VIDEO_EXTENSIONS = ['.mp4'];

export const UPLOAD_TIMEOUT = 120 * 1000;

export const UPLOAD_ERROR: IDictionary<string> = {
  ErrorGeneral: 'lbl_file_upload_fail',
  ErrorFileTypeInvalid: 'lbl_error_invalid_file_type',
  ErrorFileTooLarge: 'lbl_error_file_too_large',
  ErrorFileFTPUpload: 'lbl_error_file_ftp_upload',
  ErrorFileAlreadyExists: 'lbl_error_file_already_exists',
};
