import { IParticipantList } from '@ess/types';

import { ADULT_CODE, CHILD_CODE, SENIOR_CODE } from '@ess/constants/participants';
import { map } from 'lodash-es';

interface IRoom {
  [key: string]: {
    value: number
    dates?: string[]
  }
}

interface IMultiRoom {
  enabled: boolean
  mode: string
  roomsCount: number
}

interface IRooms {
  rooms: IRoom[]
  multiRoom: IMultiRoom
}

interface IParticipantsV5Schema {
  code: string
  birthdate?: string
  roomIndex: number
}

/**
 * If MultiRoom mode enabled, we send 2:0 pax as default.
 */
const MULTI_ROOM_PARTICIPANTS = [
  { code: ADULT_CODE, roomIndex: -1 },
  { code: ADULT_CODE, roomIndex: -1 },
];

const combineParticipants = (rooms: any = []) => {
  const combinedParticipants = { adults: 0, child: 0 };
  rooms.map((room: any) => {
    if (room.ADULT.value !== 0) {
      combinedParticipants.adults += room.ADULT.value;
    }
    if (room.CHILD.value !== 0) {
      combinedParticipants.child += room.CHILD.value;
    }
  });
  return combinedParticipants;
};

const getMostComplexRoom = (rooms: any = []) => {
  const mostComplexRoom = { totalCount: 0, room: {} };

  rooms.map((room: any) => {
    const roomTotalNumber = room.ADULT.value + (room.CHILD.value * 1.5);
    if (roomTotalNumber > mostComplexRoom.totalCount) {
      mostComplexRoom.room = room;
      mostComplexRoom.totalCount = roomTotalNumber;
    }
  });

  return mostComplexRoom.room;
};

/**
 * Return Base.ParticipantsList in V5 schema.
 */
export const getParticipantsSearchSchema = (value: IRooms | undefined, isSeniorMode = false): IParticipantList => {
  const { rooms = [], multiRoom = undefined } = value || {};
  const adultParticipantCode = isSeniorMode ? SENIOR_CODE : ADULT_CODE;
  let participantsList: IParticipantsV5Schema[] = [];

  if (multiRoom?.enabled) {
    const participants: IParticipantsV5Schema[] = [];
    const combinedParticipants = combineParticipants(rooms);

    if (multiRoom.mode === 'manual') {
      const mostComplexRoom: any = getMostComplexRoom(rooms);
      for (let i = 0; i < mostComplexRoom.ADULT.value; i++) {
        participants.push({ code: ADULT_CODE, roomIndex: -1 });
      }
      for (let i = 0; i < mostComplexRoom.CHILD.value; i++) {
        participants.push({ code: CHILD_CODE, roomIndex: -1, birthdate: mostComplexRoom.CHILD.dates[i] });
      }
      return participants;
    }

    if (combinedParticipants.child !== 0) {
      return [
        { code: ADULT_CODE, roomIndex: -1 },
        { code: CHILD_CODE, roomIndex: -1 },
      ];
    }

    return MULTI_ROOM_PARTICIPANTS;
  }

  for (let i = 0; i < rooms.length; i++) {
    const room = rooms[i];
    const children = [...Array((room[CHILD_CODE]?.value ?? 0) > 0 ? room[CHILD_CODE].value : 0)].map((_, index) => {
      const birthdate = room[CHILD_CODE].dates?.length
        ? room[CHILD_CODE].dates?.[index]
        : '';

      return {
        code: CHILD_CODE,
        roomIndex: i,
        birthdate,
      };
    });

    participantsList = [
      ...participantsList,
      ...[...Array(room[ADULT_CODE].value)].map(() => ({
        code: adultParticipantCode,
        roomIndex: i,
      })),
      ...children,
    ];
  }

  return participantsList;
};
