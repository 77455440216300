import { useState } from 'react';
import {
  BasketActionLevel,
  BasketActions,
  FilterConditions,
  FilterConditionsRequest,
  FiltersType,
  OrderTypes,
} from '@basket/types';
import moment from 'moment';
import { includes, isEmpty } from 'lodash-es';

import { showToast, TOAST_ERROR } from '@ess/utils';

import { useReactBasket } from '@basket/hooks/useReactBasket';
import useFavourites from '@ess/hooks/useFavourites';

type fieldTypes = 'From' | 'To'

const useBasketListFilters = () => {
  const [filterState, setFilterState] = useState<FiltersType>({});

  const [basketListState, setBasketListState] = useState<any>({});

  const { favourites, add, remove } = useFavourites<number>('Baskets');

  const {
    basket,
    basketClient,
  } = useReactBasket();

  const onNameChangeHandler = (value: string) => {
    setFilterState((state:any) => ({
      ...state,
      filters: {
        ...state?.filters,
        name: value,
      },
    }));
  };

  const onDateChangeHandler = (date: moment.Moment, type:fieldTypes) => {
    const typesParser = {
      To: 'dateTo',
      From: 'dateFrom',
    };
    const dateValue = date.format('YYYY-MM-DD');
    setFilterState((state:any) => ({
      ...state,
      filters: {
        ...state.filters,
        [typesParser[type]]: dateValue,
      },
    }));
  };

  const onConsulterChangeHandler = (consulter: string) => {
    // TODO Consulter
  };

  const onOrderByChangeHandler = (orderByType: OrderTypes) => {
    setFilterState((state:any) => ({
      ...state,
      orderBy: orderByType,
    }));
  };

  const onClearAllFilters = () => {
    setFilterState({});
  };

  const filterBasketList = async (
    filters: FilterConditions,
    orderBy: OrderTypes,
    type: FilterConditionsRequest = 'New',
  ) => {
    const defaultConditions = {
      count: 20,
    };

    const getConditions = () => {
      const values : any = defaultConditions;
      if (!isEmpty(favourites)) {
        values.favoriteBasketIdList = favourites;
      }
      if (!isEmpty(filters)) {
        values.filter = {
          mod: 'ON',
          ...filters,
        };
      }
      if (!isEmpty(orderBy)) {
        values.orderby = orderBy;
      }
      switch (type) {
        case 'More':
          values.offset = basketListState?.data?.length ?? 0;
          break;
        default:
          break;
      }
      return values;
    };

    const conditions = getConditions();

    try {
      setBasketListState((state: any) => ({
        ...state,
        isLoading: true,
        sent: true,
      }));

      if (!basketListState?.sent) {
        const request = await basketClient({
          method: 'get',
          action: BasketActions.List,
          level: BasketActionLevel.Basket,
          conditions,
        });

        const newValues = [...request?.list?.basket ?? []].map((item: any) => ({
          label: item.name,
          value: item.id,
          itemsCount: item.count,
          isBlocked: !!item?.blocked,
        }));

        switch (type) {
          case 'New':
            setBasketListState((state: any) => ({
              ...state,
              data: newValues,
            }));
            break;
          case 'More':
            setBasketListState((state: any) => ({
              ...state,
              data: [...state?.data ?? [], ...newValues],
            }));
            break;

          default:
            break;
        }
      }
    } catch (error) {
      showToast(TOAST_ERROR, 'lbl_filter_basket_list_error');
    } finally {
      setBasketListState((state:any) => ({
        ...state,
        isLoading: false,
        sent: false,
      }));
    }
  };

  const favouriteBasketChangeHandler = (basketId: number) => {
    if (includes(favourites, basketId)) {
      remove(basketId);
    } else {
      add(basketId);
    }
  };
  return {
    favourites,
    basketListFilterValue: filterState,
    filterBasketList,
    allvalue: basketListState,
    basketsList: basketListState?.data ?? [],
    favouriteBasketChangeHandler,
    onDateChangeHandler,
    onConsulterChangeHandler,
    onNameChangeHandler,
    onOrderByChangeHandler,
    onClearAllFilters,
    // TODO Delete this section for test/dev only
    setBasketListState,
  };
};

export default useBasketListFilters;
