import TagManager from 'react-gtm-module';
import { has } from 'lodash-es';

const GoogleTagManagerId = 'GTM-58PHNN6G';

interface IEventData {
  event: string
  eventCategory: string
  eventAction: string
  eventLabel?: string
  eventValue?: number
}

/* Min event data set. */
const REQUIRED_EVENT_PROPERTIES = ['event', 'eventCategory', 'eventAction'];

/* Initialize google analytics */
export const initializeTagManager = (): void => {
  TagManager.initialize({ gtmId: GoogleTagManagerId });
};

/**
 * useGoogleTagManager hook.
 * Return google analytics functions.
 *
 * @returns breakPoint
 */
const useGoogleTagManager = () => {
  const trackEvent = (eventData: IEventData) => {
    const hasEventProperFields = REQUIRED_EVENT_PROPERTIES.every((el) => has(eventData, el));
    const data: IEventData = { ...eventData };

    if (!hasEventProperFields) {
      console.error(`GAEvent: Missing required event properties (${REQUIRED_EVENT_PROPERTIES.join(', ')}).`);
      return;
    }

    TagManager.dataLayer({ dataLayer: data });
  };

  return {
    trackEvent,
  };
};

export default useGoogleTagManager;
