import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { deburr, includes } from 'lodash-es';

import useOnClickOutside from '@ess/hooks/useOnClickOutside';
import usePrioritizedOperators from '@ess/hooks/usePrioritizedOperators';

import { Modal } from '@ess/ui/Modal/ModalsTypes';
import { Button } from '@ess/ui/Button';
import { Row, Col } from '@ess/ui/FlexGrid';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import TextInput from '@ess/ui/Form/TextInput';

import { Styled } from './PrioritizedOperatorsList.styles';

type PrioritizedOperatorsListProps = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

const PrioritizedOperatorsList = ({ isOpen, onClose, onOpen }: PrioritizedOperatorsListProps) => {
  const { t } = useTranslation();
  const { prioritizedOperators, remove } = usePrioritizedOperators();
  const [searchValue, setSearchValue] = useState('');
  const [modalElement, setModalElement] = useState<HTMLElement | null>(null);
  const [currentRegions, setCurrentRegions] = useState<string[]>(Object.keys(prioritizedOperators));

  const onRemoveHandler = (operator: { label: string, value: string }, region: { id: string, name: string }) => {
    remove(operator, region);
  };

  const searchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(deburr(event.target.value.toLowerCase()));
  };

  const onCloseHandler = () => {
    setSearchValue('');
    onClose();
  };

  useEffect(() => {
    let regions = Object.keys(prioritizedOperators);

    if (searchValue) {
      regions = regions.filter((item) => {
        const { region } = prioritizedOperators[item];
        return includes(deburr(region.toLowerCase()), searchValue);
      });
    }

    setCurrentRegions(regions);
  }, [searchValue, prioritizedOperators]);

  useOnClickOutside(modalElement, onCloseHandler);

  return (
    <Modal
      ref={setModalElement as any}
      title={t('lbl_prioritized_operators')}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onCloseHandler}
      width={1000}
      theme="white"
      controls={(
        <FlexBox
          width="100%"
          alignItems="center"
          justifyContent="flex-end"
          px="small"
        >
          <Button
            size="small"
            width={90}
            label={t('lbl_close')}
            onClick={onCloseHandler}
            variant="secondary"
          />
        </FlexBox>
      )}
    >
      <Styled.PrioritizedOperators__Wrapper>
        {Object.keys(prioritizedOperators).length > 5 && (
          <Styled.PrioritizedOperators__SearchInput>
            <TextInput
              placeholder={t('lbl_search')}
              startIcon={<FontAwesomeIcon icon={faSearch}/>}
              onChange={searchInputChange}
              isClearable
              onClear={() => setSearchValue('')}
            />
          </Styled.PrioritizedOperators__SearchInput>
        )}
        {Object.keys(prioritizedOperators).length > 0 && currentRegions.length ? currentRegions.map((regionId, index) => {
          const item = prioritizedOperators[regionId];
          const isLastChild = Object.keys(prioritizedOperators).length - 1 === index;
          const { operators, region } = item || {};

          return operators && operators.length > 0 ? (
            <FlexBox key={regionId} mb={!isLastChild ? 'medium' : null} flexDirection="column">
              <Text fontWeight="bold" fontSize={14} mb="medium">{region}</Text>
              <Row gapX={5} gapY={5} flexWrap="wrap" alignItems="flex-start">
                {operators.map((operator: { label: string, value: string }) => (
                  <Col
                    key={`${regionId}_${operator.value}`}
                    width={{
                      lg: 3 / 12,
                      md: 4 / 12,
                      xs: 1,
                      xxs: 1,
                    }}
                  >
                    <Styled.PrioritizedOperators__Item>
                      <Styled.PrioritizedOperators__Item__Name>
                        {operator.label}
                      </Styled.PrioritizedOperators__Item__Name>
                      <Styled.PrioritizedOperators__Item__RemoveIcon
                        onClick={() => onRemoveHandler(operator, {
                          id: regionId,
                          name: region,
                        })}
                      >
                        <FontAwesomeIcon icon={faTimes} size="1x"/>
                      </Styled.PrioritizedOperators__Item__RemoveIcon>
                    </Styled.PrioritizedOperators__Item>
                  </Col>
                ))}
              </Row>
            </FlexBox>
          ) : null;
        }) : (
          <Styled.PrioritizedOperators__Empty>
            {t(searchValue && !currentRegions.length ? 'no_results' : 'prioritized_operators_empty')}
          </Styled.PrioritizedOperators__Empty>
        )}
      </Styled.PrioritizedOperators__Wrapper>
    </Modal>
  );
};

export { PrioritizedOperatorsList };
