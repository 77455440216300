import { DisplaySection } from '../types';

import { PrioritizedOperatorsList } from '../components/PrioritizedOperatorsList';

export const prioritizedOperators: DisplaySection = {
  display: {
    label: 'lbl_prioritized_operators',
    anchorDescription: 'show_prioritized_operators_list',
    clearDescription: 'lbl_reset_prioritized_operators',
    confirm: {
      title: 'lbl_reset_prioritized_operators',
      content: 'confirm_prioritized_operators_clear',
    },
    storage: {
      key: 'PrioritizedOperators',
      defaultValue: {},
    },
    Component: PrioritizedOperatorsList,
  },
};
