/**
 * Returns UniqueObjectId from OfferId.
 */
export const getUniqueObjectId = (offerId: string): string => {
  const OfferIdItemsArray = offerId.split('|') || [];

  OfferIdItemsArray.splice(OfferIdItemsArray.length - 2, 2);

  return OfferIdItemsArray.join('');
};
