import { createStore } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { CONTENT_SERVICE_URL } from '@ess/constants/api';

import { axiosInstance } from '@ess/auth-provider';

import { Store } from './types';

const createLoginSettingsStore = (initProps?: Partial<Store>) => {
  const DEFAULT_PROPS = {
    languages: [],
    advertisements: {
      bannerScrolling: false,
      banners: [],
    },
    passwordResetUrl: '',
    isLoading: false,
    isReady: false,
    isError: false,
  };

  return createStore<Store>()(
    persist(
      (set, getState) => ({
        ...DEFAULT_PROPS,
        ...initProps,
        fetchSettings: async () => {
          const isPersisted = getState().isReady;

          let response = null;

          if (isPersisted) {
            return;
          }

          try {
            set((state) => ({
              ...state,
              isLoading: true,
            }));

            const request = await axiosInstance.post(`${CONTENT_SERVICE_URL}Login`, {
              Query: 'Logon',
            });

            response = request?.data;

            if (response?.data && response.status?.status === 'SUCCESS') {
              const {
                advertisements = {},
                languages = [],
                passwordResetUrl = '',
              } = response.data;

              set((state) => ({
                ...state,
                languages,
                passwordResetUrl,
                isReady: true,
                advertisements,
              }));
            } else {
              set((state) => ({
                ...state,
                isError: true,
              }));
            }
          } catch (error) {
            set((state) => ({
              ...state,
              isError: true,
            }));
          } finally {
            set((state) => ({
              ...state,
              isLoading: false,
            }));
          }

          return response;
        },
      }), {
        name: 'loginSettings',
        storage: createJSONStorage(() => sessionStorage),
      }),
  );
};

export {
  createLoginSettingsStore,
};
