/**
 * Indicates if given url is valid.
 */
export const isValidUrl = (url: string) => {
  let parsedUrl;

  try {
    parsedUrl = new URL(url);
  } catch (_) {
    return false;
  }

  return ['http:', 'https:'].includes(parsedUrl.protocol);
};
