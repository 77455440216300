import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleRight,
  faCopy,
  faPencil,
  faPersonSeat,
  faR,
  faSearch,
  faShareNodes,
  faTrashCan,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { has } from 'lodash-es';
import moment from 'moment';

import { IDictionary } from '@ess/types';

import { BasketItem } from '@basket/types';

import { useReactBasket } from '@basket/hooks/useReactBasket';
import { SocialPostCreator } from '@ess/social-post-creator';

import { TRANSPORT_BUS, TRANSPORT_FLIGHT } from '@ess/constants/transport';

import { objectToURLParams, getTransportType, getFreePlaces } from '@ess/utils';

import { useAppConfig } from '@ess/context/AppConfigContext';

import AvailabilityModal from '@tourop/components/OfferElements/AvailabilityModal';
import PriceList from '@tourop/components/OfferElements/NewPriceList';

import { useReactBasketItem } from 'apps/basket/src/hooks/useBasketItem';

import EditNameModal from 'apps/basket/src/components/EditNameModal';

import { EllipsisMenu } from '@ess/components/EllipsisMenu';
import { OfferUrl, useOfferPage } from '@ess/hooks/useOfferPage';

type BasketOfferActionsProps = {
  basketItem: BasketItem;
  allowedActions?: string[];
  excludedActions?: string[];
};

type Redirect = {
  queryParams: IDictionary<any>;
  searchType?: string;
};

const BasketOfferActions = ({
  basketItem,
  allowedActions = [],
  excludedActions = ['cloneOffer'],
}: BasketOfferActionsProps) => {
  const { t } = useTranslation();
  const {
    deleteItem,
    checkOnline,
    cloneOffer,
    changeItemName,
    basket,
    getShortLink,
  } = useReactBasket();
  const { goToOfferPage } = useOfferPage();
  const { configOper, agency, expedientPermissions } = useAppConfig();
  const [isSocialPostCreatorOpen, setIsSocialPostCreatorOpen] = useState(false);
  const [isItemNameOpen, setItemNameOpen] = useState(false);
  const [isPriceDetailsOpen, setIsPriceDetailsOpen] = useState(false);
  const [isAvailabilityOpen, setIsAvailabilityOpen] = useState(false);
  const { item } = useReactBasketItem({ rowId: basketItem.rowid });

  const operatorConfig = has(
    configOper,
    basketItem?.offer?.Base?.Operator as string,
  )
    ? configOper[basketItem?.offer?.Base?.Operator as string]
    : configOper.default;

  const isMedia = ['video', 'image'].includes(basketItem.offerType);
  const transportType = getTransportType(basketItem.offer.Transport);
  const isFlight = transportType === TRANSPORT_FLIGHT;
  const isBus = transportType === TRANSPORT_BUS;

  const showPlaces: boolean = (isFlight && operatorConfig.showFlightPlaces)
    || (isBus && operatorConfig.showBusPlaces)
    || operatorConfig.showHotelPlaces;

  const hasBookingPermission = operatorConfig.isBookable
    && operatorConfig.hasQuickBook
    && operatorConfig.isOnline
    && (!expedientPermissions || expedientPermissions?.makeBooking);

  const isOldOffer = moment().isAfter(
    basketItem?.offer?.Base?.StartDate,
    'day',
  );

  const options = useMemo(
    () => [
      {
        value: 'goToSearchForm',
        label: t('btn_go_to_search'),
        disabled: !basketItem?.search?.hash,
        icon: <FontAwesomeIcon icon={faSearch} />,
      },
      {
        value: 'editItemName',
        label: t('edit_item_name'),
        icon: <FontAwesomeIcon icon={faPencil} />,
      },
      {
        value: 'socialPostCreator',
        label: t('share_on_social_media'),
        icon: <FontAwesomeIcon icon={faShareNodes} />,
      },
      {
        label: t('lbl_copy_offer'),
        icon: <FontAwesomeIcon icon={faCopy} />,
        value: 'cloneOffer',
      },
      {
        label: t('lbl_menu_pricelist'),
        value: 'priceDetails',
        icon: <FontAwesomeIcon icon={faUsers} />,
      },
      {
        label: t('lbl_menu_places'),
        icon: <FontAwesomeIcon icon={faPersonSeat} size="lg" />,
        value: 'freePlaces',
        disabled: !showPlaces,
      },
      {
        label: t('lbl_menu_basketremove'),
        icon: <FontAwesomeIcon icon={faTrashCan} />,
        value: 'deleteOffer',
      },
      {
        label: t('lbl_menu_details'),
        icon: <FontAwesomeIcon icon={faAngleDoubleRight} />,
        value: 'offerDetails',
      },
      {
        label: t('lbl_menu_quickbook'),
        value: 'quickBook',
        icon: <FontAwesomeIcon icon={faR} />,
        disabled: !hasBookingPermission || isOldOffer,
      },
    ],
    [showPlaces, hasBookingPermission],
  );

  const actions = allowedActions?.length
    ? options.filter((item) => allowedActions.includes(item.value))
    : excludedActions?.length
      ? options.filter((item) => !excludedActions.includes(item.value))
      : options;

  /**
   * Opens offer modal.
   * @param type
   */
  const openModal = (type: 'priceDetails' | 'places') => {
    checkOnline(basketItem.rowid, false, false);

    if (type === 'priceDetails') {
      setIsPriceDetailsOpen(true);
    } else {
      setIsAvailabilityOpen(true);
    }
  };

  const goToSearchPage = ({ searchType, queryParams }: Redirect): void => {
    const type = searchType ?? basketItem?.search?.form;
    const params = objectToURLParams(queryParams);

    window.open(`${window.location.origin}/${type}/search?${params}`, '_self');
  };

  /**
   * Dropdown item click handler.
   * @param value
   */
  const itemClickHandler = (value: string) => {
    const actions: any = {
      deleteOffer: () => deleteItem([basketItem.rowid]),
      editItemName: () => {
        // changeOnClickOutSideIgnore(true);
        setItemNameOpen(true);
      },
      priceDetails: () => {
        // changeOnClickOutSideIgnore(true);
        openModal('priceDetails');
      },
      freePlaces: () => {
        //   changeOnClickOutSideIgnore(true);
        openModal('places');
      },
      cloneOffer: () => {
        cloneOffer(basketItem.rowid, basket.current.id as number);
      },
      socialPostCreator: () => {
        if (basket?.affiliates?.selected) {
          getShortLink([basketItem.rowid], basket.affiliates.selected);
        }
        setIsSocialPostCreatorOpen(true);
      },
      quickBook: () => goToOfferPage({
        page: OfferUrl.Booking,
        offerData: basketItem?.offer,
        additionalQueryParams: {
          basket: btoa(`${basket.current.id},${basketItem.rowid}`),
        },
      }),
      offerDetails: () => goToOfferPage({
        page: OfferUrl.Details,
        offerData: basketItem?.offer,
      }),
      goToSearchForm: () => goToSearchPage({
        searchType: basketItem.search?.form,
        queryParams: {
          sf: basketItem?.search?.hash,
        },
      }),
    };

    if (value in actions) {
      actions[value]();
    }
  };

  return (
    <>
      {isItemNameOpen && (
        <EditNameModal
          title={t('edit_name')}
          placeholder={t('provide_new_name')}
          name={basketItem?.nameCustom ?? ''}
          onApply={(name) => changeItemName(basketItem.rowid, name)}
          onClose={() => {
            setItemNameOpen(false);
          }}
        />
      )}
      {!isMedia && (
        <>
          <AvailabilityModal
            isOpen={isAvailabilityOpen}
            isLoading={!!item?.online?.isLoading}
            offerAjaxAnswer={getFreePlaces(basketItem.offer) as any}
            showFlightPlaces={operatorConfig.showFlightPlaces}
            showHotelPlaces={operatorConfig.showHotelPlaces}
            onClose={() => {
              setIsAvailabilityOpen(false);
            }}
          />
          <PriceList
            offerData={basketItem.offer as any}
            isLoading={!!item?.online?.isLoading}
            onClose={() => {
              setIsPriceDetailsOpen(false);
            }}
            isOpen={isPriceDetailsOpen}
          />
          <SocialPostCreator
            isOpen={isSocialPostCreatorOpen}
            onClose={() => {
              setIsSocialPostCreatorOpen(false);
            }}
            agencyData={agency}
            offerData={basketItem.offer}
            offerLink={basketItem.AffiliateUrl}
          />
        </>
      )}

      <EllipsisMenu onItemClick={itemClickHandler} options={actions} />
    </>
  );
};

export { BasketOfferActions };
