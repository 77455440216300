import { has } from 'lodash-es';

import { IRegion } from '@ess/types';

/**
 * Returns regions id array.
 */
export const getRegionIds = (regions: IRegion[]) => {
  const regionIds: string[] = [];

  regions.map((region) => {
    regionIds.push(region.id);

    if (has(region, 'regions')) {
      const recurse = (regions: IRegion[]) => {
        regions.map((item) => {
          regionIds.push(item.id);

          if (has(item, 'regions')) {
            recurse(item.regions);
          }
        });
      };

      recurse(region.regions);
    }
  });

  return regionIds;
};
