import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, ButtonGroup} from '@ess/ui/Button';
import FlexBox from '@ess/ui/FlexBox';
import Checkbox from '@ess/ui/Form/Checkbox';
import Text from '@ess/ui/Text';

import {SortableItemContext} from './PostItemContainer';
import TextInput from '@ess/ui/Form/TextInput';
import {toNumber} from 'lodash-es';
import Anchor from "@ess/ui/Anchor";
import {isMobileOnly} from "react-device-detect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/pro-light-svg-icons";

export type ItemProps = {
  name: string
  scheme: any
  enabled: any
  selected: boolean
  type?: string
  icon?: string
  editable?: boolean
  images?: {
    url: string
    selected: boolean
  }[]
  id: number
}

export type PostItemProps = {
  item: ItemProps
  isPreview?: boolean
  onEdit?: (name: string, scheme: any) => void
  onChange?: (name: string, enabled: boolean) => void
}

const PostItem = ({item, isPreview, onChange, onEdit}: PostItemProps) => {
  const {t} = useTranslation();
  const {scheme, selected, editable, name} = item;
  const {attributes, listeners, ref} = useContext(SortableItemContext);
  const [currentScheme, setCurrentScheme] = useState<string>('');
  const [isEdit, setIsEdit] = useState('');
  const [isError, setIsError] = useState(false);
  const [selectedImages, setSelectedImages] = useState<any[]>([]);

  const getCurrentScheme = (): string => {
    const localStorageItem = localStorage.getItem('MXSocialPostCreator');

    if (localStorageItem) {
      return localStorageItem;
    }

    return t('basket_clipboard_default_text');
  }

  const onEditModeEnabled = () => {
    setCurrentScheme(getCurrentScheme());
    setIsEdit(item.name);
  }

  const onCloseEditMode = () => {
    setIsError(false);
    setIsEdit('');
  }

  const onSaveClickHandler = () => {
    if (onEdit) {
      if (item.name === 'custom') {
        if (isError) {
          return;
        } else {
          localStorage.setItem('MXSocialPostCreator', currentScheme);
          onEdit(name, {scheme: `${item.icon} ${currentScheme}`});
        }
      }

      if (item.name === 'images') {
        onEdit(name, {
          images: selectedImages,
          scheme: () => selectedImages.filter((image) => image.selected).map((image) => {
            return `🖼️ ${image.url}`;
          }).join('\n')
        });
      }

      setIsEdit('')
    }
  }

  const onImageCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value, checked} = event.target;

    setSelectedImages((state) => state.map((item, index) => index === toNumber(value) ? ({
      ...item,
      selected: checked,
    }) : item))

  }

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(item.name, event.target.checked);
    }
  }

  const onEditHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentScheme(event.target.value);
    setIsError(!event.target.value)
  }

  useEffect(() => {
    if (item.name === 'images' && item.images?.length) {
      setSelectedImages(item.images);
    }
  }, [item])


  const editableHandler = isMobileOnly ? (
    <FlexBox ml="tiny" mb={'auto'} mt={'small'}>
      {isEdit === item.name ? (
        <FlexBox
          position={'absolute'}
          bottom={'8px'}
          right={'8px'}
        >
          <ButtonGroup>
            {isEdit && (<Button
              variant="secondary"
              label={t('lbl_cancel')}
              onClick={onCloseEditMode}
              size="small"
            />)}
            <Button
              ml="auto"
              label={t('save')}
              onClick={onSaveClickHandler}
              size="small"
            />
          </ButtonGroup>
        </FlexBox>
      ) : (
        <FontAwesomeIcon
          size={"lg"}
          onClick={onEditModeEnabled}
          icon={faPenToSquare}
        />
      )}
    </FlexBox>
  ) : (
    <FlexBox ml="tiny" py="small">
      {isEdit === item.name ? (
        <ButtonGroup>
          {isEdit && (<Button
            variant="secondary"
            label={t('lbl_cancel')}
            onClick={onCloseEditMode}
            size="small"
          />)}
          <Button
            ml="auto"
            label={t('save')}
            onClick={onSaveClickHandler}
            size="small"
          />
        </ButtonGroup>
      ) : (
        <Button
          ml="auto"
          label={t('edit_action')}
          onClick={onEditModeEnabled}
          size="small"
        />
      )}
    </FlexBox>
  )

  return (
    <FlexBox
      width="100%"
      alignItems="center"
      position={'relative'}
      style={{
        transition: 'all .4s ease-in',
        wordBreak: 'break-all',
        whiteSpace: 'pre-line',
        ...isPreview ? {
          lineHeight: '24px'
        } : {
          padding: '0 10px',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: 'rgba(0,0,0,0.1) 0 1px 4px 0px',
          marginBottom: '5px',
          paddingBottom: isEdit === item.name && isMobileOnly ? '33px' : '0px'
        },
      }}>
      {!isPreview ? (
        <>
          <FlexBox flexShrink={0}>
            {isEdit !== 'images ' && (<Checkbox checked={selected} onChange={onCheckboxChange}/>)}
          </FlexBox>
          <FlexBox
            ref={ref}
            alignItems="center"
            height="100%"
            width="100%"
            py="small"
            {...{
              ...!isEdit ? {
                ...attributes,
                ...listeners,
              } : {}
            }}
          >
            {isEdit ? (
              <>
                {name === 'custom' && (
                  <FlexBox px="tiny" width="100%">
                    <TextInput
                      autoFocus
                      onChange={onEditHandler}
                      value={currentScheme}
                      error={isError ? t('provide_min_chars') : ''}
                    />
                  </FlexBox>
                )}

                {name === 'images' && (
                  <FlexBox px="tiny" width="100%" flexDirection="column">
                    {item?.images?.map((image, index) => (
                      <FlexBox key={`image-${index}`} width="100%" mb="tiny" flexDirection="column">
                        <Checkbox checked={selectedImages[index].selected} value={index}
                                  onChange={onImageCheckboxChange}/>
                        <Text>{image.url}</Text>
                        <FlexBox borderRadius={6} overflow="hidden">
                          <img src={image.url} style={{width: '100%', height: '200px', objectFit: 'cover'}}/>
                        </FlexBox>
                      </FlexBox>
                    ))}
                  </FlexBox>
                )}
              </>
            ) : item.name === 'images' ? item.images?.map((image) => {
              return `🖼️ ${image.url}`;
            }).join('\n') : item.name === 'offerUrl' ? `👉 ${scheme}`
              : scheme}
          </FlexBox>
          {editable && editableHandler}
        </>
      ) : name === 'images' && item?.images?.length ? (
        <FlexBox width="100%" flexDirection="column">
          {item.images
            .filter((item) => item.selected)
            .map((item) => (
              <FlexBox width="100%" minWidth={0}>
                {scheme.split(' ')[0]}
                <Anchor
                  color="blue"
                  href={item.url}
                  target="_blank"
                  style={{
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                  }}
                >
                  {item.url}
                </Anchor>
              </FlexBox>
            ))}
        </FlexBox>
      ) : item.name === 'offerUrl' ? (
        <FlexBox width="100%">
          <FlexBox>
            👉
          </FlexBox>
          <Anchor
            color="blue"
            href={item.scheme}
            target="_blank"
            style={{
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            {item.scheme}
          </Anchor>
        </FlexBox>
      ) : scheme}
    </FlexBox>
  )
};

export default PostItem;
