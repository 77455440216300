import React, { CSSProperties } from 'react';

import { Styled } from '../Tabs.styles';

type TabProps = {
  value: any;
  label: string;
  children?: React.ReactElement;
  disabled?: boolean;
  icon?: React.ReactElement | null;
  onClick?: (event: React.MouseEvent, value: any) => void;
  style?: CSSProperties;
};

const Tab = ({
  children,
  label,
  value,
  icon = null,
  disabled = false,
  style = {},
  onClick = undefined,
}: TabProps) => {
  const onTabClickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (onClick && !disabled) {
      onClick(event, value);
    }
  };
  return (
    <Styled.Tab
      id={`tab-${value}`}
      role="tab"
      aria-controls={`tab-panel-${value}`}
      onClick={onTabClickHandler}
      isDisabled={disabled}
      style={style}
    >
      {icon && (
        <Styled.Tab__Icon>
          {icon}
        </Styled.Tab__Icon>
      )}
      <Styled.Tab__Label>
        {children || label}
      </Styled.Tab__Label>
    </Styled.Tab>
  );
};

export default Tab;
