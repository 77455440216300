import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { isEmpty, round, toNumber } from 'lodash-es';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';

import { DATE_DISPLAY_FORMAT } from '@ess/constants/api';

import { SwiperContainer, SwiperSlide } from '@ess/ui/Swiper';

import Switch from '@ess/ui/Switch';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

import useOnClickOutside from '@ess/hooks/useOnClickOutside';
import Tooltip from '@ess/ui/Tooltip';
import { NoPrint, PrintOnly } from '@ess/react-print';
import { NavigationArrow, NavigationArrowsContainer } from './NavigationArrow';
import { TooltipContainer } from './TooltipContainer';

import { Styled } from './PriceTimeLine.styles';

type PriceTimeLineProps = {
  isBasket?: boolean
  data: any
  enablePlaceHolderData?: boolean
  requestParams?: Record<string, any>
  fetchAsync?: ({ sections, params }: { sections: string[], params: any }) => void
  isLoading?: boolean
  otherOperatorsEnabled?: boolean
  isExpandable?: boolean
  isExpanded?: boolean
  onToggle?: () => void
  height?: number
}

const getChartData = (data: any) => {
  const parsedPrices = data.map((item: any) => Math.round(toNumber(item?.Offer?.Base?.Price?.FirstPerson?.amount ?? 0)));
  const max = Math.round(Math.max(...parsedPrices));
  const selectedIndex = data.findIndex((item: any) => item.Selected) ?? 0;
  const prices = parsedPrices.map((item: number, index: number) => ({
    value: item,
    percentage: (item / max) * 100,
  }));

  return {
    max,
    prices,
    selectedIndex,
  };
};

const getElements = (width: number, Dates: any[], enablePlaceHolderData: boolean) => {
  if (enablePlaceHolderData) {
    const paddingSides = 60;
    const elementWidth = 70;
    const containerAvailAbleWidth = width - paddingSides - (Dates.length * elementWidth);
    const numberOfRenderElements = containerAvailAbleWidth / elementWidth < 0 ? 0 : containerAvailAbleWidth / elementWidth > 0 && containerAvailAbleWidth / elementWidth < 1 ? 1 : containerAvailAbleWidth / elementWidth;

    return [...Array.from({ length: numberOfRenderElements + (numberOfRenderElements !== 0 ? 1 : 0) }).map(() => `PLACEHOLDER/${Math.floor(Math.random() * (90 - 30 + 1)) + 30}`)];
  }
  return [];
};

const swiperSettings = {
  slidesPerView: 'auto' as any,
  slidesPerGroup: 4,
  spaceBetween: 10,
};

const PriceTimeLine = ({
  data,
  requestParams = {},
  fetchAsync = undefined,
  height = 320,
  isExpandable = false,
  isExpanded = true,
  isLoading = false,
  otherOperatorsEnabled = true,
  onToggle = undefined,
  isBasket = false,
  enablePlaceHolderData = true,
}: PriceTimeLineProps) => {
  const { t } = useTranslation();
  const { Dates = [], Offers = [] } = data ?? {};
  const [otherOperators, setOtherOperators] = useState(false);
  const [sliderState, setSliderState] = useState({
    progress: 0,
    index: 0,
    showArrowLeft: false,
    showArrowRight: false,
    isOpen: isExpanded,
  });

  const showLabel = otherOperators && otherOperatorsEnabled && !isLoading;
  const swiperRef = useRef<any>(null);
  const chartRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const [generalState, setGeneralState] = useState<any>({
    placeholders: getElements(containerRef?.current?.clientWidth, Dates, !!enablePlaceHolderData),
    openOption: '',
  });

  useOnClickOutside(containerRef?.current, () => setGeneralState((state: any) => ({ ...state, openOption: '' })));

  const { prices, selectedIndex } = getChartData(Offers);

  const toggleOtherOperators = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setOtherOperators((state) => !state);

    if (fetchAsync) {
      fetchAsync({
        sections: ['PriceChart'],
        params: {
          ...requestParams,
          PriceChart: {
            ...requestParams?.PriceChart,
            AlternativeOperators: isChecked,
          },
        },
      });
    }
  };

  const togglePrices = () => {
    setSliderState((state) => ({
      ...state,
      isOpen: !state.isOpen,
    }));

    if (onToggle) {
      onToggle();
    }
  };

  const nextHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    swiperRef.current?.swiper.slideNext();
  };

  const previousHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    swiperRef.current.swiper.slidePrev();
  };

  const onActiveIndexChange = (swiper: any) => {
    setSliderState((state) => ({
      ...state,
      progress: swiper.progress,
      index: swiper?.activeIndex,
      showArrowLeft: swiper?.allowSlidePrev,
      showArrowRight: swiper?.allowSlideNext,
    }));
  };

  const calculateWidth = (width: number, dates: string[]) => {
    setGeneralState((state: any) => ({
      ...state,
      placeholders: getElements(width, dates, !!enablePlaceHolderData),
    }));
  };

  const onChartClick = (offer: any) => {
    const { Base } = offer;
    const { OfferId } = Base;
    setGeneralState((state: any) => ({
      ...state,
      openOption: state.openOption === OfferId ? '' : OfferId,
    }));
  };

  const handleResize = useDebouncedCallback(() => {
    calculateWidth(containerRef?.current?.clientWidth ?? 0, Dates);
  }, 200);

  useEffect(() => {
    if (swiperRef.current) {
      const swiper = swiperRef?.current?.swiper;
      setSliderState((state) => ({
        ...state,
        progress: swiper.progress,
        index: swiper?.activeIndex,
        showArrowLeft: swiper?.allowSlidePrev,
        showArrowRight: swiper?.allowSlideNext,
      }));
    }
  }, [data?.Dates]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    calculateWidth(containerRef?.current?.clientWidth ?? 0, data?.Dates ?? []);
  }, [data?.Dates]);

  return (
    <Styled.Container ref={containerRef}>
      {isExpandable && (
        <Styled.Chart__ToggleButton onClick={togglePrices}>
          <span>{t(sliderState.isOpen ? 'lbl_hide_prices' : 'lbl_show_prices')}</span>
          <FontAwesomeIcon icon={sliderState.isOpen ? faChevronUp : faChevronDown}/>
        </Styled.Chart__ToggleButton>
      )}
      {otherOperatorsEnabled && (
        <Styled.Chart__Controls>
          <FlexBox
            flexShrink={0}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Switch onChange={toggleOtherOperators} checked={otherOperators}/>
            <Text color="textColor" ml="tiny">{`${t('lbl_change_operator')}`}</Text>
          </FlexBox>
        </Styled.Chart__Controls>
      )}
      <NoPrint>
        <Styled.Chart
          ref={chartRef}
          isExpandable={isExpandable}
          height={sliderState.isOpen ? height : 70}
        >
          <FlexBox
            width="100%"
            height="100%"
          >
            <NoPrint>
              <SwiperContainer
                ref={swiperRef}
                options={[...Dates, ...generalState.placeholders]}
                onActiveIndexChange={onActiveIndexChange}
                settings={{
                  ...swiperSettings,
                  initialSlide: selectedIndex,
                }}
              >
                {[...data?.Dates ?? [], ...generalState.placeholders]?.map((date: any, index: number) => {
                  if (date.includes('PLACEHOLDER')) {
                    return (
                      <NoPrint key={`chart-bar-${index}-placeholder`}>
                        <SwiperSlide style={{ width: '60px' }}>
                          <FlexBox
                            height="100%"
                            flexDirection="column"
                            marginBottom="50px"
                          >
                            <Styled.ChartBar__Value__Inner__PlaceHolder
                              style={{ height: `${date.split('/')[1]}%` }}
                            />
                            <Styled.ChartBar__Date__PlaceHolder/>
                          </FlexBox>
                        </SwiperSlide>
                      </NoPrint>
                    );
                  }
                  const price = prices[index];
                  const offer = Offers[index];
                  let showTooltip = false;
                  let operatorDesc = offer?.Offer?.Base?.OperatorDesc ?? '';
                  if ((offer?.Offer?.Base?.OperatorDesc ?? '').length > 25) {
                    showTooltip = true;
                    operatorDesc = `${(offer.Offer.Base.OperatorDesc).substring(0, 23)}...`;
                  }

                  return (
                    <NoPrint key={`chart-bar-${index}`}>
                      <SwiperSlide style={{ width: '60px' }}>
                        <TooltipContainer
                          appendTo={isBasket ? document.getElementById('Basket-Drawer') : undefined}
                          visible={generalState?.openOption === offer?.Offer?.Base?.OfferId}
                          offerData={offer?.Offer}
                          isSelected={offer?.Selected}
                          showLabel={otherOperators}
                        >
                          <Styled.ChartBar
                            onClick={() => onChartClick(offer?.Offer)}
                            selected={offer?.Selected}
                            showLabel={showLabel}
                          >
                            <Styled.ChartBar__Value>
                              <Styled.ChartBar__Value__Inner style={{ height: `${price.percentage}%` }}>
                                <span>{price.value}</span>
                                {showLabel && (
                                  <Tooltip disabled={!showTooltip} content={offer?.Offer?.Base?.OperatorDesc}>
                                    <Styled.ChartBar__Label>
                                      {operatorDesc}
                                    </Styled.ChartBar__Label>
                                  </Tooltip>
                                )}
                              </Styled.ChartBar__Value__Inner>
                            </Styled.ChartBar__Value>
                            <Styled.ChartBar__Date>
                              {moment(date, DATE_DISPLAY_FORMAT).format('dd. DD,[\n] MMM. YYYY')}
                            </Styled.ChartBar__Date>
                          </Styled.ChartBar>
                        </TooltipContainer>
                      </SwiperSlide>
                    </NoPrint>
                  );
                })}
              </SwiperContainer>
            </NoPrint>
          </FlexBox>
          <NoPrint>
            <NavigationArrowsContainer>
              {sliderState.progress > 0 && sliderState.showArrowLeft && isEmpty(generalState.placeholders)
                && <NavigationArrow direction="left" onClick={previousHandler}/>}
              {round(sliderState.progress, 1) < 1 && sliderState.showArrowRight && isEmpty(generalState.placeholders)
                && <NavigationArrow direction="right" onClick={nextHandler}/>}
            </NavigationArrowsContainer>
          </NoPrint>
        </Styled.Chart>
      </NoPrint>
      <PrintOnly>
        <Styled.Print__Chart
          ref={chartRef}
          isExpandable={isExpandable}
          height={sliderState.isOpen ? height : 70}
        >
          <FlexBox
            height="100%"
            width="100%"
            maxWidth="100%"
            overflow="hidden"
            style={{
              gap: '0 10px',
              flexDirection: 'initial',
              flexWrap: 'nowrap',
              flexFlow: 'initial',
            }}
          >
            {[...Dates, ...generalState.placeholders]?.map((date: any, index: number) => {
              if (date.includes('PLACEHOLDER')) {
                return (
                  <FlexBox
                    key={`chart-date-${index}-placeholder`}
                    display="flex"
                    height="100%"
                    width="60px"
                    flexShrink={0}
                    maxWidth="60px"
                    flexDirection="column"
                    marginBottom="50px"
                  >
                    <Styled.ChartBar__Value__Inner__PlaceHolder
                      style={{ height: `${date.split('/')[1]}%` }}
                    />
                    <Styled.ChartBar__Date__PlaceHolder/>
                  </FlexBox>
                );
              }

              const price = prices[index];
              const offer = Offers[index];

              return (
                <FlexBox
                  key={`chart-date-${index}`}
                  width="60px"
                  maxWidth="60px"
                  height="100%"
                >
                  <Styled.ChartBar
                    onClick={() => onChartClick(offer?.Offer)}
                    selected={offer?.Selected}
                    showLabel={showLabel}
                  >
                    <Styled.ChartBar__Value>
                      <Styled.ChartBar__Value__Inner style={{ height: `${price.percentage}%` }}>
                        <span>{price.value}</span>
                        {showLabel && (
                          <Styled.ChartBar__Label>
                            {offer?.Offer?.Base?.OperatorDesc}
                          </Styled.ChartBar__Label>
                        )}
                      </Styled.ChartBar__Value__Inner>
                    </Styled.ChartBar__Value>
                    <Styled.ChartBar__Date>
                      {moment(date, DATE_DISPLAY_FORMAT).format('dd. DD,[\n] MMM. YYYY')}
                    </Styled.ChartBar__Date>
                  </Styled.ChartBar>
                </FlexBox>
              );
            })}
            {Array.from({ length: 10 }).map(() => (
              <FlexBox
                display="flex"
                height="100%"
                width="60px"
                flexShrink={0}
                maxWidth="60px"
                flexDirection="column"
                marginBottom="50px"
              >
                <Styled.ChartBar__Value__Inner__PlaceHolder
                  style={{ height: `${Math.floor(Math.random() * (90 - 30 + 1)) + 30}%` }}
                />
                <Styled.ChartBar__Date__PlaceHolder/>
              </FlexBox>
            ))}
          </FlexBox>
        </Styled.Print__Chart>
      </PrintOnly>
      {!isLoading && isEmpty(Dates) && (
        <Styled.Anomaly__Container>
          <Styled.Anomaly__Title>
            {t('lbl_no_offers_found_price_timeline')}
          </Styled.Anomaly__Title>
        </Styled.Anomaly__Container>
      )}
    </Styled.Container>
  );
};

export {
  PriceTimeLine,
};
