import {
  capitalize, includes, isString, trim,
} from 'lodash-es';

import { IOffer } from '@ess/types';

const getCapitalizedString = (string: string) => {
  const capitalizedStrings: string[] = [];
  const hasDash = includes(string, '-');
  const parsedString = hasDash ? string.split('-') : [string];

  parsedString.map((item) => {
    capitalizedStrings.push(capitalize(trim(item)));
  });

  return hasDash ? capitalizedStrings.join(' - ') : capitalizedStrings[0];
};

const stringToHex = (string: string) => {
  const prefix = '0x';
  const buffer = Buffer.from(getCapitalizedString(string), 'utf8');
  const hex = buffer.toString('hex');

  return `${prefix}${hex}`;
};

const fixOffer = (offer: IOffer): IOffer => {
  let newOffer = offer;
  if (isString(offer?.Base?.DestinationLocation)) {
    const name = offer.Base?.DestinationLocation as string;
    newOffer = {
      ...newOffer,
      ...{
        Base: {
          ...newOffer.Base,
          DestinationLocation: {
            Id: stringToHex(name),
            Name: name,
          },
        },
      },
    };
  }
  if (isString(offer?.Base?.DepartureLocation)) {
    const name = offer.Base?.DepartureLocation as string;
    newOffer = {
      ...newOffer,
      Base: {
        ...newOffer.Base,
        DepartureLocation: {
          Id: stringToHex(name),
          Name: name,
        },
      },
    };
  }
  return newOffer;
};

interface ObjectWithOffer {
  offer: IOffer
}

const fixDeprecatedOfferArray = (offers: any[]): ObjectWithOffer[] => offers.map((value) => {
  const v = value;
  v.offer = fixOffer(v.offer ?? {});
  return v;
});

export {
  stringToHex,
  fixDeprecatedOfferArray,
};
