type MoveArrayItem<T> = {
  array: T[];
  toIndex: number;
  findFunction: (item: T) => void;
  sortFunction?: (array: T[]) => T[];
}

export const moveArrayItem = <T, >({
  array,
  toIndex,
  findFunction,
  sortFunction = undefined,
}: MoveArrayItem<T>) => {
  let newArray = [...array];

  const foundIndex = newArray.findIndex(findFunction);

  if (foundIndex !== undefined) {
    const itemToMove = newArray[foundIndex];

    newArray.splice(foundIndex, 1);

    if (sortFunction) {
      newArray = sortFunction(newArray);
    }

    newArray.splice(toIndex, 0, itemToMove);
  }

  return newArray;
};
