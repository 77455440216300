import React, { memo, useEffect, useContext } from 'react';
import {
  capitalize, includes, max, range,
} from 'lodash-es';
import moment, { Moment } from 'moment';

import { OptionTypeBase } from 'react-select';

import { ADULT_CODE, INFANT_CODE } from '@ess/constants/participants';
import { AppConfigContext } from '@ess/context/AppConfigContext';

import FlexBox from '@ess/ui/FlexBox';
import Select from '@ess/ui/Form/Select';

const getYearsOptions = (minDate: any,maxDate: any) => {
  return range(minDate.year(), maxDate.year() - 1).map((item) => ({
    label: item,
    value: item,
  }));
};

const getMonthsOptions = () => moment.months().map((label, value) => ({
  label: capitalize(label),
  value,
}));

type MonthAndYearSelectProps = {
  month: any
  minDate: any
  maxDate: any
  personType?: string
  onMonthSelect: (currentMonth: Moment, newMonthVal: string) => void
  onYearSelect: (currentMonth: Moment, newMonthVal: string) => void
}


const MonthAndYearsSelect = ({
  month,
  minDate,
  maxDate,
  personType = ADULT_CODE,
  onMonthSelect,
  onYearSelect,
}: MonthAndYearSelectProps) => {
  const { state: SFContext } = useContext(AppConfigContext);
  const { language } = SFContext;
  const monthOptions = getMonthsOptions();
  const yearsOptions = getYearsOptions(minDate, maxDate);
  const availableYears = yearsOptions.map((item) => item.value);
  const monthValue = month.month();
  const yearValue = includes(availableYears, month.year()) ? month.year() : max(availableYears);

  moment.locale(language);

  const changeMonthHandler = (selected: OptionTypeBase | null) => {
    onMonthSelect(month, selected !== null ? selected.value : null);
  };

  const changeYearHandler = (selected: OptionTypeBase | null) => {
    onYearSelect(month, selected !== null ? selected.value : null);
  };

  useEffect(() => {
    if (monthValue !== month.month()) {
      onMonthSelect(month, monthValue);
      onYearSelect(month, month.year());
    }
  }, [month]);

  return (
    <FlexBox justifyContent="space-between">
      <FlexBox flexGrow={1} mr="small">
        <Select
          options={monthOptions}
          value={monthValue}
          onChange={changeMonthHandler}
          maxMenuHeight={220}
          isSearchable={false}
          isClearable={false}
          menuPortalTarget={null}
          menuPlacement="bottom"
        />
      </FlexBox>
      <FlexBox width="80px">
        <Select
          options={yearsOptions}
          value={yearValue}
          onChange={changeYearHandler}
          maxMenuHeight={220}
          isSearchable={false}
          isClearable={false}
          menuPortalTarget={null}
          menuPlacement="bottom"
        />
      </FlexBox>
    </FlexBox>
  );
};

export default memo(MonthAndYearsSelect);
