import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical, faThumbTack as solidPin } from '@fortawesome/pro-solid-svg-icons';
import { faThumbTack } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { includes } from 'lodash-es';
import { isMobileOnly } from 'react-device-detect';

import { BasketPosition, BasketView } from '@basket/types';

import { BASKET_ICON_CLASS, BASKET_ID } from '@basket/constants';

import { useReactBasket } from '@basket/hooks/useReactBasket';
import useStorage from '@ess/hooks/useStorage';

import { useAgentSettings } from '@ess/components/AgentSettings';

import Drawer from '@ess/ui/Drawer';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

import { ViewSwitcher } from '@basket/components/ViewSwitcher';
import AutoSuggestBasketList from '@basket/components/AutoSuggestBasketList';

import { useDraggable } from '@dnd-kit/core';

type BasketContainerProps = {
  children: React.ReactNode;
  isPortal: boolean;
  style?: any;
}

const BasketContainer = ({ children, isPortal, style }: BasketContainerProps) => {
  const { t } = useTranslation();
  const { basketPosition, openBasketOnHover } = useAgentSettings(({ values }) => ({
    basketPosition: isMobileOnly ? BasketPosition.right : values.basket.basketPosition,
    openBasketOnHover: values.basket.openBasketOnHover,
  }));

  const clickTimeout = useRef<any>({
    timeout: null,
    blocked: false,
  });
  const [isPinned, setIsPinned] = useStorage<boolean>('localStorage', 'PinnedBasket', false);
  const mobile = useMediaQuery({ minWidth: 0, maxWidth: 768 });

  const {
    basket,
    toggleBasket,
    setPosition,
    setLastAgentSettingsPosition,
  } = useReactBasket();

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
  } = useDraggable({
    id: 'basket-container',
  });

  const openBasketOnHoverHandler = useCallback(() => {
    if (!basket.isOpen) {
      toggleBasket();

      clickTimeout.current.blocked = true;
      clickTimeout.current.timeout = setTimeout(() => {
        clickTimeout.current.blocked = false;
      }, 500);
    }
  }, [basket.isOpen]);

  const onPinChangeHandler = () => {
    setIsPinned(!isPinned);
  };

  const getPosition = useCallback(() => basket.position ?? basketPosition ?? BasketPosition.right, [basket.position, basketPosition]);

  const title = useMemo(() => (
    <>
      <FlexBox width="100%" alignItems="center">
        {!mobile && (
        <FlexBox ref={setActivatorNodeRef} mr="small" style={{ cursor: 'grab' }} {...listeners}>
          <FontAwesomeIcon icon={faGripDotsVertical}/>
        </FlexBox>
        )}
        <FlexBox mr="small" style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon
            icon={isPinned ? solidPin : faThumbTack}
            onClick={onPinChangeHandler}
          />
        </FlexBox>
        <FlexBox alignItems="center">
          <Text fontSize="title">
            {basket.view === BasketView.Basket ? t('lbl_basket') : t('lbl_baskets_list')}
          </Text>
          <FlexBox ml="small">
            <ViewSwitcher/>
          </FlexBox>
        </FlexBox>
        {basket.view === BasketView.Basket && (
          <FlexBox
            ml="medium"
            width="100%"
            maxWidth="270px"
          >
            <AutoSuggestBasketList/>
          </FlexBox>
        )}
      </FlexBox>
    </>
  ), [basket, isPinned, mobile]);

  useEffect(() => {
    const isOpenOnHover = openBasketOnHover;
    const basketIcon = document.getElementsByClassName(BASKET_ICON_CLASS);

    const openBasketOnClick = () => {
      if (!clickTimeout.current.blocked) {
        toggleBasket();
      }
    };

    if (basketIcon.length) {
      if (isOpenOnHover) {
        basketIcon[0].addEventListener('mouseenter', openBasketOnHoverHandler);
        basketIcon[0].addEventListener('click', openBasketOnClick);
      } else {
        basketIcon[0].addEventListener('click', toggleBasket);
      }
    }

    return () => {
      if (basketIcon.length) {
        if (isOpenOnHover) {
          basketIcon[0].removeEventListener('mouseenter', openBasketOnHoverHandler);
          basketIcon[0].removeEventListener('click', openBasketOnClick);
        } else {
          basketIcon[0].removeEventListener('click', toggleBasket);
        }
      }
    };
  }, [openBasketOnHover, openBasketOnHoverHandler]);

  useEffect(() => {
    if (!basket.position) {
      setPosition(basketPosition);
    }

    if (basketPosition !== basket.lastAgentSettingsPosition) {
      setLastAgentSettingsPosition(basketPosition);
      setPosition(basketPosition);
    }
  }, [basketPosition]);

  return (
    <Drawer
      isPortal={isPortal}
      id={BASKET_ID}
      ref={setNodeRef}
      top={isPortal ? 88 : undefined}
      isOpen={basket.isOpen}
      title={title}
      showOverlay={false}
      zIndex={!isPortal ? 1 : 9999}
      contentStyles={{
        overflow: 'hidden',
      }}
      containerStyles={mobile ? {
        maxWidth: '100%',
        top: '50px',
        maxHeight: 'calc(100% - 50px)',
        height: '100%',
      } : {
        ...includes([BasketPosition.right, BasketPosition.left], getPosition()) ? {
          maxWidth: '500px',
          width: '100%',
          top: isPortal ? '88px' : undefined,
          marginRight: !isPortal && getPosition() === BasketPosition.left ? '10px' : '0px',
          marginLeft: !isPortal && getPosition() === BasketPosition.right ? '24px' : '0px',
        } : {},
        ...includes([BasketPosition.top, BasketPosition.bottom], getPosition()) ? {
          height: '100%',
          top: basket.position === 'top' && isPortal ? '88px' : undefined,
          maxHeight: basket.view !== BasketView.BasketList && basket.offerView === 'grid' ? '520px' : '650px',
          maxWidth: '1349px',
        } : {},
        ...style,
      }}
      onClose={toggleBasket}
      animationEnabled={false}
      position={getPosition()}
      {...includes([BasketPosition.top, BasketPosition.bottom], getPosition()) ? { maxWidth: 1349 } : {}}
      {...attributes}
    >
      {children}
    </Drawer>
  );
};

export {
  BasketContainer,
};
