import {
  flatten, includes, isEmpty, toNumber,
} from 'lodash-es';

import { IDictionary } from '@ess/types';

export const getAgentAttributesXCodes = (values: any, dictionary: IDictionary<{name: string, xcodes: Array<string>}>) => {
  const xCodes = !isEmpty(dictionary) ? Object.entries(dictionary)
    .filter((item) => includes(values, item[0]))
    .map((item) => item[1].xcodes.map((xCode) => toNumber(xCode))) : [];
  return flatten(xCodes);
};
