import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash-es';
import clipboardCopy from 'clipboard-copy';

import { ShareMethod } from '@basket/types';

import { CONTENT_SERVICE_URL } from '@ess/constants/api';

import {
  showToast,
  TOAST_ERROR,
  TOAST_SUCCESS,
  getCurrentLanguage,
} from '@ess/utils';

import { promiseRequest } from '@ess/v5-data-provider/request';

import { Button } from '@ess/ui/Button';
import FlexBox from '@ess/ui/FlexBox';
import Select from '@ess/ui/Form/Select';
import TextArea from '@ess/ui/Form/TextArea';

import { ShareInput } from '@ess/components/ShareInput';

type LinkGeneratorProps = {
  isLoading: boolean
  generateLink: (affiliateId?: number, description?: string) => Promise<string>
  method: ShareMethod
  description: string
}

interface IAffiliates {
  list: any[]
  selected: any
  isLoading: boolean
}

interface IFormData {
  affiliates: IAffiliates,
  description: string
}

interface IGeneratorState {
  isLoading: boolean
  isReady: boolean
  link: string
}

const dataInitialState = {
  isLoading: false,
  isReady: false,
  link: '',
};

const affiliatesInitialState = {
  list: [],
  selected: null,
  isLoading: false,
};

const LinkGenerator = ({
  generateLink,
  method,
  description,
  isLoading,
}: LinkGeneratorProps) => {
  const { t } = useTranslation();
  const [data, setData] = useState<IGeneratorState>(dataInitialState);
  const [formData, setFormData] = useState<IFormData>({
    affiliates: affiliatesInitialState,
    description,
  });

  const onCopyHandler = useCallback(() => {
    if (data.link) {
      clipboardCopy(data.link).then(
        (() => showToast(TOAST_SUCCESS, t('copied_to_clipboard_msg'))),
        (() => showToast(TOAST_ERROR, t('copy_to_clipboard_failed_msg'))),
      );
    }
  }, [data.link]);

  const onClickHandler = async () => {
    setData((state) => ({
      ...state,
      isLoading: true,
    }));

    const [affiliateId] = formData.affiliates.selected.split(':');
    const isIbeMethod = method === ShareMethod.IbeLink;
    const link = await generateLink(
      isIbeMethod ? toNumber(affiliateId) : undefined,
      formData.description,
    );

    setData((state) => ({
      ...state,
      isLoading: false,
      isReady: true,
      link,
    }));
  };

  const onAffiliateChange = (selectedOption: any) => {
    setFormData((state) => ({
      ...state,
      affiliates: {
        ...state.affiliates,
        selected: selectedOption?.value ?? null,
      },
    }));

    setData((state) => ({
      ...state,
      isReady: false,
    }));
  };

  const onDescriptionChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData((state) => ({
      ...state,
      description: event.target.value,
    }));

    setData((state) => ({
      ...state,
      isReady: false,
    }));
  };

  const fetchAffiliates = () => {
    (async () => {
      const data = encodeURIComponent(JSON.stringify({
        Language: getCurrentLanguage(),
      }));

      setFormData((state) => ({
        ...state,
        affiliates: {
          ...state.affiliates,
          isLoading: true,
        },
      }));

      try {
        const actions = await promiseRequest(`${CONTENT_SERVICE_URL}BasketActions/${data}`, null, 3);
        const basketActions = actions?.Sections?.BasketActions?.BasketActions?.Basket?.List;

        if (basketActions) {
          const shareBasket = basketActions.find((item: any) => item.Action === 'shareBasket');
          const ibeLink = shareBasket?.List?.find((item: any) => item.Action === 'ibelink');
          const { Affiliates = [], Url = '' } = ibeLink ?? {};
          const { hostname = undefined } = new URL(Url);
          const selected = Affiliates?.find((item: any) => item.Domain === hostname) ?? null;

          setFormData((state) => ({
            ...state,
            affiliates: {
              ...state.affiliates,
              list: Affiliates,
              selected: selected?.Id ? `${selected.Id}:${selected.Domain}` : null,
            },
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setFormData((state) => ({
          ...state,
          affiliates: {
            ...state.affiliates,
            isLoading: false,
          },
        }));
      }
    })();
  };

  useEffect(() => {
    setData(dataInitialState);
  }, [method]);

  useEffect(() => {
    fetchAffiliates();
  }, []);

  const options = useMemo(
    () => formData.affiliates.list.map((item) => ({
      label: item.Domain, value: `${item.Id}:${item.Domain}`,
    })), [formData.affiliates.list]);

  return (
    <FlexBox flexDirection="column">
      {method === ShareMethod.IbeLink && (
        <FlexBox
          mb="small"
          width="100%"
          flexDirection="column"
        >
          <Select
            options={options}
            value={formData.affiliates.selected}
            onChange={onAffiliateChange}
            isClearable={false}
            isLoading={formData.affiliates.isLoading}
          />
        </FlexBox>
      )}

      <FlexBox
        mb="small"
        width="100%"
        flexDirection="column"
      >
        <TextArea
          name="description"
          value={formData.description}
          placeholder={t('lbl_description')}
          onChange={onDescriptionChangeHandler}
        />
      </FlexBox>

      <FlexBox mb="small">
        <ShareInput
          data={data?.link}
          placeholder={t('lbl_click_generate_link')}
          type="url"
        />

      </FlexBox>

      <Button
        size="large"
        width="100%"
        isLoading={isLoading}
        disabled={data.isReady}
        label={t('lbl_generate_link')}
        onClick={onClickHandler}
      />
    </FlexBox>
  );
};

export {
  LinkGenerator,
};
