/**
 * Returns participants with date ( CHILD | INFANT ).
 */
import { has } from 'lodash-es';
import moment from 'moment';

import { DATE_DISPLAY_FORMAT, DATE_REQUEST_FORMAT } from '@ess/constants/api';

export const getParticipantsWithDate = (rooms, count) => {
  const roomsArray = count === 1 ? rooms.slice(0, -1) : rooms;

  return roomsArray.map(({ CHILD, INFANT }) => ({
    CHILD: Array.from({ length: CHILD.value }).map((item, index) => ({
      date: CHILD.dates[index],
    })),
  }));
};

/**
 * Returns pseudo fields of participants with date for validation purposes.
 *
 * @param valueProperty
 * @param rooms {array}
 * @returns {object}
 */

export const getFieldsOfParticipantsWithDate = (rooms = [], valueProperty = undefined) => {
  const fields = {};

  rooms.map(({ CHILD }, roomIndex) => ({
    CHILD: Array.from({ length: valueProperty && has(CHILD, valueProperty) ? CHILD[valueProperty] : 4 }).map((item, index) => {
      fields[`Custom.Birthdates.${roomIndex}.CHILD.${index}`] = (CHILD?.dates[index] && moment(CHILD?.dates[index], [DATE_REQUEST_FORMAT, DATE_DISPLAY_FORMAT], true).isValid()) ? CHILD?.dates[index] : '';
    }),
  }));

  return fields;
};
