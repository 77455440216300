import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { capitalize } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import urlParser from 'js-video-url-parser';

import { isValidUrl } from '@ess/utils';

import useKeyPress from '@ess/hooks/useKeyPress';

import { Button } from '@ess/ui/Button';
import FlexBox from '@ess/ui/FlexBox';
import TextInput from '@ess/ui/Form/TextInput';
import Text from '@ess/ui/Text';

import { IAddMediaRequest, MediaType } from '../types';

import { ALLOWED_IMAGE_EXTENSIONS, ALLOWED_VIDEO_SERVICES } from '../constants';

type UrlMethodProps = {
  onApply: ({ itemType, data }: IAddMediaRequest) => void
}

const UrlMethod = ({ onApply }: UrlMethodProps) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [inputState, setInputState] = useState({
    value: { url: '', name: t('lbl_custom_slide') },
    error: '',
  });

  const enterPress = useKeyPress('Enter', inputRef.current);

  const onInputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, inputType: string) => {
    setInputState((state) => ({
      ...state,
      value: {
        ...state.value,
        [inputType]: event.target.value,
      },
      error: '',
    }));
  };

  const onClearHandler = (inputType: string) => {
    setInputState((state) => ({
      ...state,
      value: {
        ...state.value,
        [inputType]: '',
      },
      error: '',
    }));
  };

  const onApplyHandler = useCallback(() => {
    let mediaType: any;

    const data = {
      url: '',
      name: inputState.value.name,
    };

    if (!isValidUrl(inputState.value.url)) {
      setInputState((state) => ({
        ...state,
        error: t('lbl_provide_valid_url'),
      }));

      return;
    }

    const fileExtension = inputState.value.url.split('.').pop() as string;
    const parsedUrl = urlParser.parse(inputState.value.url);

    if (ALLOWED_IMAGE_EXTENSIONS.includes(`.${fileExtension}`)) {
      mediaType = MediaType.Image;
      data.url = inputState.value.url;
    }

    if (parsedUrl?.provider && ALLOWED_VIDEO_SERVICES.includes(parsedUrl.provider)) {
      const { id, provider } = parsedUrl;

      mediaType = MediaType.Video;
      data.url = `${provider}:${id}`;
    }

    if (mediaType && data) {
      onApply({
        itemType: mediaType,
        data,
      });
    } else {
      setInputState((state) => ({
        ...state,
        error: t('lbl_provide_valid_url'),
      }));
    }
  }, [inputState]);

  useEffect(() => {
    if (enterPress) {
      onApplyHandler();
    }
  }, [enterPress]);

  return (
    <FlexBox
      p="large"
      minHeight={220}
      width="100%"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <FlexBox
        mb="small"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Text mb="tiny">
          {t('lbl_add_media_url_description')}
        </Text>
        <Text>
          {`${t('lbl_allowed_video_services')}: ${ALLOWED_VIDEO_SERVICES.map((item) => capitalize(item)).join(' / ')}`}
        </Text>
      </FlexBox>
      <FlexBox mb="small" width="100%">
        <TextInput
          autoFocus
          ref={inputRef}
          placeholder={t('lbl_media_url')}
          value={inputState.value.url}
          onChange={(event) => onInputChangeHandler(event, 'url')}
          onClear={() => onClearHandler('url')}
          error={inputState.error}
          isClearable
        />
      </FlexBox>
      <FlexBox mb="medium" width="100%">
        <TextInput
          placeholder={t('lbl_media_name')}
          value={inputState.value.name}
          onChange={(event) => onInputChangeHandler(event, 'name')}
          onClear={() => onClearHandler('name')}
          isClearable
        />
      </FlexBox>
      <Button
        label={t('lbl_add')}
        size="large"
        onClick={onApplyHandler}
      />
    </FlexBox>
  );
};

export {
  UrlMethod,
};
