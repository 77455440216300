/**
 * Return string without html tags.
 */
export const clearHtmlTags = (htmlString: string | undefined): string => {
  if (!htmlString) {
    return '';
  }

  return htmlString.replace(/<\/?[^>]+(>|$)/g, '');
};
