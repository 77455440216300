import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { isEmpty } from 'lodash-es';

import { BASKET_ID } from '@basket/constants';

import TextInput from '@ess/ui/Form/TextInput';
import FlexBox from '@ess/ui/FlexBox';

import { useReactBasket } from '@basket/hooks/useReactBasket';
import useBasketListFilters from '@basket/hooks/useBasketListFilters';

import { FilterConditions, OrderTypes } from '@basket/types';

import DropDown from './DropDown';
import SmallSelectValues from './SmallSelectValues';

export const reduceValue = (value: any) => {
  const newValue = value;
  Object.keys(value).map((field:string) => {
    const fieldValue = value[field];
    if (isEmpty(fieldValue)) {
      delete newValue[field];
    }
  });
  return newValue;
};

const AutoSuggestBasketList = () => {
  const {
    basket,
    addBookmark,
    changeBasket,
  } = useReactBasket();

  const {
    basketListFilterValue,
    filterBasketList,
    onNameChangeHandler,
    basketsList,
    setBasketListState,
  } = useBasketListFilters();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputRef = useRef<any>(null);
  const { t } = useTranslation();

  const onInputClick = (event: any) => {
    event.stopPropagation();
    if (!isOpen) {
      filterBasketList({}, '' as OrderTypes, 'New');
    }
    setIsOpen(!isOpen);
  };

  const onChange = (selected: any) => {
    addBookmark(selected);
    onNameChangeHandler(selected?.label ?? '');
    changeBasket(selected?.value ?? null, selected?.label ?? null);
    setIsOpen(false);
  };

  const onInputChangeHandler = (event:any) => {
    const value = event?.target?.value ?? '';
    onNameChangeHandler(value);
    if (value.length >= 3) {
      filterBasketList(reduceValue({ ...basketListFilterValue.filters, name: value } as FilterConditions), basketListFilterValue?.orderBy ?? '' as OrderTypes, 'New');
    }
  };

  const onScrollEnd = () => {
    filterBasketList(reduceValue({ ...basketListFilterValue.filters } as FilterConditions), basketListFilterValue?.orderBy ?? '' as OrderTypes, 'More');
  };

  const onInputClear = () => {
    onNameChangeHandler('');
    filterBasketList(reduceValue({} as FilterConditions), '' as OrderTypes, 'New');
  };

  const getHeight = () => {
    const height = basketsList.length * 35;
    if (height > 400) {
      return 400;
    }
    return height;
  };

  return (
    <FlexBox width="100%" alignItems="center">
      <FlexBox width="100%">
        <TextInput
          onClick={onInputClick}
          ref={inputRef}
          isClearable
          startIcon={<FontAwesomeIcon icon={faSearch} color="darkGray"/>}
          value={isOpen ? basketListFilterValue?.filters?.name : basket?.current?.name}
          onChange={onInputChangeHandler}
          onClear={onInputClear}
          placeholder={t('lbl_search_basket')}
        />
      </FlexBox>
      <DropDown
        isOpen={isOpen}
        height={getHeight()}
        appendTo={document.getElementById(BASKET_ID)}
        setIsOpen={setIsOpen}
        width={270}
        inputRef={inputRef}
      >
        <SmallSelectValues
          onScrollEnd={onScrollEnd}
          setState={setBasketListState}
          basketsList={basketsList}
          onChange={onChange}
        />
      </DropDown>
    </FlexBox>
  );
};
export default AutoSuggestBasketList;
