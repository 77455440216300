import React from 'react';
import {
  Navigate, Route, Routes,
} from 'react-router-dom';

import { ProtectedRoute } from './ProtectedRoute';

import { routes } from './routes';

const RouterProvider = () => (
  <Routes>
    {routes.unAuthorized.map((route) => (
      <Route
        key={route.name}
        path={route.path}
        element={route.element}
      />
    ))}
    {routes.tourop.map((route) => (
      <Route
        key={route.name}
        path={route.path}
        element={route.element}
      />
    ))}
    <Route element={<ProtectedRoute/>}>
      {routes.authorized.map((route) => (
        <Route
          key={route.name}
          path={route.path}
          element={route.element}
        />
      ))}
      <Route path="*" element={<Navigate to="/trip/search/" />} />
    </Route>
  </Routes>
);

export {
  RouterProvider,
};
