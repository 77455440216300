import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Button } from '@ess/ui/Button';
import { Modal } from '@ess/ui/Modal/ModalsTypes';
import TextInput from '@ess/ui/Form/TextInput';
import FlexBox from '@ess/ui/FlexBox';

type CreateBasketModalProps = {
  onApply: (name: string) => void
  onClose: () => void
  appendTo?: HTMLElement | null
}

const CreateBasketModal = ({ onApply, onClose, appendTo = null }: CreateBasketModalProps) => {
  const { t } = useTranslation();
  const [basketName, setBasketName] = useState(
    `${t('lbl_basket_default_name')} - ${moment().format('DD.MM.YYYY HH:mm')}`,
  );

  const onApplyHandler = () => {
    onApply(basketName);
    onClose();
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBasketName(event.target.value);
  };

  return (
    <Modal
      appendTo={appendTo ?? document.querySelector('body')}
      title={t('lbl_create_new_basket')}
      isOpen
      style={{
        zIndex: 999999,
      }}
      onClose={onClose}
      controls={(
        <FlexBox p="small" width="100%" alignItems="center" justifyContent="flex-end">
          <Button
            variant="secondary"
            label={t('lbl_cancel')}
            size="small"
            mr="small"
            width="90px"
            onClick={onClose}
          />
          <Button
            variant="primary"
            label={t('lbl_apply')}
            size="small"
            width="90px"
            onClick={onApplyHandler}
            disabled={!basketName}
          />
        </FlexBox>
      )}
    >
      <FlexBox p="small" width="100%">
        <TextInput
          value={basketName}
          autoFocus
          autoSelect
          isClearable
          placeholder={t('lbl_provide_basket_name')}
          onChange={onChangeHandler}
        />
      </FlexBox>
    </Modal>
  );
};

export default CreateBasketModal;
