import {
  has, includes, isEmpty,
} from 'lodash-es';

import { objectToArray } from '@ess/utils';

import { REGION_LIST, SKI_REGION_LIST } from '@ess/constants/search';

import parseRegions from './parseRegions';

/**
* Compare values and return values
*/
const compare = (valueA: any, valueB: any, sortOrder: string) => {
  let valA = valueA;
  let valB = valueB;
  if (typeof valueA === 'string') {
    valA = valueA.toLocaleLowerCase();
    valB = valueB.toLocaleLowerCase();
    return sortOrder === 'desc' ? valA.localeCompare(valB) * -1 : valA.localeCompare(valB);
  }
  if (valA === valB) {
    return 0;
  }
  const sortKey = valA > valB ? 1 : -1;
  return sortOrder === 'desc' ? sortKey * -1 : sortKey;
};

/**
 * Returns sorted items.
 * @param items
 * @param sortKeyOrderAscending
 * @param isPriority
 */
export const getSortedItems = (items: any, sortKeyOrderAscending: boolean[], isPriority = false) => {
  const itemsArray = objectToArray(items);
  const itemWithOfferData = itemsArray.filter((item: any) => !isEmpty(item.offer));

  const order = sortKeyOrderAscending.map((item) => (item ? 'asc' : 'desc'));

  if (!isPriority) {
    return itemWithOfferData.sort((a, b) => (
      (compare(a.sortKeyValue[0], b.sortKeyValue[0], order[0])
      || compare(a.sortKeyValue[1], b.sortKeyValue[1], order[1]))
    ));
  }
  return itemWithOfferData.sort((a, b) => (
    order[0] === 'asc' ? a.sortKeyValue[0].localeCompare(b.sortKeyValue[0]) : b.sortKeyValue[0].localeCompare(a.sortKeyValue[0])
  ));
};

/**
 * Returns results items.
 * @param currentState
 * @param payload
 * @param item
 */
const getSearchResultsItem = (currentState: object, payload: any, item: string) => {
  const data = payload[item];
  const newState = !isEmpty(data)
    ? (includes([REGION_LIST, SKI_REGION_LIST], item))
      ? parseRegions({ [item]: data })
      : {
        list: getSortedItems(data.items, data.sortKeyOrderAscending),
        more: data.more,
      }
    : {};

  return has(payload, item) && !isEmpty(newState) ? newState : currentState;
};

export default getSearchResultsItem;
