import { useReactBasket } from '@basket/hooks/useReactBasket';

type UseReactBasketItemProps = {
  rowId: number
}

const useReactBasketItem = ({ rowId }: UseReactBasketItemProps) => {
  const { basket, checkOnline, checkBookingStatus } = useReactBasket();

  const checkItemStatus = () => {
    checkOnline(rowId);
  };

  const item = basket.items.data.find((item) => item.rowid === rowId);

  return {
    item,
    checkOnline: checkItemStatus,
    checkBookingStatus,
  };
};

export {
  useReactBasketItem,
};
