import { DisplaySection } from '../types';

import { FavouriteHotelsList } from '../components/FavouriteHotelsList';

export const favouriteHotels: DisplaySection = {
  display: {
    label: 'lbl_favourite_hotels',
    anchorDescription: 'show_favourite_hotels_list',
    clearDescription: 'lbl_reset_favourite_hotels',
    confirm: {
      title: 'lbl_reset_favourite_hotels',
      content: 'confirm_favourite_hotels_clear',
    },
    storage: {
      key: 'FavouriteHotels',
      defaultValue: [],
    },
    Component: FavouriteHotelsList,
  },
};
