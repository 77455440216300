import { has, startsWith } from 'lodash-es';

/**
 * Returns ungrouped operators list.
 */
export const getUngroupedOperators = (values: string[]): string[] => {
  const operatorGroups = window.dictionary ? window.dictionary['Base.Operator'] : undefined;

  let newValues: string[] = [];

  values.map((item) => {
    const gItem = startsWith(item, 'G_') ? item : `G_${item}`;
    const value = operatorGroups && has(operatorGroups, gItem) ? operatorGroups[gItem] : [item];
    newValues = [...newValues, ...value];
  });

  return [...new Set([...newValues])];
};
