import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import Select from '@ess/ui/Form/Select';

import { Setting } from '../../../types';

import { useAgentSettingsStore } from '../../../hooks';

type SelectSettingTypeProps = {
  data: Setting;
  name: string;
  section: string;
  onChange?: ({ name, value, section } : { name: string, value: any, section: string }) => void;
}

const SelectSetting = ({
  name,
  section,
  data,
  onChange = undefined,
}: SelectSettingTypeProps) => {
  const { t } = useTranslation();
  const { value, setSetting } = useAgentSettingsStore(useShallow((state) => ({
    setSetting: state.setSetting,
    value: state.values[section][name],
  })));

  const options = useMemo(() => data?.options?.map((option) => ({
    label: t(option.label),
    value: option.value,
  })), [data.options]);

  const onChangeHandler = (selectedOption: any) => {
    const value = selectedOption.value;
    setSetting(section, name, value);

    if (onChange) {
      onChange({ name, value, section });
    }
  };

  return (
    <Select
      name={name}
      onChange={onChangeHandler}
      options={options || []}
      value={value}
      menuPortalTarget={null}
      isClearable={false}
    />
  );
};

export {
  SelectSetting,
};
