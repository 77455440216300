/**
 * Returns operator group if exists.
 */

export const getOperatorGroup = (operator: string) => {
  const operatorGroups = window.dictionary ? window.dictionary['Base.Operator'] : undefined;

  let operatorGroup = '';

  Object.keys(operatorGroups).find((group) => {
    const item = operatorGroups[group];

    if (item.includes(operator)) {
      operatorGroup = group;
    }
  });

  return operatorGroup;
};
