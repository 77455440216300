import { isEmpty } from 'lodash-es';

import ProtoHash, { ProtoHashTypes } from '@ess/protohash';

import { getFieldsOfParticipantsWithDate } from '../form/getParticipantsWithDate';

/**
 * Returns validated formValues by given search proto hash.
 */
export const getValidatedSearchValues = (hash: string, withDefaults = true) => {
  const searchFormProtoInstance = new ProtoHash(ProtoHashTypes.SearchForm);

  if (hash) {
    const values = searchFormProtoInstance.decode(hash, withDefaults);
    const isValidValues = !isEmpty(values) && !Object.keys(values).some((item) => values[item] === null);

    if (isValidValues) {
      const { rooms } = values['Base.ParticipantsList'] ?? {};
      return {
        ...values,
        ...getFieldsOfParticipantsWithDate(rooms),
      };
    }
  }

  return false;
};
