import { get } from 'lodash-es';

/**
 * Returns parsed scheme.
 * @param scheme
 * @param data
 * @param t
 */
const parseScheme = (scheme: string, data: any, t: any) => {
  let parsedScheme = '';

  parsedScheme = scheme.replace(/(?<=%).+?(?=%)/g, ((item: any) => get(data, item, item))).replace(/%/g, '');
  parsedScheme = parsedScheme.replace(/(?<=\$).+?(?=\$)/g, ((item) => t(item))).replace(/\$/g, '');

  return parsedScheme;
}

export {
  parseScheme,
}
