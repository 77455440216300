import React, { Suspense } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { initializeTagManager } from '@ess/hooks/useGoogleTagManager';

import { GlobalStyle } from '@ess/global-styles/GlobalStyles';

import { AuthProvider } from '@ess/auth-provider';

import { LoginSettingsProvider } from '@merlin/context/LoginSettings';

import { RouterProvider } from './routes';

initializeTagManager();

const App = () => (
  <QueryParamProvider adapter={ReactRouter6Adapter}>
    <Suspense fallback={null}>
      <LoginSettingsProvider>
        <AuthProvider>
          <RouterProvider/>
        </AuthProvider>
      </LoginSettingsProvider>
    </Suspense>
    <GlobalStyle/>
  </QueryParamProvider>
);

export default App;
