import React from 'react';

import Text from '@ess/ui/Text';
import FlexBox from '@ess/ui/FlexBox';

type TechnicalIssueMessageProps = {
  title: string | React.ReactNode
  children: React.ReactNode
  button?: React.ReactNode
}

const defaultProps = {
  button: undefined,
};

const TechnicalIssueMessage = ({
  title,
  children,
  button,
}: TechnicalIssueMessageProps) => (
  <FlexBox
    p="medium"
    width="100%"
    height="100vh"
    justifyContent="center"
    alignItems="center"
    backgroundColor="#f7f7f7"
  >
    <FlexBox
      p="medium"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="620px"
      height="200px"
      mt="-80px"
      borderRadius={6}
      backgroundColor="white"
      style={{ boxShadow: 'rgb(0 0 0 / 10%) 0 1px 4px 0px' }}
    >
      <FlexBox>
        <Text
          mb="20px"
          fontSize="title"
          color="darkGray"
        >
          {title}
        </Text>
      </FlexBox>

      <Text
        mb="20px"
        fontSize="head"
        lineHeight="24px"
        textAlign="center"
        color="darkGray"
      >
        {children}
      </Text>
      {button && button}
    </FlexBox>
  </FlexBox>
);

TechnicalIssueMessage.defaultProps = defaultProps;

export default TechnicalIssueMessage;
