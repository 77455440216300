import styled from 'styled-components';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

const Modern__Offer = styled(FlexBox)`
  cursor: pointer;
  flex-direction: column;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 4px 0px;

  ${({ theme }) => `${theme.mediaQueries.sm} {
     flex-direction: column;
     height: auto;
  }`}
`;
const Content___Holder = styled(FlexBox)`
  flex-grow: 1;
  flex-direction: column;
  min-width: 0;

  ${({ theme }) => `${theme.mediaQueries.sm} {
      width: 100%;
  }`}
`;
const Name = styled.span`
font-weight: bold;
  &:hover{
    cursor: pointer;
    text-decoration: underline;
  }
`;
const Insurance__Date__Label = styled(Text)`
color: darkgreen;
  font-weight: bold;
`;
const Insurance__Name__Label = styled.span`
  cursor: pointer;
  transition: color 200ms;
&:hover{
  color: ${({ theme }) => theme.colors.orange};
}
`;
const Insurance__Details__Holder = styled(FlexBox)`
flex-direction: column;
  border-top: 1px solid #e2e6e8;
  padding: 10px 25px;
`;
const Insurance__Details__Title = styled(Text)`
font-size: 14px;
padding-bottom: 10px;
  font-weight: bold;
`;

export const Styled = {
  Insurance__Details__Title,
  Insurance__Details__Holder,
  Insurance__Name__Label,
  Insurance__Date__Label,
  Name,
  Modern__Offer,
  Content___Holder,
};
