import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import usePopperPositioning from '@ess/hooks/usePopperPositioning';

import useOnClickOutside from '@ess/hooks/useOnClickOutside';
import { Styled } from '../DesktopMenu.style';

import { Menu, MenuItem } from '../../types';

type SectionProps = {
  parentKey: string;
  data: Menu
  onMenuItemClick: any;
}

const Section = ({
  data,
  parentKey,
  onMenuItemClick,
}: SectionProps) => {
  const { t } = useTranslation();
  const targetElement = useRef<HTMLAnchorElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [visibleDropdown, setVisibleDropdown] = useState('');

  const { styles, attributes } = usePopperPositioning({
    targetElementRef: targetElement,
    popperElementRef: popperElement,
    zIndex: 999,
    offset: [0, 0],
  });

  const openDropdown = (event: React.MouseEvent<HTMLElement>) => {
    const dropdownId = event.currentTarget.id;
    setVisibleDropdown(`${dropdownId}-dropdown`);
  };

  const closeDropdown = () => {
    setVisibleDropdown('');
  };

  const onMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    const dropdownId = event.currentTarget.id;
    setVisibleDropdown(`${dropdownId}-dropdown`);
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    const relatedTarget = event.relatedTarget;

    // @ts-ignore
    if (!(relatedTarget instanceof Node && relatedTarget.id.includes(popperElement?.id))) {
      closeDropdown();
    }
  };

  const onItemClick = (event: React.MouseEvent, item: MenuItem) => {
    onMenuItemClick(event, item);
    setVisibleDropdown('');
  };

  useOnClickOutside(popperElement, closeDropdown, [], isMobile);

  // @ts-ignore
  return (
    <Styled.Menu__Item__Parent key={parentKey}>
      <Styled.Menu__Item
        ref={targetElement}
        id={parentKey}
        {...{
          ...isMobile ? {
            onClick: openDropdown,
          } : {
            onMouseEnter,
            onMouseLeave,
          },
        }}
      >
        <span>{t(data.label)}</span>
      </Styled.Menu__Item>
      {visibleDropdown === `${parentKey}-dropdown` && createPortal(
        <Styled.Menu__Dropdown
          ref={setPopperElement}
          id={`${parentKey}-dropdown`}
          style={styles.popper}
          onMouseLeave={onMouseLeave}
          {...attributes.popper}
        >
          {data.menuList?.length > 0 && data.menuList.map((item, index) => (
            <Styled.Menu__Item
              key={`${parentKey}-dropdown-item-${index}`}
              id={`${parentKey}-dropdown-item-${index}`}
              onClick={(event: React.MouseEvent) => onItemClick(event, item)}
            >
              <span>{t(item.label)}</span>
            </Styled.Menu__Item>
          ))}
        </Styled.Menu__Dropdown>,
        document.querySelector('body') as HTMLBodyElement,
      )}
    </Styled.Menu__Item__Parent>
  );
};

export {
  Section,
};
