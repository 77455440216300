import styled from 'styled-components';
import { rgba } from 'polished';

const Tab__Label = styled.span`
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    letter-spacing: 0.5px;

     &:after {
         content: '';
         position: absolute;
         bottom: 0;
         left: 0;
         right: 0;
         width: 0;
         height: 2.5px;
         border-radius: 4px;
         background-color: transparent;
         transition: all .5s ease-in;
     }
`;

const Tab__Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 25px;
`;

const Tab = styled.button<{ isDisabled?: boolean }>`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
    min-height: 45px;
    background-color: transparent;
    padding: 0 10px;
    border: 0;
    outline: 0;
    margin: 0;
    line-height: 1;
    color: ${({ theme }) => theme.colors.textColor};
    text-align: center;

    ${({ isDisabled, theme }) => (!isDisabled ? `
      cursor: pointer;

      &:hover {
         color: ${theme.colors.blue};
      }

      &[aria-selected="true"] {
        background-color: ${rgba(theme.colors.blue, 0.08)};
        color: ${theme.colors.blue};

        ${Tab__Label} {
          &:after {
            width: 100%;
            background-color: ${theme.colors.blue};
          }
        }
      }
    ` : `
      cursor: not-allowed;
      color: ${theme.colors.darkGray};
      background-color: #cecece;
    `)}

`;

const Tabs = styled.div`
  display: flex;
  width: 100%;
  overflow-x: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lighterGray};
`;

const TabPanel = styled.div`
   display: flex;
   flex-direction: column;

   &[hidden] {
      display: none;
   }
`;

export const Styled = {
  Tabs,
  Tab,
  Tab__Label,
  Tab__Icon,
  TabPanel,
};
