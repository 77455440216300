import React, { CSSProperties, forwardRef } from 'react';

import { Styled } from './Card.styles';

type Variants = 'outlined' | 'shadow';
type Orientation = 'vertical' | 'horizontal';

type CardProps = {
  children: React.ReactNode;
  onClick?: (event?: React.MouseEvent) => void;
  width?: any;
  height?: any;
  minHeight?: any;
  style?: CSSProperties;
  variant?: Variants;
  orientation?: Orientation;
}

const Card = forwardRef<HTMLDivElement, CardProps>(({
  children,
  width = undefined,
  height = 'auto',
  minHeight = undefined,
  onClick = undefined,
  style = {},
  variant = 'shadow' as Variants,
  orientation = 'horizontal' as Orientation,
}, ref) => (
  <Styled.Card
    ref={ref}
    variant={variant}
    height={height}
    minHeight={minHeight}
    width={width}
    onClick={onClick}
    orientation={orientation}
    style={style}
  >
    {children}
  </Styled.Card>
));

export {
  Card,
};
