import { IOfferBase } from '@ess/types';

export const getDestinationPath = (offerBase: IOfferBase | undefined) => {
  if (offerBase) {
    const path = [
      offerBase?.XCountry?.Name,
      offerBase?.XRegion?.Name,
    ];

    if (offerBase?.XRegion?.Name !== offerBase?.XCity?.Name) {
      path.push(offerBase?.XCity?.Name);
    }

    const nonEmptyValues = path.filter((item) => !!item);

    return nonEmptyValues.length ? nonEmptyValues.join(' / ') : '';
  }

  return '';
};
