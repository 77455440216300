import React, { useState } from 'react';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';

import useStorage from '@ess/hooks/useStorage';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Anchor from '@ess/ui/Anchor';
import { Button } from '@ess/ui/Button';
import { ConfirmModal } from '@ess/ui/Modal/ModalsTypes';

const DisplaySetting = (props: any) => {
  const { t } = useTranslation();
  const [isOpen, toggle] = useToggle(false);
  const [items, setItems] = useStorage('localStorage', props.storage.key, props.storage.defaultValue);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const isArrayItems = Array.isArray(items);
  const count = isArrayItems ? items.length : Object.keys(items).length;

  const onClearHandler = () => {
    setItems(props.storage.defaultValue);
  };

  const onClickHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    toggle();
  };

  return (
    <>
      <FlexBox p="medium" flexDirection="column">
        <Anchor
          onClick={onClickHandler}
          disabled={!count}
        >
          {t(props.anchorDescription, { count })}
        </Anchor>
        <FlexBox mt="medium" alignItems="center" justifyContent="space-between">
          <Text>{t(props.clearDescription)}</Text>
          <Button
            size="medium"
            variant="secondary"
            label={t('clear')}
            disabled={!count}
            onClick={() => setShowConfirmModal(true)}
          />
        </FlexBox>
      </FlexBox>
      <props.Component isOpen={isOpen} onClose={() => toggle(false)}/>
      {showConfirmModal && (
        <ConfirmModal
          isOpen
          title={t(props.confirm.title)}
          onConfirm={onClearHandler}
          onDiscard={() => setShowConfirmModal(false)}
          onClose={() => setShowConfirmModal(false)}
        >
          <Text py="medium" px="small">{t(props.confirm.content)}</Text>
        </ConfirmModal>
      )}
    </>
  );
};

export {
  DisplaySetting,
};
