import React, { useEffect, useMemo } from 'react';
import { routes } from '@merlin/routes';

// @ts-ignore
import { ReactComponent as Logo } from '@merlin/assets/logo_white.svg';

import { setCssVariables } from '@liveroom/utils/setCssVariables';

import { useNavigateWithParams } from '@ess/hooks/useNavigateWithParams';
import useBreakpoint from '@ess/hooks/useBreakpoint';

import { NotMobile, Mobile } from '@ess/ui/BreakPoints';

import { User } from '@merlin/components/User';

import { Menu } from './Menu';
import { HeaderIcons } from './HeaderIcons';
import { BreadCrumbs } from './BreadCrumbs';

import { Container } from '../Container';

import { Styled } from './Header.style';

const Header = () => {
  const breakPoint = useBreakpoint();
  const isNotTablet = ['md', 'lg', 'xl'].includes(breakPoint);
  const navigate = useNavigateWithParams();

  const redirectToHomePage = () => {
    navigate('/trip/search/', { include: ['sf'] });
  };

  useEffect(() => {
    const headerHeight = isNotTablet ? '88px' : '52px';

    setCssVariables({
      params: {
        headerHeight,
      },
    });
  }, [isNotTablet]);

  const breadCrumbs = useMemo(() => routes.authorized.filter((route) => route.breadCrumb), []);

  return (
    <Styled.Header>
      <Styled.Header__Top>
        <Container>
          <Styled.Header__Wrapper>
            <Mobile>
              <Menu view="mobile" />
            </Mobile>
            <Styled.Header__Start>
              <Styled.Header__Logo onClick={redirectToHomePage}>
                <Logo/>
              </Styled.Header__Logo>

              <NotMobile>
                <Styled.Header__DesktopMenu>
                  <Menu view="desktop" />
                </Styled.Header__DesktopMenu>
              </NotMobile>
            </Styled.Header__Start>

            <Styled.Header__End>
              <NotMobile>
                <HeaderIcons/>
              </NotMobile>
              <Styled.Header__User>
                <User displayedName={isNotTablet ? 'consultantCode' : undefined}/>
              </Styled.Header__User>
            </Styled.Header__End>
          </Styled.Header__Wrapper>
        </Container>
      </Styled.Header__Top>
      <NotMobile>
        <Styled.Header__Bottom>
          <Container>
            <BreadCrumbs routes={breadCrumbs}/>
          </Container>
        </Styled.Header__Bottom>
      </NotMobile>
    </Styled.Header>
  );
};

export {
  Header,
};
