import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { isEmpty, pick } from 'lodash-es';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { IDictionary } from '@ess/types';

import { DATE_REQUEST_FORMAT } from '@ess/constants/api';

import { BasketActionLevel, BasketActions, BasketView } from '@basket/types';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import { useReactBasket } from '@basket/hooks/useReactBasket';
import useFavourites from '@ess/hooks/useFavourites';

import Text from '@ess/ui/Text';
import FlexBox from '@ess/ui/FlexBox';
import Box from '@ess/ui/Box';
import Skeleton from '@ess/ui/Skeleton';
import { Card } from '@ess/ui/Card';

import { ScrollBar, useScrollBar } from '@ess/components/ScrollBar';

import { getCurrentLanguage } from '@ess/utils';

import { BasketItem } from './BasketItem';
import { Controls } from './Controls';
import { Header } from './Header';

const defaultItemsCount = {
  count: 25,
  offset: 0,
};

interface BasketListState {
  isLoading: boolean
  isLoadingMore: boolean
  isError: boolean
  isReady: boolean
  quantity: number
  data: any[]
  checked: number[]
  favourites: number[]
}

const BasketList = () => {
  const scrollRef = useRef<any>(null);
  const { t } = useTranslation();
  const {
    basketClient,
    changeView,
    blockBasket,
    unBlockBasket,
  } = useReactBasket();
  const { favourites, add, remove } = useFavourites<number>('Baskets');
  const { state: AppConfig } = useContext(AppConfigContext);
  const itemsCount = useRef(defaultItemsCount);
  const filters = useRef<IDictionary<any>>({
    name: '',
    consultant: '',
    dateTo: '',
    dateFrom: '',
  });

  const orderByValue = useRef('-modifyTime');

  const [items, setItems] = useState<BasketListState>({
    isLoading: false,
    isLoadingMore: false,
    isError: false,
    isReady: false,
    quantity: 0,
    data: [],
    checked: [],
    favourites: [],
  });

  const virtualizedList = useVirtualizer({
    count: items.data.length,
    getScrollElement: () => scrollRef.current,
    estimateSize: () => 58,
    gap: 8,
  });

  const virtualItems = virtualizedList.getVirtualItems();

  const onLoadMoreHandler = useCallback(() => {
    itemsCount.current = {
      ...itemsCount.current,
      offset: itemsCount.current.offset + defaultItemsCount.count,
    };

    fetchBaskets(true);
  }, [items.quantity]);

  useScrollBar(scrollRef.current, {
    onReachEnd: onLoadMoreHandler,
  });

  const basketActionHandler = (action: string, data: any) => {
    const actions: any = {
      createBasket: (data: any) => {
        const today = moment();

        if (data.status === 'OK' && data?.details?.id) {
          setItems((state) => ({
            ...state,
            data: [{
              id: data.details.id,
              hash: data.details.hash,
              name: data.name,
              lastModified: {
                date: today.format(DATE_REQUEST_FORMAT),
                time: today.format('HH:mm'),
              },
              count: 0,
              blocked: false,
              consultant: AppConfig?.agency.consultantCode,
            }, ...state.data],
          }));
        }

        scrollRef.current?.scrollToTop();
      },
      deleteBasket: (data: any) => {
        if (data.status === 'OK' && data?.basketId) {
          setItems((state) => ({
            ...state,
            data: [...state.data].filter((item) => item.id !== data.basketId),
          }));

          remove(data.basketId);
        }
      },
      deleteBaskets: (data: any) => {
        if (data.status === 'OK' && data?.basketId) {
          setItems((state) => ({
            ...state,
            data: [...state.data].filter((item) => !data.basketId.includes(item.id)),
            checked: [],
          }));
        }
      },
      blockBasket: (data: any) => {
        if (data.status === 'OK' && data?.basketId) {
          setItems((state) => ({
            ...state,
            data: [...state.data].map((item) => (item.id === data.basketId ? ({
              ...item,
              blocked: true,
            }) : item)),
          }));
        }
      },
      unBlockBasket: (data: any) => {
        if (data.status === 'OK' && data?.basketId) {
          setItems((state) => ({
            ...state,
            data: [...state.data].map((item) => (item.id === data.basketId ? ({
              ...item,
              blocked: false,
            }) : item)),
          }));
        }
      },
      changeBasketName: (data: any) => {
        if (data.status === 'OK' && data?.basketId) {
          setItems((state) => ({
            ...state,
            data: [...state.data].map((item) => (item.id === data.basketId ? ({
              ...item,
              name: data.name,
            }) : item)),
          }));
        }
      },
    };

    if (action in actions) {
      actions[action](data);
    }
  };

  const onBasketClickHandler = (basket: any) => {
    changeView(BasketView.Basket, basket);
  };

  const onLockClickHandler = async (basket: { id: number, name: string, blocked: boolean }) => {
    const isBlocked = !!basket.blocked;
    const request = isBlocked ? await unBlockBasket(basket.id) : await blockBasket(basket.id);

    basketActionHandler(isBlocked ? 'unBlockBasket' : 'blockBasket', request);
  };

  const onStarClickHandler = (isChecked: boolean, basket: { id: number, name: string }) => {
    if (isChecked) {
      add(basket.id);
    } else {
      remove(basket.id);
    }
  };

  const onCheckboxChangeHandler = (isChecked: boolean, basket: { id: number, name: string }) => {
    setItems((state: any) => ({
      ...state,
      checked: isChecked
        ? [...state.checked, basket.id]
        : [...state.checked].filter((item) => item !== basket.id),
    }));
  };

  const fetchBasketDetails = useCallback((basketId: number | null) => {
    if (!basketId) {
      return;
    }

    const basket = items.data.find((item) => item.id === basketId);

    if (basket && 'items' in basket) {
      return;
    }

    (async () => {
      try {
        const response = await basketClient({
          method: 'get',
          action: BasketActions.Details,
          level: BasketActionLevel.Basket,
          conditions: {
            agencyShare: 1,
            basketId,
          },
        });

        if (response?.status === 'OK' && response?.details) {
          const { details } = response;
          const selectedItems = details?.items?.map((item: any) => item.rowid) ?? [];

          setItems((state) => ({
            ...state,
            data: [...state.data].map((item) => (item.id === details.id ? ({
              ...item,
              selectedItems,
              items: details?.items,
            }) : item)),
          }));
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [items.data, filters]);

  const fetchBaskets = useCallback((isLoadingMore = false) => {
    const nonEmptyFilters = Object.keys(filters.current).filter((item) => !isEmpty(filters.current[item]));
    const requestFilters = pick(filters.current, nonEmptyFilters);

    setItems((state) => ({
      ...state,
      isError: false,
      ...isLoadingMore ? {
        isLoadingMore,
      } : {
        isLoading: true,
      },
    }));

    (async () => {
      try {
        const response = await basketClient({
          action: BasketActions.List,
          level: BasketActionLevel.Basket,
          conditions: {
            currency: 'PLN',
            Language: getCurrentLanguage(),
            agencyShare: 1,
            ...itemsCount.current,
            ...!requestFilters?.name ? {
              favoriteBasketIdList: favourites,
            } : {},
            ...!isEmpty(requestFilters) ? {
              filter: {
                mod: 'ON',
                ...requestFilters,
              },
            } : {},
            ...orderByValue.current ? {
              orderBy: orderByValue.current,
            } : {},
          },
        });

        if (response?.status === 'OK') {
          const { list } = response;
          const data = list?.basket ?? [];

          setItems((state) => ({
            ...state,
            data: isLoadingMore ? [...state.data, ...data] : data,
            quantity: list.quantity,
            isReady: true,
          }));
        }
      } catch (error) {
        setItems((state) => ({
          ...state,
          isError: true,
          isLoadingMore: false,
          isLoading: false,
        }));
      } finally {
        setItems((state) => ({
          ...state,
          isLoadingMore: false,
          isLoading: false,
        }));
      }
    })();
  }, [filters, favourites]);

  const onSearchHandler = useDebouncedCallback((name: string) => {
    itemsCount.current = defaultItemsCount;
    filters.current = {
      ...filters.current,
      name,
    };
    fetchBaskets(false);
  }, 400);

  const onFilter = (params: IDictionary<any>) => {
    itemsCount.current = defaultItemsCount;
    filters.current = {
      ...filters.current,
      ...params,
    };
    fetchBaskets(false);
  };

  const onSort = (value: string) => {
    itemsCount.current = defaultItemsCount;
    orderByValue.current = value;
    fetchBaskets(false);
  };

  const basketControls = (
    <Controls
      selectedBaskets={items.checked}
      onAction={basketActionHandler}
      onSearch={onSearchHandler}
      onFilter={onFilter}
      onSort={onSort}
    />
  );

  useEffect(() => {
    fetchBaskets();
  }, []);

  if (items.isLoading) {
    return (
      <FlexBox flexDirection="column" height="100%" width="100%">
        {basketControls}
        <Box p="small" bg="#e8ecee" minHeight="100%">
          <Box height={26}/>

          {Array.from({ length: 20 }).map((item, index) => (
            <FlexBox mb="8px" key={`basket-list-item-${index}`}>
              <Card
                height="58px"
                orientation="vertical"
                style={{
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0 1px 4px 0px',
                }}
              >
                <FlexBox alignItems="center" p="small" width="100%">
                  <Skeleton mr="tiny" bg="#e6e6e6" width="18px" height="18px" borderRadius="3px"/>
                  <Skeleton mr="tiny" bg="#e6e6e6" width="18px" height="18px" borderRadius="3px"/>
                  <Skeleton mr="small" bg="#e6e6e6" width="18px" height="18px" borderRadius="3px"/>

                  <Skeleton bg="#e6e6e6" width="40%" height="18px" borderRadius="3px"/>
                  <FlexBox ml="auto">
                    <Skeleton bg="#e6e6e6" height="30px" width="30px" borderRadius="100%"/>
                  </FlexBox>
                </FlexBox>
              </Card>
            </FlexBox>
          ))}
        </Box>
      </FlexBox>
    );
  }

  if (!items.isLoading && !items.data.length) {
    return (
      <FlexBox
        height="100%"
        flexDirection="column"
        backgroundColor="#e8ecee"
      >
        {basketControls}
        <FlexBox height="100%" width="100%" alignItems="center" justifyContent="center">
          <Text color="#84949f" mb="medium" fontSize="title">{t('no_results')}</Text>
        </FlexBox>
      </FlexBox>
    );
  }

  if (items.isError) {
    return (
      <FlexBox
        height="100%"
        flexDirection="column"
        backgroundColor="#e8ecee"
      >
        {basketControls}
        <FlexBox height="100%" width="100%" alignItems="center" justifyContent="center">
          <Text color="#84949f" mb="medium" fontSize="title">{t('lbl_basket_list_error')}</Text>
        </FlexBox>
      </FlexBox>
    );
  }

  return (
    <FlexBox
      height="100%"
      flexDirection="column"
      backgroundColor="#e8ecee"
      position="relative"
    >
      {basketControls}
      <Header/>
      <ScrollBar
        ref={scrollRef}
        width="100%"
        height="calc(100% - 90px)"
        style={{
          display: 'flex',
          position: 'absolute',
          top: 90,
          padding: '0 10px 10px 10px',
        }}
      >
        <Box style={{
          height: `${virtualizedList.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
        >
          {virtualItems.map((virtualItem) => {
            const index = virtualItem.index;
            const basket = items.data[index];

            return (
              <Box
                ref={virtualizedList.measureElement}
                key={virtualItem.key}
                data-index={virtualItem.index}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${virtualItem.start}px)`,
                }}
              >
                <BasketItem
                  key={basket.id}
                  data={basket}
                  isChecked={items.checked.includes(basket.id)}
                  isFavourite={favourites.includes(basket.id)}
                  onCheckboxChange={onCheckboxChangeHandler}
                  onStarClick={onStarClickHandler}
                  onLockClick={onLockClickHandler}
                  onDropdownOpen={fetchBasketDetails}
                  onNameClick={onBasketClickHandler}
                  onAction={basketActionHandler}
                />
              </Box>
            );
          })}
        </Box>
      </ScrollBar>
    </FlexBox>
  );
};

export {
  BasketList,
};
