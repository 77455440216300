import styled from 'styled-components';

const FavouriteHotels__Wrapper = styled.div`
  padding: ${({ theme }) => theme.space.small};
  min-height: 220px;
  background-color: #f5f5f5;
`;

const FavouriteHotels__Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  width: 100%;
`;

const FavouriteHotels__Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  width: 100%;
  color: ${({ theme }) => theme.colors.darkGray};
`;

const FavouriteHotels__Item = styled.div`
  position: relative;
  background-color: white;
  padding: ${({ theme }) => theme.space.small};
  padding-right: 30px;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 10%) 0 1px 4px 0px;
`;

const FavouriteHotels__Item__Name = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FavouriteHotels__Item__Region = styled.div`
  color: ${({ theme }) => theme.colors.textSecondaryColor};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FavouriteHotels__Item__RemoveIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0;
  top: 0;
  color: ${({ theme }) => theme.colors.textSecondaryColor};
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

export const Styled = {
  FavouriteHotels__Wrapper,
  FavouriteHotels__Loader,
  FavouriteHotels__Empty,
  FavouriteHotels__Item,
  FavouriteHotels__Item__Name,
  FavouriteHotels__Item__Region,
  FavouriteHotels__Item__RemoveIcon,
};
