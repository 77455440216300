import { forwardRef } from 'react';

import { getFirstLetters } from '@ess/utils';

import { Styled } from './Avatar.styles';

type AvatarProps = {
  img?: string;
  alt: string;
  size?: number | string;
  onClick?: () => void;
};

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(({
  alt,
  size = 40,
  onClick = undefined,
  img = undefined,
}, ref) => (
  <Styled.Avatar ref={ref} size={size} onClick={onClick}>
    {img ? (
      <Styled.Avatar__Img src={img} alt={alt} />
    ) : (
      <Styled.Avatar__Text>
        {getFirstLetters(alt, 2).toUpperCase()}
      </Styled.Avatar__Text>
    )}
  </Styled.Avatar>
),
);

export default Avatar;
