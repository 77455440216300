import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown, faSearch, faBoxOpen, faChartSimple, faGear,
} from '@fortawesome/pro-regular-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';

import { Styled } from '../MobileMenu.style';

import { Menu, MenuItem } from '../../types';

type SectionProps = {
  menu: Menu
  onClick: (event: React.MouseEvent, item: MenuItem) => void
}

const iconMap: Record<string, any> = {
  booksystem: faSearch,
  module: faBoxOpen,
  raport: faChartSimple,
  admin: faGear,
};

const Section = ({ menu, onClick }: SectionProps) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const menuSection = menu.label.split('_')[1];
  const icon = menuSection in iconMap ? iconMap[menuSection] : null;

  const toggleMenu = () => {
    setIsExpanded((state) => !state);
  };

  return (
    <Styled.MobileMenu__Section isExpanded={isExpanded}>
      <Styled.MobileMenu__Item onClick={toggleMenu}>
        {icon && (
          <Styled.MobileMenu__Item__Icon>
            <FontAwesomeIcon icon={icon} size="lg"/>
          </Styled.MobileMenu__Item__Icon>
        )}
        <span>{t(menu.label)}</span>
        <Styled.MobileMenu__Item__Expand>
          <FontAwesomeIcon icon={faChevronDown}/>
        </Styled.MobileMenu__Item__Expand>
      </Styled.MobileMenu__Item>
      <Styled.MobileMenu__Item__List>
        <AnimatePresence>
          {isExpanded && menu.menuList.map((item, index) => {
            const key = `menu-item-${index}`;

            return (
              <motion.div
                key={key}
                initial={{ height: 0 }}
                animate={{ height: 'auto' }}
                transition={{ duration: 0.25 }}
                exit={{ height: 0 }}
              >
                <Styled.MobileMenu__Item onClick={(event: React.MouseEvent) => onClick(event, item)}>
                  <span>{t(item.label)}</span>
                </Styled.MobileMenu__Item>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </Styled.MobileMenu__Item__List>
    </Styled.MobileMenu__Section>
  );
};

export {
  Section,
};
