import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '@ess/ui/Form/Select';

type SortProps = {
  onChange: (value: string) => void
}

const Sort = ({ onChange }: SortProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('-modifyTime');
  const onChangeHandler = (selectedOption: any) => {
    setValue(selectedOption?.value ?? null);
    onChange(selectedOption?.value ?? null);
  };

  const options = useMemo(() => [
    {
      label: t('lbl_sort_modification_date_asc'),
      value: 'modifyTime',
    },
    {
      label: t('lbl_sort_modification_date_desc'),
      value: '-modifyTime',
    },
    {
      label: t('lbl_sort_name_asc'),
      value: 'name',
    },
    {
      label: t('lbl_sort_name_desc'),
      value: '-name',
    },
    {
      label: t('lbl_sort_consultant_asc'),
      value: 'consultant',
    },
    {
      label: t('lbl_sort_consultant_desc'),
      value: '-consultant',
    },
  ], []);

  return (
    <Select
      value={value}
      options={options}
      onChange={onChangeHandler}
      isClearable={false}
    />
  );
};
export {
  Sort,
};
