import React, { useEffect } from 'react';
import loadable from '@loadable/component';

import { HelmetContainer } from '@merlin/containers';
import { useLayoutSettings } from '@merlin/context';

import { useNetworkStatus } from '@ess/v5-data-provider/components';

import NetworkStatus from '@tourop/components/NetworkStatus';

import { Layout } from '@ess/components/layout';

import { AgentSettingsDrawer } from '@ess/components/AgentSettings';

type PrintOffersLayoutProps = {
  children: React.ReactElement;
}

const Basket = loadable(() => import(
  /* webpackChunkName: "basket" */
  '@basket/components'
));

const PrintOffersLayout = ({ children }: PrintOffersLayoutProps) => {
  const fetchSettings = useLayoutSettings((state) => state.fetchSettings);

  useEffect(() => {
    (async () => {
      await fetchSettings();
    })();
  }, []);

  return (
    <>
      <Basket/>
      <AgentSettingsDrawer/>
      <NetworkStatus useNetworkStatusHook={useNetworkStatus}/>
      <HelmetContainer>
        <Layout>
          <Layout.Header/>
          <Layout.Main>
            {children}
          </Layout.Main>
        </Layout>
      </HelmetContainer>
    </>
  );
};

export {
  PrintOffersLayout,
};
