import React from 'react';

import Tooltip from '@ess/ui/Tooltip';
import FlexBox from '@ess/ui/FlexBox';
import Loader from '@ess/ui/Loader';
import Text from '@ess/ui/Text';

type BarStatusProps = {
  statusData: StatusData
  isLoading: boolean
}

type StatusData = {
  color: string
  type: string
  icon: React.FunctionComponent | null
  message: string
  description: string
}

const BarStatus = ({
  statusData,
  isLoading,
}: BarStatusProps) => (
  <Tooltip
    theme="bubble-wide"
    content={isLoading ? statusData.description : statusData.message}
  >
    <FlexBox
      width="100%"
      px="small"
      height="28px"
      minWidth="165px"
      alignItems="center"
      justifyContent="center"
      borderRadius={6}
      backgroundColor={isLoading ? 'lighterGray' : statusData.color}
    >
      {isLoading ? (
        <Loader type="dots" size="20px" color="darkGray"/>
      ) : (
        <>
          <Text fontSize="11px" color="white" letterSpacing="1px" mr="small" whiteSpace="nowrap">
            {statusData.description.toUpperCase()}
          </Text>
          {React.createElement(statusData.icon as any, { color: 'white' })}
        </>
      )}
    </FlexBox>
  </Tooltip>
);
export default BarStatus;
