import { isEqual } from 'lodash-es';

export const compareSelectedDeeply = <T=any>({ deepProps }:{deepProps:(keyof T)[]}) => {
  const deepPropsMap:{[index:string]:boolean} = {};
  deepProps.forEach((v) => { // @ts-ignore
    deepPropsMap[v] = true;
  });

  return (prevProps:T, nextProps:T):boolean => {
    // @ts-ignore
    const pkeys = Object.keys(prevProps);
    // @ts-ignore
    const nkeys = Object.keys(nextProps);
    if (nkeys.length !== pkeys.length) {
      return false;
    }

    for (let i = 0; i < pkeys.length; i++) {
      const name = pkeys[i];
      // @ts-ignore
      if (!Object.is(prevProps[name], nextProps[name])) {
        if (deepPropsMap[name]) {
          // @ts-ignore
          if (!isEqual(prevProps[name], nextProps[name])) {
            return false;
          }
        } else {
          return false;
        }
      }
    }
    return true;
  };
};
