import { includes, isArray, isEmpty } from 'lodash-es';

/**
 * Returns string as array.
 */
export const stringToArray = (value: string): string[] => {
  const isMultipleValue = includes(value, ',');

  return isArray(value) ? value : !isEmpty(value)
    ? isMultipleValue
      ? value.split(',')
      : [value]
    : [];
};
