import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import TextInput from '@ess/ui/Form/TextInput';

import { Styled } from '@tourop/components/MultiSelectV2/MultiSelectV2.styles';

type SearchInputProps = {
  onChange: (value: string) => void
}

const SearchInput = ({ onChange }: SearchInputProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const inputElement = useRef<HTMLDivElement>();
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onClearHandler = () => {
    setValue('');
  };

  useEffect(() => {
    onChange(value);
  }, [value]);

  useEffect(() => {
    if (inputElement !== undefined) {
      const focusOn = inputElement as any;
      if (!isMobile) {
        focusOn.current.focus();
      }
    }
  }, []);

  return (
    <Styled.MultiSelect__SearchInput isMobileOnly={isMobileOnly}>
      <TextInput
        ref={inputElement as any}
        value={value}
        onChange={onChangeHandler}
        onClear={onClearHandler}
        placeholder={t('lbl_search')}
        startIcon={<FontAwesomeIcon icon={faSearch}/>}
        isClearable
      />
    </Styled.MultiSelect__SearchInput>
  );
};

export default SearchInput;
