import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { showToast, TOAST_INFO } from '@ess/utils';

import { useReactBasket } from '@basket/hooks/useReactBasket';

import { ConfirmModal } from '@ess/ui/Modal/ModalsTypes';
import FlexBox from '@ess/ui/FlexBox';

import { EllipsisMenu } from '@ess/components/EllipsisMenu';
import CreateBasketModal from '@basket/components/CreateBasketModal';

const confirmModalDefault = {
  isOpen: false,
  title: '',
  message: '',
  onConfirm: () => {},
};

type BasketListActionsProps = {
  selectedBaskets: number[]
  onAction?: (action: string, data: any) => any
}

const BasketListActions = ({ selectedBaskets, onAction = undefined }: BasketListActionsProps) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(confirmModalDefault);

  const {
    createBasket,
    deleteBasket,
  } = useReactBasket();

  const options = [
    {
      value: 'createBasket',
      label: t('lbl_create_new_basket'),
      icon: <FontAwesomeIcon icon={faPlus}/>,
    },
    {
      value: 'deleteBaskets',
      label: t('lbl_delete_selected_baskets'),
      icon: <FontAwesomeIcon icon={faTrashCan}/>,
    },
  ];

  const onDiscardHandler = () => {
    setConfirmModal(confirmModalDefault);
  };

  /**
   * Confirm action handler.
   */
  const onConfirmHandler = () => {
    if (confirmModal?.onConfirm) {
      confirmModal.onConfirm();
      setConfirmModal(confirmModalDefault);
    }
  };

  const createBasketHandler = async (name: string) => {
    const response = await createBasket(name);

    if (onAction) {
      onAction('createBasket', response);
    }
  };

  const itemClickHandler = async (value: string) => {
    const actions: any = {
      createBasket: () => {
        setIsOpenModal(true);
      },
      deleteBaskets: async () => {
        if (!selectedBaskets.length) {
          showToast(TOAST_INFO, t('lbl_no_baskets_selected'));
          return;
        }

        await setConfirmModal({
          isOpen: true,
          title: t('lbl_delete_selected_baskets'),
          message: t('lbl_delete_selected_baskets_confirm'),
          onConfirm: async () => {
            const response = await deleteBasket(selectedBaskets);

            if (onAction) {
              onAction('deleteBaskets', response);
            }
          },
        });
      },
    };

    if (value in actions) {
      actions[value]();
    }
  };

  return (
    <>
      {confirmModal.isOpen && (
        <ConfirmModal
          isOpen
          title={confirmModal.title}
          onConfirm={onConfirmHandler}
          onClose={onDiscardHandler}
          onDiscard={onDiscardHandler}
        >
          <FlexBox p="small">
            {confirmModal.message}
          </FlexBox>
        </ConfirmModal>
      )}
      {isOpenModal && (
        <CreateBasketModal
          onApply={createBasketHandler}
          onClose={() => setIsOpenModal(false)}
        />
      )}
      <EllipsisMenu
        options={options}
        onItemClick={itemClickHandler}
      />
    </>
  );
};

export {
  BasketListActions,
};
