import { groupBy, map as lodashmap } from 'lodash-es';

interface clusterResults<T> {
  list: (T & {key:string}) [],
  gps?: { lat: number, lng: number },
  key: string,
}

export const cluster = <T>(api:any, map:any, offers: T[], [cx, cy]:[number, number], useRegion:boolean):
  clusterResults<T>[] => {
  try {
    if (!api || !map) {
      return [];
    }
    const overlay = new api.OverlayView();
    overlay.draw = () => {};
    overlay.setMap(map);
    const projection = overlay.getProjection();
    const basepos = projection.fromLatLngToDivPixel(new api.LatLng(0, 0));
    return Object.values(lodashmap(groupBy(lodashmap(offers, (offer: any) => {
      const [lat, lng] = offer?.offer?.Accommodation?.Location?.Coords ?? [];
      const pos = projection.fromLatLngToDivPixel(new api.LatLng(lat, lng));
      return {
        ...offer,
        xy: [
          parseInt((((pos.x - basepos.x) / cx) + 1000000).toString(), 10),
          parseInt((((pos.y - basepos.y) / cy) + 1000000).toString(), 10),
        ],
      };
    }), useRegion ? ['xy', 'regionId'] : 'xy'), (cluster: any[]) => {
      // const [blockX, blockY] = cluster[0].xy;
      // const { lat, lng } = projection.fromDivPixelToLatLng(new api.Point(
      //   ((((blockX - 1000000)) * cx)) + basepos.x,
      //   (((blockY - 1000000)) * cy) + basepos.y,
      // ));
      // const [x, y] = [lat(), lng()];
      const [x, y] = cluster[0].offer?.Accommodation?.Location?.Coords ?? [];
      return {
        key: cluster[0].xy.join(','),
        list: cluster,
        gps: { lat: x, lng: y },
      };
    }));
  } catch (error) {
    return [];
  }
};
