import React from 'react';

import { Styled } from './Container.style';

type ContainerProps = {
  children: React.ReactNode;
  width?: string;
}

const Container = ({ children, width = '' }: ContainerProps) => (
  <Styled.Container width={width}>
    {children}
  </Styled.Container>
);

export {
  Container,
};
