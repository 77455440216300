import { useContext } from 'react';

import { AppConfigContext } from './AppConfigContext';

const useAppConfig = () => {
  const context = useContext(AppConfigContext);

  if (!context) {
    throw new Error('useAppConfig hook must be used within AppConfigProvider');
  }

  return {
    ...context.state,
    dispatch: context.dispatch,
  };
};

export {
  useAppConfig,
};
