import React from 'react';
import { useMediaQuery } from 'react-responsive';

type BreakPointsProps = {
  children: React.ReactElement,
}

const MobileAndTablet = ({ children }: BreakPointsProps) => (useMediaQuery({ minWidth: 0, maxWidth: 991 }) ? children : null);
const Desktop = ({ children }: BreakPointsProps) => (useMediaQuery({ minWidth: 992 }) ? children : null);
const Tablet = ({ children }: BreakPointsProps) => (useMediaQuery({ minWidth: 768, maxWidth: 991 }) ? children : null);
const NotMobile = ({ children }: BreakPointsProps) => (useMediaQuery({ minWidth: 768 }) ? children : null);

const Mobile = ({ children }: BreakPointsProps) => (useMediaQuery({ minWidth: 0, maxWidth: 768 }) ? children : null);
const NotTablet = ({ children }: BreakPointsProps) => (useMediaQuery({ minWidth: 992 }) ? children : null);

const NotSmallDesktop = ({ children }: BreakPointsProps) => (useMediaQuery({ minWidth: 1200 }) ? children : null);

export {
  NotTablet,
  NotMobile,
  Mobile,
  Desktop,
  Tablet,
  MobileAndTablet,
  NotSmallDesktop,
};
