import moment from 'moment';
import { DATE_REQUEST_FORMAT } from '@ess/constants/api';

/**
 * Checks if date passed.
 */

type PastDateValidationArgs = {
  date: string
  relativeDate?: any
}

const pastDateValidation = ({ date, relativeDate = moment() }: PastDateValidationArgs) => {
  const value = moment(date, DATE_REQUEST_FORMAT, true);
  const isValidDate = date.length === 10 && value.isValid();
  const isPastDate = (
    isValidDate
      && relativeDate !== null
      && moment(value.format(DATE_REQUEST_FORMAT)).isBefore(relativeDate.format(DATE_REQUEST_FORMAT), 'day')
  );
  const parsedDate = isPastDate ? relativeDate : value;

  return {
    isValidDate,
    isPastDate,
    date: {
      moment: isValidDate ? parsedDate : null,
      string: isValidDate ? parsedDate.format(DATE_REQUEST_FORMAT) : '',
    },
  };
};

export {
  pastDateValidation,
};
