import React from 'react';
import { useTranslation } from 'react-i18next';

import { BOOKING_DETAILS_ENDPOINT } from '@ess/constants/api';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Anchor from '@ess/ui/Anchor';

type BookingIdProps = {
  id: string
  operator: string
}
const BookingId = ({ id, operator }: BookingIdProps) => {
  const { t } = useTranslation();

  const onClickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();

    const url = BOOKING_DETAILS_ENDPOINT
      .replace(':bookingId', id)
      .replace(':operator', operator);

    window.open(`/${url}`, '_blank');
  };

  return (
    <FlexBox>
      <Text mr="tiny">{`${t('lbl_booking_short')}:`}</Text>
      <Anchor
        onClick={onClickHandler}
        target="_blank"
        color="navy"
        fontWeight="bold"
      >
        {id}
      </Anchor>
    </FlexBox>
  );
};

export {
  BookingId,
};
