import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { escape } from 'lodash-es';

import { getUrlSearchParams, objectToURLParams } from '@ess/utils';

import { Modal } from '@ess/ui/Modal/ModalsTypes';
import { Button } from '@ess/ui/Button';
import FlexBox from '@ess/ui/FlexBox';
import TextInput from '@ess/ui/Form/TextInput';
import Checkbox from '@ess/ui/Form/Checkbox';

type CreateBookmarkProps = {
  onClose: () => void;
  onApply: (bookmarkName: string, createBasket: boolean) => void;
}

const CreateBookmark = ({ onClose, onApply }: CreateBookmarkProps) => {
  const { t } = useTranslation();
  const [bookmark, setBookmark] = useState({
    name: '',
    createBasket: false,
    error: false,
  });

  const onApplyHandler = () => {
    const { name, createBasket } = bookmark;
    const hash = btoa(escape(encodeURIComponent(bookmark.name)));

    if (!name) {
      setBookmark((state) => ({
        ...state,
        error: true,
      }));

      return;
    }

    onApply(name, createBasket);

    const { origin, pathname, search } = window.location;
    const params = objectToURLParams({
      ...getUrlSearchParams({ queryString: search }),
      _t: hash,
    });

    const url = `${origin}${pathname || ''}?${params}`;

    window.open(url, '_blank');
    onClose();
  };

  const onCheckboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBookmark((state) => ({
      ...state,
      createBasket: event.target.checked,
    }));
  };

  const onInputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBookmark((state) => ({
      ...state,
      name: event.target.value,
      error: !event.target.value,
    }));
  };

  const onInputClearHandler = () => {
    setBookmark((state) => ({
      ...state,
      name: '',
    }));
  };

  return (
    <Modal
      title={t('new_bookmark_title')}
      isOpen
      theme="white"
      style={{
        zIndex: 999999,
      }}
      onClose={onClose}
      controls={(
        <FlexBox p="small" width="100%" alignItems="center" justifyContent="flex-end">
          <Button
            variant="secondary"
            label={t('lbl_cancel')}
            size="small"
            mr="small"
            width="90px"
            onClick={onClose}
          />
          <Button
            variant="primary"
            label={t('lbl_add')}
            size="small"
            width="90px"
            onClick={onApplyHandler}
          />
        </FlexBox>
      )}
    >
      <FlexBox flexDirection="column" p="small" width="100%">
        <FlexBox mb="small">
          <TextInput
            value={bookmark.name}
            placeholder={t('lbl_name')}
            onChange={onInputChangeHandler}
            onClear={onInputClearHandler}
            error={bookmark.error ? t('requiredErrorMessage') : ''}
            isClearable
            autoFocus
          />
        </FlexBox>

        <Checkbox
          spacing="10px"
          checked={bookmark.createBasket}
          onChange={onCheckboxChangeHandler}
          label={t('lbl_bookmark_create_basket')}
        />
      </FlexBox>
    </Modal>
  );
};

export {
  CreateBookmark,
};
