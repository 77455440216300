import React from 'react';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import { includes } from 'lodash-es';

import { showToast, TOAST_INFO } from '@ess/utils';

import useBreakpoints from '@ess/hooks/useBreakpoint';

import { useAgentSettings } from '@ess/components/AgentSettings';

import FlexBox from '@ess/ui/FlexBox';
import { Button } from '@ess/ui/Button';
import Switch from '@ess/ui/Switch';
import Text from '@ess/ui/Text';

import TripRange from './TripRange';

interface IDateRange {
  startDate: any
  endDate: any
}

export interface IPreset {
  translationKey: string
  start: Moment
  end: Moment
}

type PresetsProps = {
  onDatesChange: ({ startDate, endDate }: IDateRange) => void
  presets: IPreset[]
  customSettings?: boolean
}

const defaultProps = {
  customSettings: false,
};

const Presets = ({ onDatesChange, presets, customSettings }: PresetsProps) => {
  const { t } = useTranslation();
  const breakpoint = useBreakpoints();
  const isMobile = includes(['xxs', 'xs', 'sm'], breakpoint);
  const { dateType, setSetting } = useAgentSettings((state) => ({
    dateType: state.values.searchForm.dateType,
    setSetting: state.setSetting,
  }));

  return (
    <FlexBox
      width="100%"
      px="large"
      py="small"
      alignItems="center"
      flexDirection={isMobile ? 'column' : 'row'}
      style={{
        borderTop: '1px solid #dedede',
      }}
    >
      <FlexBox
        justifyContent={isMobile ? 'center' : 'default'}
        alignItems="center"
        style={{ gap: '10px' }}
      >
        {presets.map(({ translationKey, start, end }) => (
          <Button
            key={translationKey}
            size="small"
            label={t(translationKey)}
            onClick={() => onDatesChange({ startDate: start, endDate: end })}
          />
        ))}
        {customSettings && (
          <FlexBox alignItems="center">
            <TripRange name="Custom.TripRange"/>
          </FlexBox>
        )}
      </FlexBox>
      {customSettings && (
        <FlexBox
          width={isMobile ? '100%' : 'auto'}
          m="auto"
          mt={isMobile ? '10px' : 'auto'}
          flexGrow={1}
          justifyContent={isMobile ? 'center' : 'flex-end'}
          alignItems="center"
        >
          <FlexBox style={{ gap: '10px' }}>
            <Text>
              {`${t('lbl_date_picker_switch')} (${includes(dateType, 'returnDateTo')
                ? t('lbl_stay_date_range')
                : t('lbl_filters_startdate')})`}
            </Text>
            <Switch
              checked={includes(dateType, 'returnDateTo')}
              onChange={(event) => {
                setSetting('searchForm', 'dateType', event.target.checked
                  ? 'Custom.StartDateRange.returnDateTo'
                  : 'Custom.StartDateRange.startDateTo');

                showToast(TOAST_INFO, t('local_settings_saved'));
              }}
            />
          </FlexBox>
        </FlexBox>
      )}
    </FlexBox>
  );
};

Presets.defaultProps = defaultProps;

export default Presets;
