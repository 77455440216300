import styled from 'styled-components';

const ShareInput = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  background-color: #fafafa;
  padding-left: 10px;
  padding-right: 5px;
  border: 1px solid #dedede;
`;

const ShareInput__Inner = styled.div`
  width: calc(100% - 30px);
  padding-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  & > a {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const ShareInput__CopyButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  background-color: #1a9fff;
  border-radius: 6px;
  color: #fff;
  justify-content: center;
  height: 30px;
  width: 30px;
`;

const ShareInput__CopyButton__Icon = styled.div`

`;

export const Styled = {
  ShareInput,
  ShareInput__Inner,
  ShareInput__CopyButton,
  ShareInput__CopyButton__Icon,
};
