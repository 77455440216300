import styled from 'styled-components';

const HotelAttribute = styled.div<{ isHighlighted: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 15px;
    font-size: 14px;
    border: ${({ isHighlighted }) => (isHighlighted ? '2px solid #102c58' : '1px solid rgb(204,204,204)')};
`;

const RestHotelAttribute = styled.div<{ isHighlighted: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 15px;
    font-size: 14px;
    border: ${({ isHighlighted }) => (isHighlighted ? '2px solid white' : '1px solid rgb(204,204,204)')};
`;

const HotelAttributesGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -1.5px;

    ${HotelAttribute} {
        margin: 1.5px;
    }
`;

export const Styled = {
  HotelAttribute,
  RestHotelAttribute,
  HotelAttributesGroup,
};
