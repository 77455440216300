export default {
  fieldsSchemaMap: {
    'Custom.StartDateRange.StartDateFrom': 'Base.StartDate.After',
    'Custom.StartDateRange.StartDateTo': 'Base.StartDate.Before',
    'Custom.StartDateRange.ReturnDateTo': 'Base.ReturnDate.Before',
    'Custom.PriceRange': 'Base.Price',
    'Custom.Flight.Stops': 'Transport.Flight.Stops',
    'Custom.Availability': 'Base.Availability',
    'Custom.HotelName': 'Accommodation.XCode',
    'Base.StartDateDayOfWeek': 'Base.StartDate.DayOfWeek',
    'Custom.ComponentsCombinations': 'Base.ComponentsCombinations',
    'Base.DestinationLocation.Region': 'Base.DestinationLocation.Id',
    'Base.DestinationLocation': 'Base.DestinationLocation.Id',
    'Base.DepartureLocation': 'Base.DepartureLocation.Id',
  },
};
