import React, { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { set } from 'lodash-es';
import * as Sentry from '@sentry/react';

import 'react-toastify/dist/ReactToastify.min.css';

import { theme } from '@tourop/config/theme';

import FallbackComponent from '@tourop/errorBoundaries/GeneralErrorSentryBoundary';
import registerServiceWorker from './serviceWorkerRegistration';

import App from './App';

import './sentry';

set(window, 'GlobalVariables.isBasketGoOn', true);

const root = createRoot(
  document.getElementById('react-root') as HTMLElement,
);

root.render(
  <Sentry.ErrorBoundary fallback={<FallbackComponent/>}>
    <Suspense fallback={null}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <ToastContainer />
      </ThemeProvider>
    </Suspense>
  </Sentry.ErrorBoundary>,
);

registerServiceWorker();
