import moment from 'moment';

import { DATE_REQUEST_FORMAT } from '@ess/constants/api';
import { OWN_TRANSPORT, TRANSPORT_FLIGHT } from '@ess/constants/transport';

import { getTransportDesc, getTransportType } from '@ess/utils';

export const defaultItems = [
  {
    name: 'custom',
    scheme: (_: any, t: any) => {
      const localStorageItem = localStorage.getItem('MXSocialPostCreator');

      if (localStorageItem) {
        return `👌 ${localStorageItem}`;
      }

      return `👌 ${t('basket_clipboard_default_text')}`;
    },
    icon: '👌',
    type: 'text',
    editable: true,
    enabled: true,
    selected: true,
    id: 1,
  },
  {
    name: 'destination',
    scheme: '🌍 %Base.DestinationLocation.Name%',
    type: 'text',
    enabled: true,
    selected: true,
    id: 2,
  },
  {
    name: 'object',
    scheme: (offer: any, t: any) => {
      const { Transport } = offer;
      const transportType = getTransportType(Transport);
      const hasAccommodation = 'Accommodation' in offer;
      const category = hasAccommodation && offer.Accommodation.Category
        ? Array.from({ length: offer.Accommodation.Category }).map(() => '⭐').join('')
        : null;

      const icon = hasAccommodation ? '🏨' : transportType === TRANSPORT_FLIGHT ? '✈️' : '🚌';

      return `${icon} ${hasAccommodation ? `${t('hotel')}:` : ``} ${offer?.Base?.XCode?.Name} ${category ? category : ''}`;
    },
    type: 'text',
    enabled: true,
    selected: true,
    id: 3,
  },
  {
    name: 'term',
    scheme: (offer: any, t: any) => {
      const startDate = moment(offer.Base.StartDate, DATE_REQUEST_FORMAT).format('DD.MM')
      const endDate = moment(offer.Base.ReturnDate, DATE_REQUEST_FORMAT).format('DD.MM.YYYY')

      return `📆 ${t('term')} ${startDate} - ${endDate}, ${offer.Base.NightsBeforeReturn} ${t('lbl_nights')}`;
    },
    type: 'text',
    enabled: true,
    selected: true,
    id: 4,
  },
  {
    name: 'transport',
    scheme: (offer: any, t: any) => {
      let scheme = '';
      const { Transport } = offer;
      const transportType = getTransportType(Transport);
      const { Out: transportOutSegments = [], Ret: transportRetSegments = [] } = transportType !== OWN_TRANSPORT
        ? getTransportDesc(Transport)
        : {};

      if (transportType === OWN_TRANSPORT) {
        return scheme;
      }

      const icon = transportType === TRANSPORT_FLIGHT ? '✈️' : '🚌';

      if (transportOutSegments.length) {
        const { Departure, Destination } = transportOutSegments[0];
        scheme += `${icon} ${Departure.Name} - ${Destination.Name} ${moment(Departure.Date, DATE_REQUEST_FORMAT).format('DD.MM')} ${Departure.Time} - ${Destination.Time}`;
      }

      if (transportRetSegments.length) {
        const { Departure, Destination } = transportRetSegments[0];
        scheme += `\n${icon} ${Departure.Name} - ${Destination.Name} ${moment(Departure.Date, DATE_REQUEST_FORMAT).format('DD.MM')} ${Departure.Time} - ${Destination.Time}`;
      }

      return scheme;
    },
    type: 'text',
    enabled: (offer: any) => {
      const transportType = getTransportType(offer.Transport);
      return transportType !== OWN_TRANSPORT;
    },
    selected: true,
    id: 5,
  },
  {
    name: 'service',
    scheme: '🍴 $lbl_service$: %Accommodation.Service.Name%',
    type: 'text',
    enabled: true,
    selected: true,
    id: 6,
  },
  {
    name: 'operator',
    scheme: '©️ $operator$: %Base.OperatorDesc%',
    enabled: true,
    selected: true,
    id: 7,
  },
  {
    name: 'totalPrice',
    scheme: '💰 $lbl_totalprice$: %Base.Price.Total.amount% %Base.Price.Total.currency%',
    type: 'text',
    enabled: true,
    selected: true,
    id: 8,
  },
  {
    name: 'personPrice',
    scheme: '💰 $lbl_price$: %Base.Price.FirstPerson.amount% %Base.Price.FirstPerson.currency%',
    type: 'text',
    enabled: true,
    selected: true,
    id: 9,
  },
  {
    name: 'summaryPrice',
    scheme: (offer: any, t: any) => {
      const details = offer.Base.Price.Details.Participants;

      if (!details.length) {
        return '';
      }

      const persons = details.map((person: any) => {
        return `${t(`lbl_${person.code.toLowerCase()}`)} ${person.amount} ${person.currency}`
      });

      return `💰 ${t(`lbl_menu_pricelist`)}: ${persons.join(', ')}`
    },
    type: 'text',
    enabled: (offer: any) => {
      return offer.Base?.Price?.Details?.Participants?.length;
    },
    selected: true,
    id: 10,
  },
  {
    name: 'rating',
    scheme: (offer: any, t: any) => {
      const { Accommodation = null } = offer;
      const tripAdvisor = Accommodation?.ExtTripAdvisor;

      if (tripAdvisor && tripAdvisor?.rating) {
        return `ℹ️ ${t('lbl_rating')}: ${tripAdvisor.rating}, ${t('reviews_count')}: ${tripAdvisor.count}`;
      }

      return ``
    },
    type: 'text',
    enabled: (offer: any) => {
      return offer.Accommodation?.ExtTripAdvisor?.rating;
    },
    selected: true,
    id: 11,
  },
  {
    name: 'attributes',
    scheme: (offer: any) => {
      const attributes = offer.Accommodation.Attributes;
      const dictionary = window.dictionary['Accommodation.Attributes'];

      if (attributes.length) {
        return `ℹ️ ${attributes.map((item: string) => item in dictionary ? dictionary[item]?.text ?? item : item).join(', ')}`;
      }

      return '';
    },
    type: 'text',
    enabled: (offer: any) => {
      return offer?.Accommodation?.Attributes?.length;
    },
    selected: true,
    id: 12,
  },
  {
    name: 'images',
    scheme: '',
    type: 'image',
    images: [],
    editable: true,
    enabled: true,
    selected: true,
    id: 13,
  },
  {
    name: 'offerUrl',
    scheme: `%OfferLink%`,
    type: 'url',
    enabled: (OfferLink: string) => {
      return OfferLink?.length ?? 0;
    },
    selected: true,
    id: 14,
  },
  {
    name: 'address',
    scheme: `🏨 %Agency.name% \n🏨 %Agency.address.street% \n✉️ %Agency.address.zipCode% %Agency.address.city% \n☎️ %Agency.contact.tel% %Agency.contact.email%
    `,
    type: 'text',
    enabled: true,
    selected: true,
    id: 15,
  },
]
