import React from 'react';

import { Section } from './Section';

import { Styled } from './DesktopMenu.style';

import { Menu, MenuItem } from '../types';

type MenuProps = {
  menu: Menu[];
  onMenuItemClick: (event: React.MouseEvent, item: MenuItem) => void;
}

const DesktopMenu = ({ menu, onMenuItemClick }: MenuProps) => (
  <Styled.MenuContainer>
    {menu.map((parent, parentIndex) => {
      const parentKey = `parent-${parentIndex}`;

      return (
        <Section
          key={parentKey}
          parentKey={parentKey}
          data={parent}
          onMenuItemClick={onMenuItemClick}
        />
      );
    })}
  </Styled.MenuContainer>
);

export {
  DesktopMenu,
};
