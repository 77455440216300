export const getFirstLetters = (string: string, limit = 0) => {
  if (!string) {
    return '';
  }

  let words = string.split(' ');

  if (limit) {
    words = words.slice(0, limit);
  }

  return words.map((word) => word.charAt(0)).join('');
};
