import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

const BreadCrumbs = styled.div`
    display: flex;
    align-items: center;
    height: var(--breadcrumbs-height);
    width: 100%;
`;

const BreadCrumbs__Container = styled.div`
  display: flex;
`;

const BreadCrumbs__Item = styled.div`
    font-size: 12px;

    &:not(:last-child):after {
      content: '/';
      padding: 10px;
      text-decoration: none;
      color: ${({ theme }) => theme.colors.gray};
    }
`;

const BreadCrumbs__Link = styled(NavLink)`
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.green};
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.orange};
    }
`;

const BreadCrumbs__Text = styled.div`
    color: ${({ theme }) => theme.colors.textColor};
`;

export const Styled = {
  BreadCrumbs,
  BreadCrumbs__Container,
  BreadCrumbs__Item,
  BreadCrumbs__Link,
  BreadCrumbs__Text,
};
