import styled from 'styled-components';

const Layout = styled.div<{ isFullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Styled = {
  Layout,
};
