import React from 'react';
import { useShallow } from 'zustand/react/shallow';

import Switch from '@ess/ui/Switch';

import { useAgentSettingsStore } from '../../../hooks';

type SwitchSettingTypeProps = {
  name: string;
  section: string;
  onChange?: ({ name, value, section } : { name: string, value: any, section: string }) => void;
}

const SwitchSetting = ({ name, section, onChange = undefined }: SwitchSettingTypeProps) => {
  const { value, setSetting } = useAgentSettingsStore(useShallow((state) => ({
    setSetting: state.setSetting,
    value: state.values[section][name],
  })));

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSetting(section, name, event.target.checked);

    if (onChange) {
      onChange({ name, value: event.target.checked, section });
    }
  };

  return (
    <Switch name={name} onChange={onChangeHandler} checked={value}/>
  );
};

export {
  SwitchSetting,
};
