import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';

import { useReactBasket } from '@basket/hooks/useReactBasket';

import { IconButton } from '@ess/ui/Button';
import { ConfirmModal } from '@ess/ui/Modal/ModalsTypes';
import FlexBox from '@ess/ui/FlexBox';

const FeaturedActions = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    deleteItem,
    basket,
  } = useReactBasket();

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  /**
   * Delete selected items handler.
   */
  const deleteCheckedOffers = () => {
    onCloseModal();
    deleteItem(basket.selectedItems);
  };

  return (
    <>
      <FlexBox>
        <IconButton
          size="small"
          label={t('lbl_delete_checked_offers')}
          icon={<FontAwesomeIcon icon={faTrashCan}/>}
          variant="secondary"
          disabled={!basket.selectedItems.length}
          onClick={() => {
            setIsModalOpen(true);
          }}
        />
      </FlexBox>
      {isModalOpen && (
        <ConfirmModal
          isOpen
          title={t('lbl_delete_checked_offers')}
          onConfirm={deleteCheckedOffers}
          onClose={onCloseModal}
          onDiscard={onCloseModal}
        >
          <FlexBox p="small">
            {t('lbl_delete_basket_offers_confirm')}
          </FlexBox>
        </ConfirmModal>
      )}
    </>
  );
};

export default FeaturedActions;
