import styled from 'styled-components';

const User = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const User__DropdownHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space.small};
  border-bottom: 1px solid ${({ theme }) => theme.colors.separator};
`;

const User__Data = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.space.small};
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  line-height: 16px;
`;

const User__Data__Name = styled.div`
  font-weight: bold;
`;

const User__Data__Agency = styled.div`
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.lightBlue};
`;

export const Styled = {
  User,
  User__Data,
  User__Data__Name,
  User__Data__Agency,
  User__DropdownHeader,
};
