import React from 'react';

import { Styled } from './Main.style';

type MainProps = {
  children: React.ReactNode
}

const Main = ({ children }: MainProps) => (
  <Styled.Main>
    {children}
  </Styled.Main>
);

export {
  Main,
};
