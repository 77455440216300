import { useReactBasket } from '@basket/hooks/useReactBasket';

import { Styled } from './BasketCount.style';

const BasketCount = () => {
  const { basket } = useReactBasket();
  return (
    <>
      {basket?.count > 0 ? (
        <Styled.BasketCountBadge>
          <span>{basket.count}</span>
        </Styled.BasketCountBadge>
      ) : null}
    </>
  );
};

export {
  BasketCount,
};
