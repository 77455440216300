import React, { useEffect } from 'react';

import { useSelector } from '@ess/store/core';

import { useReactBasket } from '@basket/hooks/useReactBasket';

import { useColumnsManager } from './ColumnsManager/provider/useColumnsManager';

import { Header } from './Header';
import { Main } from './Main';
import { Container } from './Container';
import { ColumnsManager } from './ColumnsManager';

import { Styled } from './Layout.style';

type LayoutProps = {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const toggleElement = useColumnsManager((state) => state.toggleElement);
  const moveElement = useColumnsManager((state) => state.moveElement);
  const showSearchFormMap = useSelector((state) => state.base.showMap);
  const { basket } = useReactBasket();

  useEffect(() => {
    toggleElement('map', showSearchFormMap);
  }, [showSearchFormMap]);

  useEffect(() => {
    if (window?.GlobalVariables?.isBasketGoOn) {
      toggleElement('basket', basket.isOpen);
    }
  }, [basket.isOpen]);

  useEffect(() => {
    if (window?.GlobalVariables?.isBasketGoOn && basket?.position) {
      moveElement('basket', ['top', 'bottom'].includes(basket.position) ? 'outside' : basket.position);
    }
  }, [basket.position]);

  return (
    <Styled.Layout>
      {children}
    </Styled.Layout>
  );
};

Layout.Header = Header;
Layout.Main = Main;
Layout.Container = Container;
Layout.ColumnsManager = ColumnsManager;

export {
  Layout,
};
