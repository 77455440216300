import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import usePopperPositioning from '@ess/hooks/usePopperPositioning';
import { theme } from '@tourop/config/theme';
import useOnClickOutside from '@ess/hooks/useOnClickOutside';
import { Styled } from './AutoSuggestBasketList.styles';

type DropDownProps = {
  children?: React.ReactElement,
  appendTo?: any
  width: number
  height: number
  isOpen: boolean
  setIsOpen: any
  inputRef: any
}

const DropDown = ({
  height,
  isOpen,
  setIsOpen,
  children,
  appendTo,
  width,
  inputRef,
}: DropDownProps,
) => {
  const [modalRef, setModalRef] = useState<HTMLInputElement | null>(null);

  const { styles, attributes } = usePopperPositioning({
    targetElementRef: inputRef as any,
    popperElementRef: modalRef,
    zIndex: theme.zIndex.modal,
    applyMaxSizeEnabled: true,
  }) as any;

  useOnClickOutside(modalRef, () => setIsOpen(false));

  return (
    <>
      {createPortal(
        <>
          {isOpen && (
          <Styled.AutoSuggestDropDown
            ref={setModalRef}
            width={width}
            height={height}
            style={styles.popper}
            {...attributes.popper}
          >
            {children}
          </Styled.AutoSuggestDropDown>
          )}
        </>,
        (!appendTo ? document.querySelector('body') : appendTo) as HTMLElement,
      )}
    </>
  );
};
export default DropDown;
