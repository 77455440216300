import { searchForm } from './searchForm';
import { searchResults } from './searchResults';
import { basket } from './basket';
import { favouriteHotels } from './favouriteHotels';
import { prioritizedOperators } from './prioritizedOperators';
import { createDetailsPageSettings } from './detailsPage';

export const createConfig = (sections?: Record<string, boolean>) => ({
  searchForm,
  searchResults,
  favouriteHotels,
  prioritizedOperators,
  basket,
  detailsPage: createDetailsPageSettings(sections),
});
