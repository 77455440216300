import styled from 'styled-components';

const PrioritizedOperators__Wrapper = styled.div`
  padding: ${({ theme }) => theme.space.small};
  min-height: 250px;
  background-color: #f5f5f5;
`;

const PrioritizedOperators__SearchInput = styled.div`
  margin-right: 0;
  margin-bottom: ${({ theme }) => theme.space.medium};
  max-width: 350px;
`;

const PrioritizedOperators__Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 230px;
  width: 100%;
  color: ${({ theme }) => theme.colors.darkGray};
`;

const PrioritizedOperators__Item = styled.div`
  position: relative;
  background-color: white;
  padding: ${({ theme }) => theme.space.small};
  padding-right: 30px;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 10%) 0 1px 4px 0px;
`;

const PrioritizedOperators__Item__Name = styled.div`
  font-size: 14px;
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const PrioritizedOperators__Item__RemoveIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0;
  top: 0;
  color: ${({ theme }) => theme.colors.textSecondaryColor};
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

export const Styled = {
  PrioritizedOperators__Wrapper,
  PrioritizedOperators__SearchInput,
  PrioritizedOperators__Empty,
  PrioritizedOperators__Item,
  PrioritizedOperators__Item__Name,
  PrioritizedOperators__Item__RemoveIcon,
};
