import { has } from 'lodash-es';

import { IDictionary, IOption, IDictionaryItem } from '@ess/types';

type ComponentsCombinationsArgs = {
  values: any,
  dictionary: IDictionary<IDictionaryItem>
}

/**
 * Returns {transport | object} options from Base.ComponentsCombinations search base field.
 * Functions requires values dictionary.
 */
export const getComponentsCombinationsOptions = ({ values, dictionary }: ComponentsCombinationsArgs): IOption[] => {
  const options = [];

  if (values && dictionary) {
    for (let i = 0; i < values.length; i++) {
      const item = values[i];
      const value = item.join(',');

      if (has(dictionary, value)) {
        options.push({
          label: dictionary[value].text,
          value,
          ...dictionary[value]?.sortId ? {
            sortId: dictionary[value].sortId,
          } : {},
        });
      }
    }
  }

  return options;
};
