export enum MediaType {
  Image = 'image',
  Video = 'video',
}

export enum AddMediaMethod {
  File = 'file',
  Url = 'url',
}

export interface IAddMediaRequest {
  itemType: MediaType
  data: any
}
