import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faListUl } from '@fortawesome/pro-solid-svg-icons';

import { BasketView } from '@basket/types';

import { useReactBasket } from '@basket/hooks/useReactBasket';

import { Button } from '@ess/ui/Button';

const ViewSwitcher = () => {
  const { t } = useTranslation();
  const { changeView, basket } = useReactBasket();
  const onViewChange = (event: React.MouseEvent) => {
    event.stopPropagation();
    const view = basket.view === BasketView.Basket
      ? BasketView.BasketList
      : BasketView.Basket;

    changeView(view);
  };

  return (
    <>
      {basket.view === BasketView.Basket ? (
        <Button
          label={t('lbl_baskets_list')}
          startIcon={<FontAwesomeIcon icon={faListUl}/>}
          size="small"
          onClick={onViewChange}
        />
      ) : (
        <Button
          label={t('lbl_back')}
          startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}
          size="small"
          onClick={onViewChange}
        />
      )}
    </>
  );
};

export {
  ViewSwitcher,
};
