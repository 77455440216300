import {
  has, includes, isEqual, uniqWith,
} from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { showToast } from '@ess/utils';

import { useOfferListPriorityOrder } from '@ess/v5-data-provider/mwsfunc/hooks';

import useStorage from '@ess/hooks/useStorage';
import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';

interface IPrioritizedOperatorsItem {
  operators: { label: string, value: string}[]
  region: string
}

export interface IPrioritizedOperators {
  [regionId: string]: IPrioritizedOperatorsItem
}

/**
 * usePrioritizedOperators hook.
 *
 * Manages prioritized operators storage.
 */
const usePrioritizedOperators = () => {
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const [_, setOffersPriorityOrder] = useOfferListPriorityOrder();
  const [prioritizedOperators, setPrioritizedOperators] = useStorage<any>(
    'localStorage', 'PrioritizedOperators', {},
  );

  /**
   * Clear stored operators.
   */
  const reset = () => {
    setPrioritizedOperators({});
    setOffersPriorityOrder({
      field: 'Base.Operator',
      mapping: [
        [],
      ],
    });
    showToast(1, t('prioritized_operators_cleared'));
  };

  /**
   * Add/Remove item from prioritized operators.
   * @param operators
   * @param allValues
   * @param region
   */
  const add = (operators: {label: string, value: string}[], allValues: string[], region: { id: string, name: string }) => {
    trackEvent({
      event: 'prioritizedOperators',
      eventCategory: 'B2B_CLICK_EVENT',
      eventAction: 'B2B_PRIORITIZED_OPERATORS',
    });

    const values = operators.map((item) => item.value);
    const unSelectValues = allValues.filter((item) => !includes(values, item));

    setPrioritizedOperators((state: IPrioritizedOperators) => {
      const stateCopy = { ...state };
      const regionExists = has(state, region.id);

      let currentValues = regionExists ? stateCopy[region.id]?.operators : [];

      if (unSelectValues.length) {
        currentValues = currentValues.filter((item: { label: string, value: string }) => !includes(unSelectValues, item.value));
      }

      const newValues = uniqWith([...currentValues, ...operators], isEqual);
      const message = newValues.length === 1
        ? 'operator_marked_as_prioritized'
        : newValues.length > 1
          ? 'operators_marked_as_prioritized'
          : 'operators_cleared_for_region';

      showToast(1, t(message, {
        region: region.name,
        ...newValues.length ? { operators: newValues.map((item) => item.label).join(', ') } : {},
      }));

      setOffersPriorityOrder({
        field: 'Base.Operator',
        mapping: newValues.length ? [
          [...newValues.map((item) => item.value)],
          [],
        ] : [],
      });

      if (!newValues.length) {
        delete stateCopy[region.id];
        return stateCopy;
      }
      return {
        ...state,
        [region.id]: {
          region: region.name,
          operators: newValues,
        },
      };
    });
  };

  /**
   * Remove value from prioritized operators.
   * @param operator
   * @param region
   */
  const remove = (operator: {label: string, value: string}, region: { id: string, name: string }) => {
    showToast(1, t('operator_removed_from_prioritized', {
      operator: operator.label,
      region: region.name,
    }));

    setPrioritizedOperators((state: IPrioritizedOperators) => {
      const stateCopy = { ...state };
      const newValues = [...stateCopy[region.id].operators].filter((item) => item.value !== operator.value);

      if (!newValues.length) {
        delete stateCopy[region.id];
        return stateCopy;
      }
      return {
        ...state,
        [region.id]: {
          region: region.name,
          operators: newValues,
        },
      };
    });
  };

  return {
    add,
    remove,
    reset,
    prioritizedOperators,
  };
};

export default usePrioritizedOperators;
