import React from 'react';

import { BasketItem } from '@basket/types';

import { VIDE_SERVICE_URL } from '@basket/constants';
import { MediaType } from '@basket/components/CustomMediaModal/types';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Anchor from '@ess/ui/Anchor';

type MediaProps = {
  data: BasketItem,
}

const getVideoUrl = (url?: string) => {
  if (url) {
    const [service, videoId] = url.split(':');
    const serviceUrl = service in VIDE_SERVICE_URL
      ? VIDE_SERVICE_URL[service]
      : undefined;

    return serviceUrl ? `${serviceUrl}${videoId}` : url;
  }

  return url;
};

const Media = ({ data }: MediaProps) => {
  const url = data.offerType === MediaType.Video
    ? getVideoUrl(data.mediaLinkUrl)
    : data.mediaLinkUrl;

  return (
    <FlexBox flexDirection="column" minHeight={80}>
      <Text fontWeight="bold" mb="small">{data.nameCustom}</Text>
      <Anchor
        color="blue"
        href={url}
        target="_blank"
        style={{
          wordBreak: 'break-all',
        }}
      >
        {url}
      </Anchor>
    </FlexBox>
  );
};

export {
  Media,
};
