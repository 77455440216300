import styled from 'styled-components';

const MenuContainer = styled.div`
    display: flex;
    height: 100%;
`;

const Menu__Item = styled.a`
    display: flex;
    align-items: center;
    height: 100%;
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    padding: 0 15px;

    &:hover {
      color: ${({ theme }) => theme.colors.orange};
    }
`;

const Menu__Dropdown = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 52px;
    min-width: 120px;
    background-color: #0a1a35;
    z-index: 99999;

    ${Menu__Item} {
      padding: 10px 15px;

      &:hover {
        background-color: ${({ theme }) => theme.colors.orange};
        color: ${({ theme }) => theme.colors.white};
      }
    }
`;

const Menu__Item__Parent = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    &:hover {
      background-color: #0a1a35;
    }
`;

export const Styled = {
  MenuContainer,
  Menu__Item__Parent,
  Menu__Dropdown,
  Menu__Item,
};
