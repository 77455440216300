import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faPlus,
  faShoppingCart,
} from '@fortawesome/pro-regular-svg-icons';
import { useToggle } from 'react-use';

import { useReactBasket } from '@basket/hooks/useReactBasket';
import { useAgentSettings } from '@ess/components/AgentSettings';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

import CreateBasketModal from '@basket/components/CreateBasketModal';

import { CreateBookmark } from '@merlin/components/CreateBookmark';

import { BasketCount } from '@basket/components';

type HeaderIconsProps = {
  showTitle?: boolean;
}

const HeaderIcons = ({ showTitle = false }:HeaderIconsProps) => {
  const { t } = useTranslation();
  const { toggleBasket, createBasket, basket } = useReactBasket();
  const [newBasketModal, toggleNewBasket] = useToggle(false);
  const [newBookmarkModal, toggleNewBookmark] = useToggle(false);
  const openBasketOnHover = useAgentSettings((state) => state.values.basket.openBasketOnHover);

  const basketIcon = useRef<HTMLDivElement>(null);

  const clickTimeout = useRef<any>({
    timeout: null,
    blocked: false,
  });

  const openBasketOnHoverHandler = useCallback(() => {
    if (!basket.isOpen && openBasketOnHover) {
      toggleBasket();

      clickTimeout.current.blocked = true;
      clickTimeout.current.timeout = setTimeout(() => {
        clickTimeout.current.blocked = false;
      }, 500);
    }
  }, [basket.isOpen, openBasketOnHover]);

  const onBookmarkApply = (bookmarkName: string, createNewBasket: boolean) => {
    if (createNewBasket) {
      createBasket(bookmarkName);
    }
  };

  const openBasketOnClick = () => {
    if (!clickTimeout.current.blocked) {
      toggleBasket();
    }
  };

  useEffect(() => {
    const isOpenOnHover = openBasketOnHover;
    const basketIconElement = basketIcon?.current;

    if (basketIconElement) {
      if (isOpenOnHover) {
        basketIconElement.addEventListener('mouseenter', openBasketOnHoverHandler);
        basketIconElement.addEventListener('click', openBasketOnClick);
      } else {
        basketIconElement.addEventListener('click', toggleBasket);
      }
    }

    return () => {
      if (basketIconElement) {
        if (isOpenOnHover) {
          basketIconElement.removeEventListener('mouseenter', openBasketOnHoverHandler);
          basketIconElement.removeEventListener('click', openBasketOnClick);
        } else {
          basketIconElement.removeEventListener('click', toggleBasket);
        }
      }
    };
  }, [openBasketOnHover, openBasketOnHoverHandler]);

  return (
    <>
      {newBasketModal && (
        <CreateBasketModal
          onApply={createBasket}
          onClose={() => toggleNewBasket(false)}
          appendTo={document.querySelector('body')}
        />
      )}
      {newBookmarkModal && (
        <CreateBookmark
          onClose={toggleNewBookmark}
          onApply={onBookmarkApply}
        />
      )}
      <FlexBox
        width={35}
        height={35}
        mr="small"
        alignItems="center"
        justifyContent="center"
        onClick={() => toggleNewBasket(true)}
        style={{
          cursor: 'pointer',
        }}
      >
        <FontAwesomeIcon icon={faPlus} color="#fff" size="xl"/>
        {showTitle && (<Text fontSize="14px" ml="medium">{t('lbl_add_basket')}</Text>)}
      </FlexBox>
      <FlexBox
        width={35}
        height={35}
        mr="small"
        alignItems="center"
        justifyContent="center"
        onClick={() => toggleNewBookmark(true)}
        style={{
          cursor: 'pointer',
        }}
      >
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} color="#fff" size="xl"/>
        {showTitle && (<Text fontSize="14px" ml="medium">{t('lbl_new_window')}</Text>)}
      </FlexBox>
      <FlexBox
        ref={basketIcon}
        className="js-basket-react-icon"
        width={35}
        height={35}
        position="relative"
        alignItems="center"
        justifyContent="center"
        style={{
          cursor: 'pointer',
        }}
      >
        <BasketCount/>
        <FontAwesomeIcon icon={faShoppingCart} color="#fff" size="xl"/>
        {showTitle && (<Text fontSize="14px" ml="medium">{t('lbl_basket')}</Text>)}
      </FlexBox>
    </>
  );
};

export {
  HeaderIcons,
};
