import {
  AUTH_ERROR_TRANSLATIONS,
  VALIDATION_ERROR_TRANSLATIONS,
} from '@ess/auth-provider';

export const getAuthError = (errorCode: string | null) => {
  if (!errorCode) {
    return null;
  }

  return errorCode in AUTH_ERROR_TRANSLATIONS
    ? AUTH_ERROR_TRANSLATIONS[errorCode]
    : errorCode;
};

export const getValidationErrors = (errors: Record<string, string>) => {
  const error: string[] = [];

  Object.keys(errors).map((key) => {
    const errorKey = errors[key] in VALIDATION_ERROR_TRANSLATIONS
      ? VALIDATION_ERROR_TRANSLATIONS[errors[key]]
      : errors[key];

    error.push(errorKey);
  });

  return error;
};
