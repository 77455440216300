import * as Sentry from '@sentry/react';

import { axiosInstance } from '@ess/auth-provider';

import { DEVELOPMENT } from '@ess/constants/api';

import {
  TOUROP_LOGIN_ENDPOINT,
  TOUROP_LOGOUT_ENDPOINT,
} from '../constants';

const captureError = (error: any) => {
  Sentry.withScope((scope) => {
    const { responseURL } = error?.request ?? {};

    if (error?.data) {
      scope.setContext('Response', {
        error: JSON.stringify(error.data),
      });
    }

    scope.setTransactionName(`TouropAuth Error: ${responseURL} ${error?.message ?? ''}`);

    Sentry.captureException(new Error(error));
  });
};

export const touropAuth = {
  login: () => {
    if (DEVELOPMENT) {
      return;
    }

    (async () => {
      try {
        const response = await axiosInstance.post(TOUROP_LOGIN_ENDPOINT);

        if (response?.data?.status?.status !== 'SUCCESS') {
          throw new Error(response as any);
        }
      } catch (error) {
        captureError(error);
      }
    })();
  },
  logout: () => {
    if (DEVELOPMENT) {
      return;
    }

    (async () => {
      try {
        const response = await axiosInstance.post(TOUROP_LOGOUT_ENDPOINT);

        if (response?.data?.status?.status !== 'SUCCESS') {
          throw new Error(response as any);
        }
      } catch (error) {
        captureError(error);
      }
    })();
  },
};
