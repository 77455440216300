import { useContext } from 'react';
import { useStore } from 'zustand';

import { Store } from './store';

import { AuthContext } from './AuthProvider';

const useAuth = <T>(selector: (state: Store) => T) => {
  const store = useContext(AuthContext);

  if (!store) {
    throw new Error('useAuth hook must be used within AuthProvider');
  }

  return useStore(store, selector!);
};

export {
  useAuth,
};
