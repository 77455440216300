import styled from 'styled-components';

const Basket__Name = styled.div`
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Close__Icon = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 25px;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

const Basket__Tabs = styled.div`
  display: flex;
  align-self: flex-end;
  height: 30px;
  max-width: calc(100%  - 50px);
`;

const Basket__Header = styled.div`
  display: flex;
  flex-shrink: 0;
  height: 50px;
  padding: 0 ${({ theme }) => theme.space.small};
`;

const Basket__Tab__Add = styled.div<{ hasTabs?: boolean | undefined }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 26px;
  height: 26px;
  min-width: 26px;
  color: ${({ theme }) => theme.colors.darkGray};
  margin-left: ${({ theme, hasTabs }) => (hasTabs ? theme.space.tiny : 0)};
  background-color: ${({ theme }) => theme.colors.lighterGray};
  border-radius: 6px;
  opacity: 0.8;

  &:hover {
     background-color: ${({ theme }) => theme.colors.lightBlue};
     color: ${({ theme }) => theme.colors.blue};
     opacity: 1;
  }
`;

const Basket__Tab = styled.div<{ isCurrentBasket: boolean }>`
  display: flex;
  position: relative;
  cursor: pointer;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 25px 5px 10px;
  align-content: center;
  align-items: center;
  background-color: transparent;
  white-space: nowrap;
  transition: all .3s ease-in;

  ${Basket__Name} {
    opacity: 0.6;
  }

  ${({ isCurrentBasket, theme }) => (isCurrentBasket ? `
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #e8ecee;

    ${Basket__Name} {
      color: ${theme.colors.textColor};
      opacity: 1;
    }
  ` : `
    &:hover {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      background-color: ${theme.colors.lightBlue};

      ${Basket__Name} {
        color: ${theme.colors.blue};
        opacity: 1;
      }
    }
  `)};
`;

export const Styled = {
  Basket__Header,
  Basket__Tabs,
  Basket__Tab,
  Basket__Tab__Add,
  Basket__Name,
  Close__Icon,
};
