import { useEffect, useState } from 'react';

import { CONTENT_SERVICE_URL } from '@ess/constants/api';

import { promiseRequest } from '@ess/v5-data-provider/request';
import i18next from 'i18next';
import { getCurrentLanguage } from '@ess/utils';

enum ImagesSizes {
  Full = 'Full',
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
}

interface ImagesState {
  isLoading: boolean;
  isReady: boolean;
  isError: boolean;
  data: string[];
}

type UseFetchImages = {
  serviceUrl?: string;
  operator: string;
  hotelCode: string;
  xCode?: number | undefined;
  imageSize?: ImagesSizes;
  lazy?: boolean;
  onImagesReady?: () => void;
  initialData?: string[];
};

const useFetchImages = ({
  operator,
  hotelCode,
  imageSize,
  xCode,
  serviceUrl,
  initialData = [],
  lazy = false,
}: UseFetchImages) => {
  const [imagesState, setImagesState] = useState<ImagesState>({
    isLoading: false,
    isReady: false,
    isError: false,
    data: initialData,
  });

  /**
   * Fetch images handler.
   */
  const fetchImages = async () => {
    setImagesState((state) => ({
      ...state,
      isLoading: true,
    }));

    try {
      const requestUrl = serviceUrl ?? CONTENT_SERVICE_URL;

      const postData = JSON.stringify({
        Language: getCurrentLanguage(),
        Operator: operator,
        HotelCode: hotelCode,
        HotelXCode: xCode,
        PictureSizes: imageSize?.toLowerCase(),
      });

      const request = await promiseRequest(
        `${requestUrl}Pictures/${encodeURIComponent(postData)}`,
        null,
      );
      const images = request?.Sections?.Pictures[`PictureUrl${imageSize}`];

      if (images?.length) {
        setImagesState((state) => ({
          ...state,
          data: images,
          isReady: true,
        }));
      } else {
        setImagesState((state) => ({
          ...state,
          isReady: true,
        }));
      }
    } catch (error) {
      setImagesState((state) => ({
        ...state,
        isError: true,
      }));
    } finally {
      setImagesState((state) => ({
        ...state,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    (async () => {
      if (!lazy) {
        await fetchImages();
      }
    })();
  }, [lazy]);

  useEffect(() => {
    setImagesState((state) => ({
      ...state,
      isReady: false,
      data: [],
    }));
  }, [hotelCode]);

  return {
    ...imagesState,
    fetch: fetchImages,
  };
};

export { useFetchImages, ImagesSizes };
