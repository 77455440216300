import React from 'react';

import { MerlinLayout } from './MerlinLayout';
import { MerlinLayoutContainer } from './MerlinLayoutContainer';
import { PrintOffersLayout } from './PrintOffersLayout';

type LayoutProps = {
  children: React.ReactElement;
}

export default ({ children }: LayoutProps) => (
  <MerlinLayoutContainer>
    <MerlinLayout>
      {children}
    </MerlinLayout>
  </MerlinLayoutContainer>
);

export const PrintOffersLayout1 = ({ children }: LayoutProps) => (
  <MerlinLayoutContainer>
    <PrintOffersLayout>
      {children}
    </PrintOffersLayout>
  </MerlinLayoutContainer>
);
