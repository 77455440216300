import styled from 'styled-components';

const Column = styled.div<{ height: number }>`
  display: flex;
  align-self: start;
  flex-grow: 1;
  flex-shrink: 1;
  position: sticky !important;
  height: ${({ height }) => `calc(${height}px - var(--header-height))`};
  top: var(--header-height);
  z-index: 991;
`;

const Column__Inner = styled.div<{ columnSide: string }>`
  display: flex;
  position: absolute;
  justify-content: ${({ columnSide }) => (columnSide === 'left' ? 'flex-end' : 'flex-start')};
  top: 0;
  left: -15;
  height: 100%;
  width: calc(100% + 15px);

  ${({ columnSide }) => (columnSide === 'left' ? `
    right: -15px;
  ` : `
    left: -15px;
  `)};
`;

export const Styled = {
  Column,
  Column__Inner,
};
