import React from 'react';

import { Styled } from '@tourop/components/MultiSelectV2/MultiSelectV2.styles';

type TagProps = {
  value: string
  isCount?: boolean
}

const Tag = ({ value, isCount = false }: TagProps) => (
  <Styled.MultiSelect__Field__Tags__Item key={value} isCount={isCount}>
    <span>{value}</span>
  </Styled.MultiSelect__Field__Tags__Item>
);

export default Tag;
