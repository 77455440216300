import React, { createContext, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import { createColumnsManagerStore } from './store';

import { ColumnsManagerStore } from './types';

const ColumnsManagerContext = createContext<ColumnsManagerStore | null>(null);

type ColumnsManagerContextProps = {
  children: React.ReactNode;
  elements?: Record<string, any>
}

const PAGES: Record<string, string> = {
  results: 'RESULTS_PAGE',
  search: 'SEARCH_PAGE',
  other: 'OTHER_PAGE',
};

const getPageColumns = (path: string) => {
  let page = PAGES.other;

  Object.keys(PAGES).map((item) => {
    if (path.includes(item)) {
      page = PAGES[item];
    }
  });

  return page;
};

const ColumnsManagerProvider = ({ children, elements = {} }: ColumnsManagerContextProps) => {
  const storeRef = useRef<ColumnsManagerStore>();
  const location = useLocation();
  const page = getPageColumns(location.pathname);

  if (!storeRef.current) {
    storeRef.current = createColumnsManagerStore(elements ? { elements: elements[page] } : {});
  }

  useEffect(() => {
    if (storeRef?.current && elements) {
      storeRef.current.getState().setElements(elements[page] as any);
    }
  }, [page]);

  return (
    <ColumnsManagerContext.Provider value={storeRef.current}>
      {children}
    </ColumnsManagerContext.Provider>
  );
};

export {
  ColumnsManagerProvider,
  ColumnsManagerContext,
};
