import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from '@ess/ui/Text';
import FlexBox from '@ess/ui/FlexBox';

import { useAgentSettingsStore } from '../../hooks';

import { SelectSetting, SwitchSetting, DisplaySetting } from './SettingType';

import { Styled } from './Sections.style';

const settingTypes: Record<string, any> = {
  switch: SwitchSetting,
  select: SelectSetting,
  display: DisplaySetting,
};

const sectionLabelsMap: Record<string, string> = {
  detailsPage: 'lbl_details_page',
};

type SectionsProps = {
  onChange?: ({ name, value, section } : { name: string, value: any, section: string }) => void;
}

const Sections = ({ onChange = undefined }: SectionsProps) => {
  const { t } = useTranslation();
  const config = useAgentSettingsStore((state) => state.config);

  return (
    <Styled.AgentSettings>
      {Object.keys(config).map((section) => {
        const settings = config[section];
        const isDisplay = 'display' in settings;

        const label = section in sectionLabelsMap
          ? sectionLabelsMap[section]
          : isDisplay ? settings.display.label as string : `lbl_${section.toLowerCase()}`;

        return (
          <React.Fragment key={section}>
            <Styled.AgentSettings__Section>
              <Text fontWeight="normal" color="white" fontSize={16}>
                {t(label)}
              </Text>
            </Styled.AgentSettings__Section>
            {isDisplay ? (
              <DisplaySetting {...settings.display}/>
            ) : (
              <>
                {Object.keys(settings).map((settingName) => {
                  const setting = settings[settingName];
                  const Component = settingTypes[setting.type];
                  const isHidden = setting?.visible === false;

                  if (!Component || isHidden) {
                    return false;
                  }

                  return (
                    <Styled.AgentSettings__Section__Item key={settingName}>
                      <Text>
                        {t(setting.label)}
                      </Text>
                      <FlexBox
                        width={150}
                        justifyContent="flex-end"
                        flexShrink={0}
                      >
                        <Component
                          section={section}
                          name={settingName}
                          data={setting}
                          onChange={onChange}
                        />
                      </FlexBox>
                    </Styled.AgentSettings__Section__Item>
                  );
                })}
              </>
            )}
          </React.Fragment>
        );
      })}
    </Styled.AgentSettings>
  );
};

export {
  Sections,
};
