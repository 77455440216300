import React from 'react';

import { LayoutSettingsProvider } from '@merlin/context';

import { ColumnsManagerContainer } from '@ess/components/layout';

type MerlinLayoutContainerProps = {
  children: React.ReactElement;
}

const MerlinLayoutContainer = ({ children }: MerlinLayoutContainerProps) => (
  <LayoutSettingsProvider>
    <ColumnsManagerContainer>
      {children}
    </ColumnsManagerContainer>
  </LayoutSettingsProvider>
);

export {
  MerlinLayoutContainer,
};
