import React, { createContext, useRef } from 'react';

import { createLiveRoomStore, LiveRoomStore } from '@liveroom/store';

const LiveRoomStoreContext = createContext<LiveRoomStore | null>(null);

type LiveRoomStoreProviderProps = {
  children: React.ReactNode;
  cssVariablesContainer?: string
}

const LiveRoomStoreProvider = ({ children, cssVariablesContainer = '' }: LiveRoomStoreProviderProps) => {
  const storeRef = useRef<LiveRoomStore>();

  if (!storeRef.current) {
    storeRef.current = createLiveRoomStore({ cssVariablesContainer });
  }

  return (
    <LiveRoomStoreContext.Provider value={storeRef.current}>
      {children}
    </LiveRoomStoreContext.Provider>
  );
};

export {
  LiveRoomStoreProvider,
  LiveRoomStoreContext,
};
