import styled from 'styled-components';

const BasketCountBadge = styled.div`
  text-align: center;
  border-radius: 4px;
  position: absolute;
  top: -2px;
  min-width: 18px;
  right: -4px;
  line-height: 18px;
  padding: 0 4px;
  box-shadow: 0px 0px 1px rgba(50,50,50,0.6);
  background-color: ${({ theme }) => theme.colors.blue};

  span {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.caption};
  }
`;

export const Styled = {
  BasketCountBadge,
};
