import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLock,
  faUnlock,
  faPlus,
  faCodeCompare,
  faCheck,
  faCopy,
  faTrashCan,
  faPrint,
  faPencil,
  faPhotoFilm,
  faShareNodes,
} from '@fortawesome/pro-regular-svg-icons';

import { useReactBasket } from '@basket/hooks/useReactBasket';

import { ConfirmModal } from '@ess/ui/Modal/ModalsTypes';
import FlexBox from '@ess/ui/FlexBox';

import { EllipsisMenu } from '@ess/components/EllipsisMenu';

import {
  showToast,
  TOAST_INFO,
  getUrlSearchParams,
  objectToURLParams,
} from '@ess/utils';

import { CustomMediaModal } from '../CustomMediaModal';
import { ShareBasket } from '../ShareBasket';
import CreateBasketModal from '../CreateBasketModal';
import EditNameModal from '../EditNameModal';

const confirmModalDefault = {
  isOpen: false,
  title: '',
  message: '',
  onConfirm: () => {},
};

type BasketActionsProps = {
  basket: {
    id: number | null;
    name: string;
    selectedItems?: number[];
    blocked?: boolean;
    description: string;
  }
  onAction?: (action: string, data: any) => any;
  excludedActions?: string[];
  onDropdownOpen?: (basketId: number | null) => void;
}

const BasketActions = ({
  basket,
  excludedActions = [],
  onDropdownOpen = undefined,
  onAction = undefined,
}: BasketActionsProps) => {
  const { t } = useTranslation();
  const {
    basket: basketState,
    getCompareOffersUrl,
    createBasket,
    deleteItem,
    deleteBasket,
    blockBasket,
    copyToCSV,
    unBlockBasket,
    autoCheckOnline,
    changeBasketName,
    liveRoom,
  } = useReactBasket();
  const [isAddMediaModalOpen, setIsAddMediaModalOpen] = useState(false);
  const [isShareBasketModalOpen, setIsShareBasketModalOpen] = useState(false);
  const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(confirmModalDefault);
  const currentBasketId = basket.id;

  /**
   * Discard action handler.
   */
  const onDiscardHandler = () => {
    setConfirmModal(confirmModalDefault);
  };

  /**
   * Confirm action handler.
   */
  const onConfirmHandler = () => {
    if (confirmModal?.onConfirm) {
      confirmModal.onConfirm();
      setConfirmModal(confirmModalDefault);
    }
  };

  const onBasketNameChange = async (newName: string) => {
    const response = await changeBasketName(currentBasketId, newName);

    if (onAction) {
      onAction('changeBasketName', response);
    }
  };

  /**
   * Dropdown item click handler.
   * @param value
   */
  const itemClickHandler = async (value: string) => {
    const actions: any = {
      printOffers: () => {
        const urlParams = getUrlSearchParams({ params: ['sf'] });
        urlParams.offerItems = basket?.selectedItems?.toString() ?? '';
        const params = objectToURLParams(urlParams);

        window.open(`/print-offers/${currentBasketId}?${params}`, '_self');
      },
      compareOffers: () => getCompareOffersUrl(currentBasketId),
      createBasket: () => {
        setIsOpenModal(true);
      },
      shareBasket: () => {
        setIsShareBasketModalOpen(true);
      },
      blockBasket: async () => {
        const response = await blockBasket(currentBasketId);

        if (onAction) {
          onAction(value, response);
        }
      },
      unBlockBasket: async () => {
        const response = await unBlockBasket(currentBasketId);

        if (onAction) {
          onAction(value, response);
        }
      },
      changeBasketName: () => {
        if (!currentBasketId) {
          showToast(TOAST_INFO, t('lbl_no_selected_basket'));
        } else {
          setIsEditNameModalOpen(true);
        }
      },
      copyToCsv: () => {
        if (!basket?.selectedItems?.length) {
          showToast(TOAST_INFO, t('lbl_basket_no_offers_checked'));
          return;
        }

        copyToCSV();
      },
      autoCheckStatus: () => {
        if (!basket?.selectedItems?.length) {
          showToast(TOAST_INFO, t('lbl_basket_no_offers_checked'));
          return;
        }

        autoCheckOnline();
      },
      addMedia: () => {
        setIsAddMediaModalOpen(true);
      },
      deleteBasket: async () => {
        await setConfirmModal({
          isOpen: true,
          title: t('lbl_delete_basket'),
          message: t('basketDelConfirmText'),
          onConfirm: async () => {
            const response = await deleteBasket(currentBasketId);

            if (onAction) {
              onAction(value, response);
            }
          },
        });
      },
      deleteOffers: () => {
        if (!basket?.selectedItems?.length) {
          showToast(TOAST_INFO, t('lbl_basket_no_offers_checked'));
          return;
        }
        setConfirmModal({
          isOpen: true,
          title: t('lbl_delete_checked_offers'),
          message: t('lbl_delete_basket_offers_confirm'),
          onConfirm: () => deleteItem(basket?.selectedItems ?? []),
        });
      },
      liveRoom: () => {
        liveRoom(currentBasketId);
      },
    };

    if (value in actions) {
      actions[value]();
    }
  };

  const offerActions = [
    {
      value: 'printOffers',
      label: t('lbl_print_offers'),
      icon: <FontAwesomeIcon icon={faPrint}/>,
      disabled: !basketState?.current?.id,
    },
    {
      value: 'compareOffers',
      label: t('lbl_compare_offers'),
      icon: <FontAwesomeIcon icon={faCodeCompare}/>,
      disabled: !basketState?.current?.id,
    },
    {
      value: 'autoCheckStatus',
      label: t('lbl_check_status'),
      icon: <FontAwesomeIcon icon={faCheck}/>,
      disabled: !basketState?.current?.id,
    },
    {
      value: 'deleteOffers',
      label: t('lbl_delete_checked_offers'),
      icon: <FontAwesomeIcon icon={faTrashCan}/>,
      disabled: !basketState?.current?.id,
    },
    {
      value: 'copyToCsv',
      label: t('lbl_copy_to_csv'),
      icon: <FontAwesomeIcon icon={faCopy}/>,
      disabled: !basketState?.current?.id,
    },
  ].filter((item) => basketState?.actions?.offer?.includes(item.value) ?? true);

  const basketActions = [
    {
      value: 'createBasket',
      label: t('lbl_create_new_basket'),
      icon: <FontAwesomeIcon icon={faPlus}/>,
    },
    {
      value: 'changeBasketName',
      label: t('lbl_change_basket_name'),
      icon: <FontAwesomeIcon icon={faPencil}/>,
      disabled: !basketState?.current?.id,
    },
    {
      value: 'shareBasket',
      label: t('lbl_share_basket'),
      icon: <FontAwesomeIcon icon={faShareNodes}/>,
      disabled: !basketState?.current?.id,
    },
    {
      value: basket.blocked ? 'unBlockBasket' : 'blockBasket',
      label: basket.blocked ? t('lbl_unblock_basket') : t('lbl_block_basket'),
      icon: <FontAwesomeIcon icon={basket.blocked ? faUnlock : faLock}/>,
      disabled: !basketState?.current?.id,
    },
    {
      value: 'addMedia',
      label: t('lbl_add_media'),
      icon: <FontAwesomeIcon icon={faPhotoFilm}/>,
      disabled: !basketState?.current?.id,
    },
    {
      value: 'deleteBasket',
      label: t('lbl_delete_basket'),
      icon: <FontAwesomeIcon icon={faTrashCan}/>,
      disabled: !basketState?.current?.id,
    },
  ].filter((item) => basketState?.actions?.basket?.includes(item.value) ?? true);

  const options = useMemo(() => [
    {
      group: 'offers',
      label: t('lbl_offers'),
      options: excludedActions?.length
        ? offerActions.filter((action) => !excludedActions.includes(action.value))
        : offerActions,
    },
    {
      group: 'basket',
      label: t('lbl_basket'),
      options: excludedActions?.length
        ? basketActions.filter((action) => !excludedActions.includes(action.value))
        : basketActions,
    },
  ], [basket.blocked, excludedActions]);

  return (
    <>
      {isShareBasketModalOpen && (
        <ShareBasket
          basketId={basket.id}
          description={basket.description}
          onClose={() => setIsShareBasketModalOpen(false)}
        />
      )}
      {isAddMediaModalOpen && (
        <CustomMediaModal
          onClose={() => setIsAddMediaModalOpen(false)}
        />
      )}
      {isEditNameModalOpen && (
        <EditNameModal
          name={basket.name}
          title={t('lbl_change_basket_name')}
          placeholder={t('lbl_provide_basket_name')}
          onApply={onBasketNameChange}
          onClose={() => {
            setIsEditNameModalOpen(false);
          }}
        />
      )}
      {isOpenModal && (
        <CreateBasketModal
          onApply={createBasket}
          onClose={() => setIsOpenModal(false)}
        />
      )}
      {confirmModal.isOpen && (
        <ConfirmModal
          isOpen
          title={confirmModal.title}
          onConfirm={onConfirmHandler}
          onClose={onDiscardHandler}
          onDiscard={onDiscardHandler}
        >
          <FlexBox p="small">
            {confirmModal.message}
          </FlexBox>
        </ConfirmModal>
      )}

      <EllipsisMenu
        onOpen={() => {
          if (onDropdownOpen) {
            onDropdownOpen(basket.id);
          }
        }}
        options={options}
        onItemClick={itemClickHandler}
      />
    </>
  );
};

export default BasketActions;
