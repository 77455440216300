import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import clipboardCopy from 'clipboard-copy';

import { showToast, TOAST_ERROR, TOAST_SUCCESS } from '@ess/utils';

import Anchor from '@ess/ui/Anchor';
import Text from '@ess/ui/Text';
import Tooltip from '@ess/ui/Tooltip';

import { Styled } from './ShareInput.styles';

type ShareInputProps = {
  data?: string;
  type: 'text' | 'url';
  placeholder?: string;
}

const ShareInput = ({ type, data = undefined, placeholder = undefined }: ShareInputProps) => {
  const { t } = useTranslation();

  const onCopyHandler = useCallback(() => {
    if (data) {
      clipboardCopy(data).then(
        (() => showToast(TOAST_SUCCESS, t('copied_to_clipboard_msg'))),
        (() => showToast(TOAST_ERROR, t('copy_to_clipboard_failed_msg'))),
      );
    }
  }, [data]);

  return (
    <Styled.ShareInput>
      <Styled.ShareInput__Inner>
        {data ? (
          <>
            {type === 'url' ? (
              <Anchor color="navy" href={data} target="_blank">
                {data}
              </Anchor>
            ) : (
              <Text as="span">
                {data}
              </Text>
            )}
          </>
        ) : (
          <Text textAlign="center" color="darkGray">
            {placeholder}
          </Text>
        )}
      </Styled.ShareInput__Inner>
      {data && (
      <Styled.ShareInput__CopyButton onClick={onCopyHandler}>
        <Tooltip content={t('copy_to_clipboard')} theme="bubble-wide">
          <Styled.ShareInput__CopyButton__Icon>
            <FontAwesomeIcon icon={faCopy}/>
          </Styled.ShareInput__CopyButton__Icon>
        </Tooltip>
      </Styled.ShareInput__CopyButton>
      )}
    </Styled.ShareInput>
  );
};

export {
  ShareInput,
};
