import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { isMobileOnly } from 'react-device-detect';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import clipboardCopy from 'clipboard-copy';

import { IOffer } from '@ess/types';

import { CONTENT_SERVICE_URL } from '@ess/constants/api';

import { promiseRequest } from '@ess/v5-data-provider/request';

import { getCurrentLanguage, showToast, TOAST_ERROR, TOAST_SUCCESS } from "@ess/utils";

import { parseScheme } from '../utils/parseScheme';

import { defaultItems } from '../defaultItems';

import { Button, ButtonGroup } from '@ess/ui/Button';
import { Modal } from '@ess/ui/Modal/ModalsTypes';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

import PostItem, { ItemProps } from './PostItem';
import PostItemContainer from './PostItemContainer';


type Image = {
  url: string
  selected: boolean
};

export type SocialPostCreatorProps = {
  isOpen: boolean
  offerData: IOffer
  agencyData: any,
  offerLink: string,
  onClose: () => void
}

enum ViewType {
  Modify = 'Modify',
  Preview = 'Preview',
}

const SocialPostCreator = ({
  agencyData,
  offerData,
  offerLink,
  isOpen,
  onClose,
}: SocialPostCreatorProps) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<ItemProps[]>(defaultItems);
  const [parsedItems, setParsedItems] = useState<any[]>([]);
  const isFirstOpen = useRef(true);
  const imagesLoaded = useRef(false);
  const sensors = useSensors(
    useSensor(PointerSensor),
  );
  const [viewType, setViewType] = useState(ViewType.Preview);

  const itemVisibilityHandler = (name: string, selected: boolean) => {
    setItems((state) => [...state].map((item) => item.name === name ? ({
      ...item,
      selected,
    }) : item))
  }

  const fetchImages = async () => {
    try {
      const postData = encodeURIComponent(JSON.stringify({
        Language:  getCurrentLanguage(),
        Operator: offerData?.Base?.Operator,
        offerId: offerData?.Base?.OfferId,
        HotelCode: `${offerData?.Accommodation?.Code}`,
        PictureSizes: 'full',
      }));
      const request = await promiseRequest(`${CONTENT_SERVICE_URL}Pictures/${postData}`, null);
      const { Sections } = request;
      const { Pictures } = Sections;
      const { PictureUrlFull } = Pictures;

      if (PictureUrlFull.length) {
        const pictures = PictureUrlFull.slice(0, 5);

        setItems((state: any) => {
          const newItems = state.map((item: any) => item.name === 'images' ? ({
            ...item,
            images: pictures.map((image: string) => ({
              url: image,
              selected: true,
            })),
            scheme: () => pictures.map((image: string) => {
              return `🖼️ ${image}`;
            }).join('\n')
          }) : item);

          return newItems;
        });
      } else {
        setItems((state: any) => {
          return state.map((item: any) => item.name === 'images' ? ({
            ...item,
            images: [],
            scheme: '',
            enabled: false,
          }) : item);
        });
      }

    } catch (e) {
      setItems((state: any) => {
        return state.map((item: any) => item.name === 'images' ? ({
          ...item,
          images: [],
          scheme: '',
          enabled: false,
        }) : item);
      });
    } finally {
      imagesLoaded.current = true;
    }
  }

  const onDragEndHandler = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activeIndex = items.findIndex(({ id }) => id === active.id);
      const overIndex = items.findIndex(({ id }) => id === over.id);
      const sorted = arrayMove(items, activeIndex, overIndex);

      setItems(sorted);
    }
  };

  const imagesItem = items.find((item) => (item.name === 'images' && item.selected));
  const imagePreview = imagesItem ? imagesItem?.images?.filter((image: Image) => image.selected) : [];

  const buttonGroup = (
    <FlexBox paddingBottom="10px" ml="auto">
      <ButtonGroup>
        <Button
          key="modify"
          label={t('lbl_modify')}
          onClick={() => setViewType(ViewType.Modify)}
          variant={viewType === ViewType.Modify ? 'primary' : 'secondary'}
          size="small"
        />
        <Button
          key="modify"
          label={t('lbl_preview')}
          onClick={() => setViewType(ViewType.Preview)}
          variant={viewType === ViewType.Preview ? 'primary' : 'secondary'}
          size="small"
        />
      </ButtonGroup>
    </FlexBox>
  )
  const onItemEdit = (name: string, scheme: any) => {
    setItems((state: any) => {
      return state.map((item: any) => item.name === name ? ({
        ...item,
        ...scheme,
      }) : item);
    });
  }

  const onCopyHandler = useCallback(() => {
    const post = parsedItems
      .filter((item) => item.selected)
      .map((item) => item.scheme.trim())
      .join('\n');

    clipboardCopy(post).then(
      (() => showToast(TOAST_SUCCESS, t('copied_to_clipboard_msg'))),
      (() => showToast(TOAST_ERROR, t('copy_to_clipboard_failed_msg'))),
    );
    return false;
  }, [parsedItems]);

  useEffect(() => {
    if (isOpen) {
      (async () => {
        fetchImages();
      })();

    }
  }, [isOpen])

  useEffect(() => {
    const parsedItems = items
      .filter((item) => (typeof item.enabled === 'function' ? item.enabled(item.name === 'offerUrl' ? offerLink : offerData) : item.enabled) && item.scheme)
      .map((item) => {

        return {
          ...item,
          scheme: typeof item.scheme === 'function'
            ? item.scheme(offerData, t)
            : parseScheme(item.scheme, { ...offerData, Agency: agencyData, OfferLink: offerLink }, t),
        }

      });

    setParsedItems(parsedItems)
  }, [items, offerLink]);

  useEffect(() => {
    if (isOpen && isFirstOpen.current && imagesLoaded.current) {
      onCopyHandler();
      isFirstOpen.current = false;
    }
  }, [parsedItems, isOpen])

  return (
    <Modal
      title={t('share_on_social_media')}
      isOpen={isOpen}
      width={1050}
      onClose={onClose}
      controls={(
        <FlexBox p="small" width="100%" alignItems="center" justifyContent="flex-end">
          <Button
            variant="secondary"
            label={t('lbl_close')}
            size="small"
            mr="small"
            width="90px"
            onClick={onClose}
          />
          <Button
            variant="primary"
            label={t('copy_to_clipboard')}
            size="small"
            onClick={onCopyHandler}
          />
        </FlexBox>
      )}
    >
      <FlexBox flexDirection="column">
        {!isMobileOnly && (
        <FlexBox py="medium" px="small" width="100%">
          <Text>
            {t('offer_copied_to_clipboard_message')}
          </Text>
        </FlexBox>
        )}
        <FlexBox minHeight={500} bg="#e8ecee">
        { (!isMobileOnly || (isMobileOnly && viewType === ViewType.Modify)) && (
          <FlexBox
            p="small"
            flexWrap="wrap"
            flexDirection="column"
            width={ (isMobileOnly) ? "100%" : "50%"}
          >
            {isMobileOnly ? (
              <FlexBox width="100%">
                {buttonGroup}
              </FlexBox>
            ) : (
            <Text
              mb="small"
              fontSize="head"
            >
              {t('lbl_modify')}
            </Text>
            )}
            <DndContext
              sensors={sensors}
              modifiers={[restrictToFirstScrollableAncestor]}
              onDragEnd={onDragEndHandler}
            >
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
                disabled={items.length < 2}
              >
                {parsedItems.map((item) => {
                  return (
                    <PostItemContainer id={item.id} key={item.name}>
                      <PostItem item={item} onChange={itemVisibilityHandler} onEdit={onItemEdit}/>
                    </PostItemContainer>
                  );
                })}
              </SortableContext>
            </DndContext>
          </FlexBox>
        )}
          { (!isMobileOnly || (isMobileOnly && viewType === ViewType.Preview)) && (
          <FlexBox flexDirection="column" width={ (isMobileOnly) ? "100%" : "50%"}>
            <FlexBox flexDirection="column" p="small">
            {isMobileOnly ? (
              <FlexBox width="100%">
                {buttonGroup}
              </FlexBox>
            ) : (<Text
                mb="small"
                fontSize="head"
              >
                {t('lbl_preview')}
              </Text>
            )}
              <FlexBox
                backgroundColor="white"
                flexDirection="column"
                borderRadius={8}
                overflow="hidden"
                width="100%"
              >
                <FlexBox
                  p="small"
                  flexDirection="column"
                >
                  {parsedItems.filter((item) => item.selected).map((item) => {
                    return (
                      <FlexBox
                        key={item.name}
                        width="100%"
                      >
                        <PostItem item={item} isPreview/>
                      </FlexBox>
                    )
                  })}
                </FlexBox>
                {imagePreview?.length ? (
                  <FlexBox mt="small">
                    <img src={imagePreview[0].url} style={{
                      maxWidth: '100%',
                      width: '100%',
                      height: '250px',
                      objectFit: 'cover',
                    }}/>
                  </FlexBox>
                ) : null}
              </FlexBox>
            </FlexBox>
          </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </Modal>
  )
};

export { SocialPostCreator }
