import { useState } from 'react';

import { CONTENT_SERVICE_URL } from '@ess/constants/api';

import { promiseRequest } from '@ess/v5-data-provider/request';

type UploadState = {
  state: 'idle' | 'loading' | 'error' | 'ready';
  url: string | null;
}

type UploadArgs = {
  type: string;
  file: File;
  additionalParams?: Record<string, any>;
}

const initialState: UploadState = {
  state: 'idle',
  url: null,
};

const useCFUpload = () => {
  const [state, setState] = useState<UploadState>(initialState);
  const upload = async ({ type, file, additionalParams }: UploadArgs) => {
    let response;

    setState((state) => ({
      ...state,
      state: 'loading',
    }));

    try {
      const formData = new FormData();

      formData.set('file', file);
      formData.set('type', type);

      if (additionalParams) {
        Object.keys(additionalParams).map((key) => {
          formData.set(key, additionalParams[key]);
        });
      }

      response = await promiseRequest(`${CONTENT_SERVICE_URL}File`, formData, 2, undefined, 15000, {
        'Content-Type': 'multipart/form-data',
      });

      const fileUrl = response?.Sections?.File?.Url;

      if (fileUrl) {
        setState((state) => ({
          ...state,
          state: 'ready',
          url: fileUrl,
        }));
      } else {
        throw new Error('UPLOAD_ERROR');
      }
    } catch (error) {
      console.error(error);
      setState((state) => ({
        ...state,
        state: 'error',
      }));
    }

    return response;
  };

  return {
    upload,
    ...state,
  };
};

export {
  useCFUpload,
};
