import React from 'react';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { createPortal } from 'react-dom';

import { BASKET_DROPPABLE_AREA } from '@basket/constants';

import { useReactBasket } from '@basket/hooks/useReactBasket';

import Droppable from './Droppable';

type DraggableContainerProps = {
  children: React.ReactNode
}

const DraggableContainer = ({ children }: DraggableContainerProps) => {
  const { basket, setIsDragging, setPosition } = useReactBasket();

  /**
   * On basket drag start handler.
   */
  const onDragStart = () => {
    setIsDragging(true);
  };

  /**
   * On basket drag end handler.
   * @param event
   */
  const onDragEnd = (event: any) => {
    setIsDragging(false);
    if (event?.over?.id) {
      setPosition(event?.over?.id);
    }
  };

  return (
    <>
      <DndContext
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        {React.cloneElement(children as any, { style: basket.isDragging ? { zIndex: 9999, opacity: 0 } : {} })}
        {createPortal(
          <DragOverlay>
            {basket.isDragging && React.cloneElement(children as any, { isPortal: false, style: { opacity: 1 } })}
          </DragOverlay>,
          document.body,
        )}
        {basket.isDragging && (
        <>
          {createPortal(
            <>
              {Object.keys(BASKET_DROPPABLE_AREA).map((item: any) => (
                <Droppable key={item} id={item} style={BASKET_DROPPABLE_AREA[item]}/>
              ))}
            </>,
                document.querySelector('body') as HTMLElement,
          )}
        </>
        )}
      </DndContext>
    </>
  );
};

export { DraggableContainer };
