import styled from 'styled-components';
import { isNumber } from 'lodash-es';

const Avatar = styled.div<{ size?: string | number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.blue};
  width: ${({ size }) => (isNumber(size) ? `${size}px` : size)};
  height: ${({ size }) => (isNumber(size) ? `${size}px` : size)};
  overflow: hidden;
  border-radius: 50%;
`;

const Avatar__Img = styled.img`
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
`;

const Avatar__Text = styled.div`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.white};
`;

export const Styled = {
  Avatar,
  Avatar__Img,
  Avatar__Text,
};
