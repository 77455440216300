import { useContext } from 'react';
import { useStore } from 'zustand';

import { Store } from './store';

import { LoginSettingsContext } from './LoginSettingsProvider';

const useLoginSettings = <T>(selector: (state: Store) => T) => {
  const store = useContext(LoginSettingsContext);

  if (!store) {
    throw new Error('useLoginSettings hook must be used within LoginSettingsProvider');
  }

  return useStore(store, selector!);
};

export {
  useLoginSettings,
};
