import React, {
  forwardRef, memo, useImperativeHandle, useRef,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { TippyProps } from '@tippyjs/react';

import 'tippy.js/animations/shift-away.css';
import 'tippy.js/animations/shift-toward.css';
import 'tippy.js/animations/perspective.css';

import Box from '@ess/ui/Box';

import LazyTooltip from './LazyTooltip';

type TooltipProps = {
  children: React.ReactElement;
  content: string | React.ReactElement;
  animation?: string;
  interactive?: boolean;
  disabled?: boolean;
  showOnMobile?: boolean;
  contentPadding?: number;
  appendTo?: HTMLElement | null;
  trigger?: string;
  theme?: string;
  zIndex?: number;
  isInitialMount?: boolean;
} & TippyProps;

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(({
  children,
  content,
  animation = 'shift-away',
  interactive = false,
  disabled = false,
  contentPadding = 8,
  trigger = 'mouseenter',
  theme = 'navy',
  zIndex = 99999,
  appendTo = undefined,
  isInitialMount = false,
  showOnMobile = false,
  ...props
},
ref,
) => {
  const tooltipRef = useRef<any>(null);
  const isDisabled = (isMobileOnly && !showOnMobile) || disabled;

  useImperativeHandle(ref, () => tooltipRef.current);

  return (
    <LazyTooltip
      ref={tooltipRef}
      theme={theme}
      disabled={isDisabled}
      isInitialMount={isInitialMount}
      content={<Box p={`${contentPadding}px`}>{content}</Box>}
      interactive={interactive}
      animation={animation}
      zIndex={zIndex}
      {...(props?.visible !== undefined ? {} : { trigger })}
      {...(appendTo ? { appendTo } : {})}
      {...props}
    >
      {children}
    </LazyTooltip>
  );
},
);

export default memo(Tooltip);
