import { createStore } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { Store } from './types';

import { createConfig } from '../config';

const getDefaultValues = (config: any) => {
  let defaultValues: any = {};

  Object.keys(config).map((section) => {
    Object.keys(config[section]).map((item) => {
      defaultValues = {
        ...defaultValues,
        [section]: {
          ...defaultValues[section],
          [item]: config[section][item].defaultValue,
        },
      };
    });
  });

  return defaultValues;
};

const createAgentSettingsStore = (sections?: Record<string, boolean>) => {
  const config = createConfig(sections);

  const initialState = {
    config,
    values: getDefaultValues(config),
  };

  return createStore<Store>()(
    persist(
      (set) => ({
        ...initialState,
        setSetting: (section: string, name: string, value: any) => {
          set((state) => ({
            ...state,
            values: {
              ...state.values,
              [section]: {
                ...state.values[section],
                [name]: value,
              },
            },
          }));
        },
      }), {
        name: 'agentSettings',
        storage: createJSONStorage(() => localStorage),
        partialize: (state) => ({ values: state.values }),
      }),
  );
};

export {
  createAgentSettingsStore,
};
