import React, { useEffect, useRef, useState } from 'react';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import { isFirefox } from 'react-device-detect';
import { useVirtualizer } from '@tanstack/react-virtual';

import { BasketItem } from '@basket/types';

import Box from '@ess/ui/Box';
import FlexBox from '@ess/ui/FlexBox';

import { ScrollBar } from '@ess/components/ScrollBar';

import NavigationArrow, { Direction } from '@tourop/components/NavigationArrow';

import { SortableOverlay } from './SortableOverlay';
import SortableItem from './SortableItem';

type SortableContainerProps = {
  items: BasketItem[]
  onChange(items: BasketItem[], original: BasketItem[], { activeId, overId } : {activeId: number, overId: number }): void
  renderItem(item: BasketItem): React.ReactNode
  disabled?: boolean
  direction?: 'horizontal' | 'vertical'
}

const SortableContainer = ({
  items,
  renderItem,
  onChange,
  disabled = false,
  direction = 'vertical',
}: SortableContainerProps) => {
  const scrollRef = useRef<any>(null);
  const [scrollBarWrapperElement, setScrollBarWrapperElement] = useState<any>(null);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [progress, setProgress] = useState(0);
  const [showArrows, setShowArrows] = useState(false);
  const sensors = useSensors(
    useSensor(PointerSensor),
  );

  const virtualizedList = useVirtualizer({
    count: items.length,
    getScrollElement: () => scrollRef.current,
    estimateSize: () => 120,
    gap: 8,
  });

  const virtualItems = virtualizedList.getVirtualItems();

  /**
   * On Arrow click handler.
   * @param direction
   */
  const onArrowClickHandler = (direction: Direction) => {
    const { scrollLeft } = scrollRef.current;

    scrollRef.current?.scrollTo({
      left: direction === Direction.Right ? scrollLeft + 320 : scrollLeft - 320,
      behavior: isFirefox ? 'instant' : 'smooth',
    });
  };

  const onDragCancelHandler = () => {
    setActiveItem(null);
  };

  const onDragStartHandler = ({ active }: { active: any }) => {
    setActiveItem(items.find((item) => item.id === active.id));
  };

  const onDragEndHandler = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activeIndex = items.findIndex(({ id }) => id === active.id);
      const overIndex = items.findIndex(({ id }) => id === over.id);
      const sorted = arrayMove(items, activeIndex, overIndex);

      onChange(sorted, items, {
        activeId: active.id,
        overId: over.id,
      });
    }

    setActiveItem(null);
  };

  const onMouseWheelHandler = (event: React.WheelEvent) => {
    scrollRef.current?.scrollTo({
      left: scrollRef.current.scrollLeft + event.deltaY,
    });
  };

  useEffect(() => {
    scrollBarWrapperElement?.addEventListener('wheel', onMouseWheelHandler, { passive: false });
    return () => {
      scrollBarWrapperElement?.removeEventListener('wheel', onMouseWheelHandler);
    };
  }, [scrollBarWrapperElement]);

  return (
    <>
      <DndContext
        sensors={sensors}
        modifiers={[restrictToFirstScrollableAncestor]}
        onDragEnd={onDragEndHandler}
        onDragStart={onDragStartHandler}
        onDragCancel={onDragCancelHandler}
      >
        <SortableContext
          items={items}
          strategy={direction === 'vertical' ? verticalListSortingStrategy : horizontalListSortingStrategy}
          disabled={disabled || items.length < 2}
        >
          {showArrows && (
            <>
              {progress > 0 && (
                <NavigationArrow
                  direction={Direction.Left}
                  onClick={(direction) => onArrowClickHandler(direction)}
                  style={{
                    marginTop: 15,
                  }}
                />
              )}
              {progress < 98 && (
                <NavigationArrow
                  direction={Direction.Right}
                  onClick={(direction) => onArrowClickHandler(direction)}
                  style={{
                    marginTop: 15,
                  }}
                />
              )}
            </>
          )}
          <ScrollBar
            ref={scrollRef}
            style={{ padding: '0 10px 10px' }}
          >
            {direction === 'vertical' ? (
              <Box style={{
                height: `${virtualizedList.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
              >
                {virtualItems.map((virtualItem) => {
                  const index = virtualItem.index;
                  const item = items[index];

                  return (
                    <Box
                      ref={virtualizedList.measureElement}
                      key={virtualItem.key}
                      data-index={virtualItem.index}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        transform: `translateY(${virtualItem.start}px)`,
                      }}
                    >
                      <SortableItem id={item.id}>
                        {renderItem(item)}
                      </SortableItem>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <FlexBox
                ref={setScrollBarWrapperElement}
                display="inline-flex"
                minHeight="100%"
                style={{
                  gap: '10px',
                }}
              >
                {items.map((item) => (
                  <SortableItem
                    key={item.id}
                    id={item.id}
                    style={{
                      width: '300px',
                      display: 'flex',
                      alignItems: 'baseline',
                      flexShrink: 0,
                    }}
                  >
                    {renderItem(item)}
                  </SortableItem>
                ))}
              </FlexBox>
            )}
          </ScrollBar>
        </SortableContext>
        <SortableOverlay>
          {activeItem ? renderItem(activeItem) : null}
        </SortableOverlay>
      </DndContext>
    </>

  );
};

export { SortableContainer };
