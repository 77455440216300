import React, { memo } from 'react';

type ElementProps = {
  Component: any;
  hasRequiredWidth: boolean;
}
const Element = memo(({ Component, hasRequiredWidth }: ElementProps) => (
  Component && <Component isPortal={!hasRequiredWidth} />
));

export {
  Element,
};
