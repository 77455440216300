import React from 'react';
import { useTranslation } from 'react-i18next';

import FlexBox from '@ess/ui/FlexBox';

import Text from '@ess/ui/Text';
import { Styled } from '../BasketList.styles';

const Header = () => {
  const { t } = useTranslation();

  return (
    <Styled.BasketHeader>
      <FlexBox mr="small" />
      <FlexBox mr="small" />
      <FlexBox pr="small" />
      <FlexBox pr="small">
        <Text
          whiteSpace="nowrap"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
        >
          {t('lbl_name')}
        </Text>
      </FlexBox>
      <FlexBox px="small">
        <Text
          whiteSpace="nowrap"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
        >
          {t('lbl_modification_date')}
        </Text>
      </FlexBox>
      <FlexBox px="small">
        <Text
          whiteSpace="nowrap"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
        >
          {t('lbl_quantity')}
        </Text>
      </FlexBox>
      <FlexBox px="small">
        <Text
          whiteSpace="nowrap"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
        >
          {t('lbl_consultant')}
        </Text>
      </FlexBox>
      <FlexBox/>
    </Styled.BasketHeader>
  );
};

export {
  Header,
};
