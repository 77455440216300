import Cookies from 'js-cookie';
import { createStore } from 'zustand';

import { CONTENT_SERVICE_URL } from '@ess/constants/api';

import { touropAuth } from '../api/touropAuth';
import { axiosInstance } from '../api';

import { Store } from './types';

import { AUTH_ERROR, ACCESS_TOKEN_KEY } from '../constants';

const defaultState = {
  isAuthenticated: false,
  isLoading: false,
  twoFactorAuthentication: false,
  showLoginOverlay: false,
  error: {
    login: null,
    passwordRecovery: null,
    passwordReset: null,
  },
  userLogin: '',
};

const createAuthStore = (initProps?: Partial<Store>) => {
  const initialState = {
    ...defaultState,
    isAuthenticated: !!Cookies.get(ACCESS_TOKEN_KEY),
  };

  return createStore<Store>((set, getState) => ({
    ...initialState,
    ...initProps,
    login: async ({
      credentials,
      onSuccess,
      onSecured,
      onError,
    }) => {
      try {
        const userLogin = getState().userLogin;
        const isSecureLoginMethod = 'code' in credentials;

        set((state) => ({
          ...state,
          isLoading: true,
          error: {
            login: null,
            passwordRecovery: null,
            passwordReset: null,
          },
        }));

        const postData = isSecureLoginMethod ? {
          Query: 'Secure',
          Login: {
            User: userLogin,
            Pin: credentials.code,
          },
        } : {
          Query: 'Login',
          Login: {
            User: credentials.login,
            Password: credentials.password,
            Domain: window.location.host,
            SupportLogin: '',
          },
        };

        const request = await axiosInstance.post(`${CONTENT_SERVICE_URL}Login`, postData);
        const { data, status } = request?.data ?? {};

        if (data?.secured) {
          set((state) => ({
            ...state,
            userLogin: credentials.login,
            twoFactorAuthentication: true,
          }));

          if (onSecured) {
            onSecured();
          }

          return;
        }

        if (!data?.token || status?.status === 'Error') {
          throw new Error(
            status?.statusMsg && status.statusMsg in AUTH_ERROR
              ? AUTH_ERROR[status.statusMsg]
              : AUTH_ERROR.ErrorGeneral,
          );
        }

        Cookies.set(ACCESS_TOKEN_KEY, data.token, { secure: true });

        set((state) => ({
          ...state,
          twoFactorAuthentication: false,
          isAuthenticated: true,
        }));

        if (onSuccess) {
          onSuccess();
          touropAuth.login();
        }
      } catch (error: any) {
        console.error(error);
        const errorCode = error?.message in AUTH_ERROR
          ? AUTH_ERROR[error.message as string]
          : AUTH_ERROR.ErrorGeneral;

        set((state) => ({
          ...state,
          error: {
            ...state.error,
            login: errorCode,
          },
        }));

        if (onError) {
          onError();
        }
      } finally {
        set((state) => ({
          ...state,
          isLoading: false,
        }));
      }
    },
    logout: (params) => {
      const { showLoginOverlay = undefined, onSuccess = undefined } = params ?? {};
      const showOverlay = !!Cookies.get(ACCESS_TOKEN_KEY) && showLoginOverlay;

      Cookies.remove(ACCESS_TOKEN_KEY);
      sessionStorage.clear();
      localStorage.removeItem('printOffers');
      touropAuth.logout();

      set((state) => ({
        ...state,
        ...defaultState,
        showOverlay,
      }));

      if (onSuccess) {
        onSuccess();
      }
    },
    passwordReset: async ({ data, onError, onSuccess }) => {
      try {
        set((state) => ({
          ...state,
          isLoading: true,
          error: {
            login: null,
            passwordRecovery: null,
            passwordReset: null,
          },
        }));

        const postData = {
          Query: 'PasswordReset',
          Login: {
            Pin: data?.hash ?? '',
            Password: data?.newPassword ?? '',
          },
        };

        const request = await axiosInstance.post(`${CONTENT_SERVICE_URL}Login`, postData);
        const { status } = request?.data ?? {};

        if (status?.status === 'Error') {
          throw new Error(
            status?.statusMsg && status.statusMsg in AUTH_ERROR
              ? AUTH_ERROR[status.statusMsg]
              : AUTH_ERROR.ErrorGeneral,
          );
        }

        if (onSuccess) {
          onSuccess();
        }
      } catch (error: any) {
        console.error(error);
        const errorCode = error?.message in AUTH_ERROR
          ? AUTH_ERROR[error.message as string]
          : AUTH_ERROR.ErrorGeneral;

        if (onError) {
          onError();
        }

        set((state) => ({
          ...state,
          isLoading: false,
          error: {
            login: null,
            passwordRecovery: null,
            passwordReset: errorCode,
          },
        }));
      }
    },
    passwordResetCheck: async (hash) => {
      let response = null;

      try {
        const postData = {
          Query: 'PasswordResetCheck',
          Login: {
            Pin: hash,
          },
        };

        const request = await axiosInstance.post(`${CONTENT_SERVICE_URL}Login`, postData);
        const { status } = request?.data?.data ?? {};

        response = status === 'OK';
      } catch (error) {
        console.error(error);
      }

      return response;
    },
    passwordRecovery: async ({ login, onSuccess, onError }) => {
      try {
        set((state) => ({
          ...state,
          isLoading: true,
          error: {
            login: null,
            passwordRecovery: null,
            passwordReset: null,
          },
        }));

        const postData = {
          Query: 'PasswordRecovery',
          Login: {
            User: login,
          },
        };

        const request = await axiosInstance.post(`${CONTENT_SERVICE_URL}Login`, postData);
        const { status } = request?.data ?? {};

        if (status?.status?.toUpperCase() === 'SUCCESS') {
          if (onSuccess) {
            onSuccess();
          }
        } else {
          throw new Error(
            status?.statusMsg && status.statusMsg in AUTH_ERROR
              ? AUTH_ERROR[status.statusMsg]
              : AUTH_ERROR.ErrorGeneral,
          );
        }
      } catch (error: any) {
        console.error(error);
        const errorCode = error?.message in AUTH_ERROR
          ? AUTH_ERROR[error.message as string]
          : AUTH_ERROR.ErrorGeneral;

        set((state) => ({
          ...state,
          error: {
            ...state.error,
            passwordRecovery: errorCode,
          },
        }));

        if (onError) {
          onError();
        }
      } finally {
        set((state) => ({
          ...state,
          isLoading: false,
        }));
      }
    },
  }));
};

export {
  createAuthStore,
};
