import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import useOnClickOutside from '@ess/hooks/useOnClickOutside';

import { Button } from '@ess/ui/Button';
import Modal from '@ess/ui/Modal';
import FlexBox from '@ess/ui/FlexBox';
import Box from '@ess/ui/Box';
import Text from '@ess/ui/Text';

import { INetworkStatus } from '@ess/v5-data-provider/interfaces';

const NetworkStatus = ({ useNetworkStatusHook, appendTo = undefined }:
    { useNetworkStatusHook:()=>INetworkStatus, appendTo?:any|undefined }) => {
  const { t } = useTranslation();
  const { isOnline, retryButton, reset } = useNetworkStatusHook();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);
  useOnClickOutside(modalElement, () => setIsOpen(false));

  const retry = () => {
    if (retryButton) {
      retryButton();
      setIsOpen(false);
      setTimeout(() => {
        setIsOpen(!isOnline);
      }, Math.floor(Math.random() * (4000 - 1000)) + 1000);
    }
  };

  useEffect(() => {
    setIsOpen(!isOnline);
  }, [isOnline]);

  return (
    <Modal
      ref={setModalElement}
      appendTo={appendTo}
      title={t('netstatus_title')}
      isOpen={isOpen}
      onClose={() => {
        if (reset) {
          reset();
        }

        setIsOpen(false);
      }}
      controls={(
        <FlexBox alignItems="center" justifyContent="flex-end" px="small" width="100%">
          {retryButton ? (
            <Button
              variant="primary"
              label={t('netstatus_retrybutton')}
              size="small"
              width="80px"
              onClick={retry}
              disabled={!window.navigator.onLine}
            />
          ) : undefined}
        </FlexBox>
      )}
    >
      <Box p="small" >
        <FlexBox style={{ justifyContent: 'space-between' }}>
          <Text>{t('lbl_no_network_connection')}</Text>
        </FlexBox>
      </Box>
    </Modal>
  );
};

export default NetworkStatus;
