import React from 'react';
import { Translation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { theme } from '@tourop/config/theme';

import TechnicalIssueMessage from '@tourop/components/TechnicalIssueMessage';

import Button from '@ess/ui/Button/Button';

import { GlobalStyle } from '@ess/global-styles/GlobalStyles';

const FallbackComponent = () => (
  <ThemeProvider theme={theme}>
    <TechnicalIssueMessage
      title={(<Translation>{((t) => <>{t('lbl_unexpected_error')}</>)}</Translation>)}
      button={(
        <Button
          width="auto"
          label={(<Translation>{((t) => <>{t('try_again')}</>)}</Translation>)}
          onClick={() => window.location.reload()}
        />
        )}
    >
      <Translation>{((t) => <>{t('lbl_unexpected_error_try_again')}</>)}</Translation>
    </TechnicalIssueMessage>
    <GlobalStyle/>
  </ThemeProvider>
);

export default FallbackComponent;
