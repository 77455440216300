import { assignIn } from 'lodash-es';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SEARCH_TYPE':
      return {
        ...state,
        type: action.payload,
      };
    case 'AUTHORIZE_GOOGLE_MAPS_KEY':
      return {
        ...state,
        isGoogleKeyAuthorized: action.payload,
      };
    case 'FETCH_SEARCH_FORM_CONFIG':
      return {
        ...state,
        isLoading: true,
      };
    case 'SORT_TOP_OFFERS':
      return {
        ...state,
        topOffersSortBy: action.payload,
      };
    case 'UPDATE_SEARCH_FORM_CONFIG':
      return {
        ...state,
        config: assignIn(state.config, action.payload),
        isLoading: false,
      };
    case 'UPDATE_APP_CONFIG':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
