import { has, isEmpty } from 'lodash-es';

import { IDictionary } from '@ess/types';

import topOffersConfig from '@tourop/config/search/topOffers';

import { REQUEST_IDLE } from '@ess/constants/request';
import { FIRST_PERSON_PRICE, GROUPED_VIEW, TOTAL_PRICE } from '@ess/constants/search';

import ProtoHash, { ProtoHashTypes } from '@ess/protohash';

import {
  getUrlSearchParams,
  getValidatedSearchValues,
  getFieldsValuesFromConfig,
  getRegionListType,
  getFieldsList,
} from '@ess/utils';

const getQueryParams = () => getUrlSearchParams({ params: ['sf', 'sfr', 'tourop'] });

const searchFormProtoInstance = new ProtoHash(ProtoHashTypes.SearchForm);
const filtersProtoInstance = new ProtoHash(ProtoHashTypes.Filters);

const attributesName = 'Accommodation.Attributes';
const regionName = 'Base.DestinationLocation';
const dayOfWeekName = 'Base.StartDateDayOfWeek';

/**
 * Return filters initial values.
 * @param fieldValues
 */
const getFilterFieldsInitialValues = ({ fieldValues }: any) => {
  const { fieldList } = fieldValues;
  const initialValues: IDictionary<string | number> = {};

  fieldList.map((item: string) => {
    initialValues[item] = '';

    if (item === 'Base.StartDate') {
      initialValues[dayOfWeekName] = '';
    }

    if (item === 'Base.NightsBeforeReturn') {
      initialValues['Base.NightsBeforeReturn'] = -1;
    }
  });

  return initialValues;
};

/**
 * Redux - Initial search form state
 * @param formValues
 * @param searchType
 */
const searchFormState = (formValues: any, searchType: string) => ({
  type: searchType,
  protoHash: searchFormProtoInstance.encode(formValues),
  priceType: formValues['Custom.FullPrice'] ? TOTAL_PRICE : FIRST_PERSON_PRICE,
  formErrors: {},
  searchValues: formValues,
  sortBy: topOffersConfig.defaultSorting,
  showAllAttributes: false,
  showMap: false,
  selectedAgentProfile: null,
  status: REQUEST_IDLE,
});

/**
 * Redux - Initial search results state
 * @param formValues
 */

const agentSettings = localStorage.getItem('agentSettings')
  ? JSON.parse(`${localStorage.getItem('agentSettings')}`)
  : undefined;

const mapOpen = agentSettings?.state?.values?.searchResults?.mapShowed ?? false;

if (mapOpen) {
  localStorage.setItem('mapOpen', 'true');
}

const searchResultsState = (formValues: any) => ({
  view: agentSettings?.state?.values?.searchResults?.viewSetting ?? GROUPED_VIEW,
  extendedViewDetails: false,
  multiRoomMode: formValues['Base.ParticipantsList']?.multiRoom?.enabled,
  initialOrder: formValues['Custom.FullPrice'] ? 'Base.Price.Total' : 'Base.Price.FirstPerson',
  regionsType: getRegionListType(formValues),
  showMap: agentSettings?.state?.values?.searchResults?.mapShowed ?? false,
  results: {
    hoveredMarker: { },
  },
  filters: {
    fields: {},
  },
});

/**
 * Redux - Initial filters form state.
 */
const filtersFormState = () => {
  const filtersHash = getQueryParams()?.sfr ?? '';
  const filters = filtersHash ? filtersProtoInstance.decode(filtersHash, true) : {};

  const filtersFields = getFieldsList(
    {}, 'fieldValues', (item: string) => item !== regionName,
  );

  const initialFiltersValues = getFilterFieldsInitialValues(filtersFields);

  const attributes = {
    [attributesName]: has(filters, [attributesName]) ? filters[attributesName] : [],
  };

  const regions = {
    [regionName]: has(filters, [regionName]) ? filters[regionName] : [],
  };

  const fields = {
    ...initialFiltersValues,
    ...attributes,
    ...!isEmpty(filters) ? { ...filters } : {},
  } as any;

  if (has(fields, [regionName])) {
    delete fields[regionName];
  }

  return {
    fields,
    regions,
    operators: [],
    initialFetch: !isEmpty(filters) ? Object.keys(filters)
      .map((item) => item) : [],
    showFilters: agentSettings?.state?.values?.searchResults?.filtersExpanded ?? true,
    referrer: undefined,
  };
};

/**
 * Returns preloaded redux state.
 * @param appConfig
 */
const getPreloadedState = (appConfig: any) => {
  const protoFormValues = getValidatedSearchValues(getQueryParams()?.sf);

  const formValues = protoFormValues || getFieldsValuesFromConfig({
    SBContext: appConfig?.searchBase ?? {},
    SFContext: appConfig?.config ?? {},
    property: 'initialValue',
  });

  return {
    base: {
      showHotkeysDrawer: false,
      showSettingsDrawer: false,
      isMapSearchDisabled: false,
      showMap: mapOpen,
    },
    topOffers: {
      initiator: null,
      visibleOnSearchPage: false,
    },
    searchForm: searchFormState(formValues, appConfig?.config?.type),
    searchResults: searchResultsState(formValues),
    filtersForm: filtersFormState(),
  };
};

export default getPreloadedState;
