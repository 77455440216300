import {
  includes,
  get,
  set,
  has,
  isArray,
  isEmpty,
  isObject,
  max,
  min,
  partition,
  toNumber,
  isNumber,
  isBoolean,
} from 'lodash-es';
import moment from 'moment';

import { DATE_REQUEST_FORMAT } from '@ess/constants/api';

import searchRequestConfig from '@tourop/config/search/searchRequest';

import { getHotelXcodeFromValue } from '../form/getHotelXcodeFromValue';
import { stringToArray } from '../string/stringToArray';

/**
 * Returns validated min-max object.
 * @param originalValue
 */
const getMinMaxValue = (originalValue: string | number | { min: number, max: number }) => {
  const value = isNumber(originalValue) ? originalValue.toString() : originalValue;

  const { min: minValue, max: maxValue } = isObject(value) ? value : {
    min: !isEmpty(value) ? value : 0,
    max: !isEmpty(value) ? value : 0,
  };

  return toNumber(minValue) > 0 ? {
    min: toNumber(minValue),
    max: toNumber(maxValue),
  } : null;
};

const searchRequestSchema = {
  Custom: {
    PriceRange: {
      transformValue: (value: { [key: string]: { Min: number, Max: number } }) => {
        const priceType = Object.keys(value).filter((item) => !isEmpty(value[item]))[0];
        const { Max, Min } = value[priceType];

        return {
          [priceType]: {
            Min,
            Max,
          },
        };
      },
    },
    HotelName: {
      transformValue: (value: { hotelXCode: number | null; agentAttributesXCodes: Array<number>; }) => {
        const { hotelXCode, agentAttributesXCodes } = value;
        const hasAgentAttributes = agentAttributesXCodes && agentAttributesXCodes.length;

        return hasAgentAttributes ? [0, ...agentAttributesXCodes] : hotelXCode ? [hotelXCode] : [];
      },
    },
    Availability: {
      transformValue: (value: string) => stringToArray(value),
    },
    Flight: {
      Stops: {
        transformValue: (value: string) => stringToArray(value),
      },
    },
    ComponentsCombinations: {
      transformValue: (value: { transport: Array<string> | string; objectType: string[]; }) => {
        const { transport, objectType } = value;
        const combinationsArray: any = [];
        const sendTransport: Array<string> = [];

        let tArray: Array<string> = isArray(transport) ? [...transport] : [transport];

        tArray = tArray.filter((item) => !isEmpty(item));

        (!tArray || isEmpty(tArray)
          ? ['transport.*,accommodation.*']
          : tArray).map((item) => sendTransport.push(...item.split(',')));

        const [transportArray, objectTypeArray] = partition(
          sendTransport, (item) => includes(item, 'transport'),
        );

        const parsedObject = !isEmpty(objectType) ? objectType : !isEmpty(objectTypeArray[0]) ? objectTypeArray : [];
        const hasObject = !isEmpty(parsedObject);

        const getParsedObjectItem = (object: string) => (
          includes(object, ',') ? object.split(',') : [object]
        );

        if (transportArray.length) {
          transportArray.map((transportItem) => {
            if (hasObject) {
              parsedObject.map((objectItem) => {
                combinationsArray.push([transportItem, ...getParsedObjectItem(objectItem)]);
              });
            } else {
              combinationsArray.push([transportItem]);
            }
          });
        } else {
          parsedObject.map((objectItem) => {
            combinationsArray.push([...getParsedObjectItem(objectItem)]);
          });
        }

        return !isEmpty(combinationsArray) ? combinationsArray : [];
      },
    },
    OfferAttributes: {
      sendValueAsParams: true,
      transformValue: (value: any) => {
        const parsedValue: any = {};

        value.map((item: string) => {
          const [fieldName, stringValue] = item?.split('_') ?? [];
          const value = stringValue?.split(':') ?? [];
          parsedValue[fieldName] = value;
        });

        return parsedValue;
      },
    },
  },
  Base: {
    Candy: {
      transformValue: (value: any) => (value ? toNumber(value) : ''),
    },
    StartDateDayOfWeek: {
      transformValue: (value: string) => stringToArray(value),
    },
    StartDate: {
      transformValue: (value: any) => (!isObject(value) && !isEmpty(value)
        ? {
          After: value,
          Before: value,
        } : value),
    },
    Operator: {
      transformValue: (value: string) => stringToArray(value),
    },
    NightsBeforeReturn: {
      transformValue: (originalValue: any) => {
        const value = isNumber(originalValue) ? originalValue.toString() : originalValue;
        const parsedValue = isArray(value) ? value : !isEmpty(value) ? [value] : [];
        const hasMultipleValues = value.length > 1;
        const minValue = hasMultipleValues ? min(parsedValue.map((item) => item * 1)) : parsedValue[0];
        const maxValue = hasMultipleValues ? max(parsedValue.map((item) => item * 1)) : parsedValue[0];

        return !isEmpty(parsedValue) && toNumber(minValue) >= 0 ? {
          min: toNumber(minValue),
          max: toNumber(maxValue),
        } : null;
      },
    },
    DepartureLocation: {
      transformValue: (value: string) => stringToArray(value),
    },
    Catalog: {
      transformValue: (value: string) => stringToArray(value),
    },
  },
  Accommodation: {
    BuildRenovationYear: {
      transformValue: (value: string | number) => {
        const [first, second] = value
          ? typeof value === 'string'
            ? value.split(',')
            : [value, null]
          : [null, null];

        const values = {
          ...first ? { min: toNumber(first) } : {},
          ...second ? { max: toNumber(second) } : {},
        };

        return isEmpty(values) ? null : values;
      },
    },
    Rooms: {
      transformValue: (value: string[]) => {
        const type = value.filter((el) => !includes(el, '3:'));
        const feature = value.filter((el) => includes(el, '3:'));

        const roomTypes = {
          ...type.length ? { type } : {},
          ...feature.length ? { feature } : {},
        };

        return !isEmpty(roomTypes) ? [roomTypes] : null;
      },
    },
    XCode: {
      transformValue: (value: string | string[]) => {
        const parsedValue: number[] = [];

        if (isArray(value)) {
          value.map((item) => parsedValue.push(toNumber(item)));
        } else {
          const newValue = getHotelXcodeFromValue(value);

          if (newValue) {
            parsedValue.push(newValue);
          }
        }

        return parsedValue;
      },
    },
    XService: {
      transformValue: (originalValue: number | string) => {
        const value = isNumber(originalValue) ? originalValue.toString() : originalValue;
        const valuesArray = stringToArray(value).map((item) => toNumber(item));
        return valuesArray.filter((item) => item !== 0);
      },
    },
    Category: {
      transformValue: (value: number | string | { min: number, max: number }) => getMinMaxValue(value),
    },
    Rating: {
      transformValue: (value: number | string | { min: number, max: number }) => getMinMaxValue(value),
    },
  },
  Transport: {
    Flight: {
      AirlineType: {
        transformValue: (value: string): Array<string> => stringToArray(value),
      },
    },
  },
};

const getHotelNameFromXCAndName = (formikVal: string) => {
  const [, value] = formikVal.split('|', 2);
  return value;
};

/**
 * Returns V5 req format
 */

export const formValuesToSearchRequestSchema = (formValues: { [x: string]: any }) => {
  const { fieldsSchemaMap } = searchRequestConfig;
  const searchRequest = {};
  const values: { [key: string]: any } = {
    ...formValues,
    ...has(formValues, 'Custom.HotelName') ? {
      'Custom.HotelName': {
        hotelXCode: getHotelXcodeFromValue(formValues['Custom.HotelName']),
        agentAttributesXCodes: formValues['Custom.AgentAttributes.XCodes'],
      },
      'Base.XNameAndCodePartial': getHotelNameFromXCAndName(formValues['Custom.HotelName']),
    } : {},
    ...has(formValues, 'Base.ComponentsCombinations.Transport') ? {
      'Custom.ComponentsCombinations': {
        transport: formValues['Base.ComponentsCombinations.Transport'],
        objectType: formValues['Base.ComponentsCombinations.ObjectType'],
      },
    } : {},
  };

  if (Number(formValues['Custom.TripRange']) > 0) {
    const dateDiff = Number(formValues['Custom.TripRange']);
    values['Custom.StartDateRange.StartDateFrom'] = moment(formValues['Custom.StartDateRange.StartDateFrom'],
      DATE_REQUEST_FORMAT).add(-dateDiff, 'days').format(DATE_REQUEST_FORMAT);
    values['Custom.StartDateRange.StartDateTo'] = moment(formValues['Custom.StartDateRange.StartDateTo'],
      DATE_REQUEST_FORMAT).add(dateDiff, 'days').format(DATE_REQUEST_FORMAT);
  }

  if (has(formValues, 'Base.ComponentsCombinations.Transport')) {
    delete values['Base.ComponentsCombinations.Transport'];
    delete values['Base.ComponentsCombinations.ObjectType'];
  }

  if (!formValues['Custom.DistanceToBeach']) {
    delete values['Accommodation.DistanceToBeach'];
  }

  if (!includes(formValues['Accommodation.Attributes'], 'location_ski_resorts')) {
    delete values['Accommodation.DistanceToSlope'];
  }

  if (has(formValues, 'Accommodation.Rating') && !formValues['Accommodation.Rating']?.min) {
    delete values['Accommodation.Rating'];
  }

  if ((formValues['Custom.Rating'] ?? false) && (Number(formValues['Accommodation.Rating']?.min ?? 0)) === 0) {
    values['Accommodation.Rating'] = { ...(values['Accommodation.Rating'] ?? {}), min: 0.5 };
  }

  if (has(formValues, 'Base.DestinationLocation.Geo.Polygon')) {
    delete values['Base.DestinationLocation.Geo.Circle'];
    delete values['Base.DestinationLocation.Region'];
  }

  if (has(formValues, 'Base.DestinationLocation.Geo.Circle')) {
    delete values['Base.DestinationLocation.Region'];
  }

  if (values) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item in values) {
      if (has(values, item)) {
        const fieldName = get(fieldsSchemaMap, item, item);
        const itemSchema = get(searchRequestSchema, item, {}) as any;
        const transformValue = get(itemSchema, 'transformValue', false);
        const sendValueAsParams = get(itemSchema, 'sendValueAsParams', false);
        const value: any = transformValue ? itemSchema.transformValue(values[item], values) : values[item];
        const currentValue = get(searchRequest, fieldName);
        const isValueArray = isArray(value);

        const isCustomField = includes(fieldName, 'Custom');
        const isPositiveBoolean = isBoolean(value) && value;
        const isUnspecifiedValue = isArray(value) && value.length && value.join(',') === 'unspecified';

        if (sendValueAsParams && !isEmpty(value)) {
          // eslint-disable-next-line array-callback-return
          Object.keys(value).map((item) => {
            set(searchRequest, item, value[item]);
          });
        } else if (!isCustomField && (isPositiveBoolean || isNumber(value) || (!isEmpty(value) && !isUnspecifiedValue))) {
          set(searchRequest, fieldName, currentValue
            ? isValueArray ? [...currentValue, ...value] : value
            : value);
        }
      }
    }
  }

  return searchRequest;
};
