import React from 'react';

import { Styled } from '../Tabs.styles';

type TabPanelProps = {
  children: React.ReactNode;
  hidden?: boolean;
  value: any;
};

const TabPanel = ({ children, hidden = true, value }: TabPanelProps) => (
  <Styled.TabPanel
    id={`tab-panel-${value}`}
    role="tabpanel"
    {...(hidden ? { hidden } : {})}
  >
    {children}
  </Styled.TabPanel>
);

export default TabPanel;
