import styled from 'styled-components';

const MultiSelect__Label = styled.div`
  margin-bottom: 2px;
`;

const Multiselect__Field__DropdownIndicator = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.green};
  pointer-events: none;
`;

const Multiselect__Field__DisabledIcon = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
`;

const MultiSelect__Field = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ isOpen }) => `
      ${Multiselect__Field__DropdownIndicator} {
        transform: ${isOpen ? 'rotate(180deg)' : ''};
        transition: all .3s ease;
        will-change: transform, top;
        transition-property: transform, top;
      }
    `}
`;

const MultiSelect__Field__Input = styled.div`
  position: relative;
  width: 100%;
`;

const MultiSelect__Field__Tags__Item = styled.div<{ isCount?: boolean }>`
  background-color: rgb(230, 230, 230);
  border-radius: 4px;
  display: flex;
  margin: 0px;
  flex-shrink: 0;
  padding: 3px 6px;
  min-width: 0px;
  max-width: calc(100% - 28px);
  box-sizing: border-box;

  & > span {
    font-size: 11px;
    white-space: nowrap;

    ${({ isCount }) => !isCount && `
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      `}
  }
`;

const MultiSelect__Field__Tags = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 99;
  padding: 0 6px;
  width: calc(100% - 28px);
  height: 100%;
  display: flex;
  align-items: center;

  ${MultiSelect__Field__Tags__Item}:not(:last-child) {
    margin-right: 2px;
  }
`;

const MultiSelect__DropDown__Container = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: ${({ width }) => width}px;
  border-radius: ${({ theme }) => theme.radii['br-4']};
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  background-color: ${({ theme }) => theme.colors.white};
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

const MultiSelect__DropDown = styled.div<{ maxHeight: number }>`
  position: relative;
  overflow: auto;
  max-height: ${({ maxHeight }) => maxHeight}px;
`;

const MultiSelect__Controls = styled.div<{ isMobileOnly: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  padding: 0 ${({ theme, isMobileOnly }) => (!isMobileOnly ? theme.space.tiny : theme.space.small)};
  width: 100%;

  ${({ isMobileOnly, theme }) => !isMobileOnly && `
     border-top: 1px solid ${theme.colors.lighterGray};
     height: 40px;
  `}
`;

const MultiSelect__Empty = styled.div<{ isMobileOnly: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ isMobileOnly }) => (isMobileOnly ? '100%' : '34px')};
  width: 100%;
  padding: ${({ theme }) => theme.space.small} 0;
`;

const MultiSelect__Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  padding: ${({ theme }) => theme.space.small} 0;
`;

const MultiSelect__SearchInput = styled.div<{ isMobileOnly: boolean }>`
  padding: ${({ theme }) => theme.space.small};
`;

const MultiSelect__DropDown__Header = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.lighterGray}`};
`;

const MultiSelect__Options = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
`;

const MultiSelect__Options__Inner = styled.div`
  display: flex;
  flex-direction: column;
`;

const MultiSelect__Options__Group = styled.div`
  display: flex;
  align-items: center;
  min-height: 34px;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  white-space: normal;
  padding: ${({ theme }) => theme.space.tiny} ${({ theme }) => theme.space.small};
`;

const MultiSelect__Option__Favourite = styled.div<{ isMobileOnly: boolean, isActive: boolean }>`
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  height: 34px;
  font-size: 16px;
  display: flex;
  margin-right: ${({ theme }) => theme.space.small};
`;

const MultiSelect__Option__Label = styled.span`
  position: relative;
  top: 1px;
  color: ${({ theme }) => theme.colors.textColor};
`;

const MultiSelect__Option__Favourite__IconContainer = styled.div<{ isActive: boolean }>`
  display: flex;
`;

const MultiSelect__Option = styled.div<{ isMobileOnly: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 34px;
  padding: 0 ${({ theme }) => theme.space.small};

  ${({ isMobileOnly, theme }) => !isMobileOnly && `
    &:hover {
        background-color: ${theme.colors.navy};

        ${MultiSelect__Option__Label} {
          color: ${theme.colors.white};
        }

        ${MultiSelect__Option__Favourite} {
          color: ${theme.colors.white};
        }
     }
  `}
`;

const MultiSelect = styled.div<{ disabled?: boolean, hasCustomElement?: boolean }>`
  width: ${({ hasCustomElement }) => (hasCustomElement ? 'auto' : '100%')};

  ${({ disabled }) => disabled && `
      ${MultiSelect__Field__Tags} {
        cursor: not-allowed;
      }

      ${MultiSelect__Field__Tags__Item} {
        background-color: #999999;
        color: #fff;
      }
    `}
`;

export const Styled = {
  MultiSelect,
  MultiSelect__Label,
  MultiSelect__Field,
  MultiSelect__Field__Tags,
  MultiSelect__Field__Tags__Item,
  MultiSelect__Field__Input,
  Multiselect__Field__DropdownIndicator,
  Multiselect__Field__DisabledIcon,
  MultiSelect__DropDown,
  MultiSelect__DropDown__Header,
  MultiSelect__Controls,
  MultiSelect__DropDown__Container,
  MultiSelect__Empty,
  MultiSelect__Loader,
  MultiSelect__SearchInput,
  MultiSelect__Options,
  MultiSelect__Options__Group,
  MultiSelect__Options__Inner,
  MultiSelect__Option,
  MultiSelect__Option__Label,
  MultiSelect__Option__Favourite,
  MultiSelect__Option__Favourite__IconContainer,
};
