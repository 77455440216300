import { useContext } from 'react';
import { useStore } from 'zustand';

import { Store } from './types';

import { ColumnsManagerContext } from './';

const useColumnsManager = <T>(selector: (state: Store) => T) => {
  const store = useContext(ColumnsManagerContext);

  if (!store) {
    throw new Error('useColumnsManager must be used within ColumnsManagerProvider');
  }

  return useStore(store, selector!);
};

export {
  useColumnsManager,
};
